
import { Component, Watch, Vue,Prop } from "vue-property-decorator";
@Component
export default class TitleComponentForER extends Vue {
    @Prop()
    breadcrumbItems:Array<any>;
    @Prop()
    showButtons:boolean;
   
        zoomInZoomOutOnClick(zoomLevel:number){
                this.$emit('zoom-in-zoom-out',zoomLevel)
            }

            downloadAsPng(){
                this.$emit('download-as-png')
            }
            downloadAsPdf(){
                this.$emit('download-image-as-pdf')
            }
            
}
