import { ProductDimensionMember } from '@/models/ProductDimensionMember'

export class ProductDimension {
    productId: number;
    productName: string;
    dimensionId: number;
    dimensionName: string;
    platformId: number;
    platformName: string;
    hasAllMembers: boolean;
    dimensionMemberIds: Array<number> = [];
    dimensionMembers: Array<ProductDimensionMember> = [];
}
