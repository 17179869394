import { MutationTree } from 'vuex'
import { NotificationState } from './types'

export const mutations: MutationTree<NotificationState> = {
    showError(state, options) {
        state.snackBarColour = "error";
        state.snackBarIcon = "mdi-alert-circle-outline";
        state.snackBarTitle = options.title;
        state.snackBarMessage = options.message;
        state.snackBarTimeout = options.timeout ?? state.snackBarTimeout;
        state.showSnackBar = true;
    },
    showWarning(state, options) {
        state.snackBarColour = "warning";
        state.snackBarIcon = "mdi-alert-circle-outline";
        state.snackBarTitle = options.title;
        state.snackBarMessage = options.message;
        state.snackBarTimeout = options.timeout ?? state.snackBarTimeout;
        state.showSnackBar = true;
    },
    showInfo(state, options) {
        state.snackBarColour = "info";
        state.snackBarIcon = "mdi-info-circle-outline";
        state.snackBarTitle = options.title;
        state.snackBarMessage = options.message;
        state.snackBarTimeout = options.timeout ?? state.snackBarTimeout;
        state.showSnackBar = true;
    },
    showSuccess(state, options) {
        state.snackBarColour = "success";
        state.snackBarIcon = "mdi-check-circle-outline";
        state.snackBarTitle = options.title;
        state.snackBarMessage = options.message;
        state.snackBarTimeout = options.timeout ?? state.snackBarTimeout;
        state.showSnackBar = true;
    },
    hideNotification(state) {
        state.showSnackBar = false;
    }
}
