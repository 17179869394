
    import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
    import { ObjectDataListBase } from '@/models/ObjectDataListBase';
    import { PlatformListItem } from '@/models/PlatformListItem';
    import { PlatformFilterData } from '@/models/PlatformFilterData';
    import { PlatformFilterOptions } from '@/models/PlatformFilterOptions';
    import { DataObjectList } from '@/models/DataObjectList';

    @Component
    export default class PlatformsIndex extends ObjectDataListBase<PlatformListItem, DataObjectList<PlatformListItem>, PlatformFilterData, PlatformFilterOptions> {

        private headers: Array<any>;
        private deleteDialog = false;
        private deleteId: number;

        created() {
            this.filteredLookupData = new PlatformFilterData();
            this.filterOptions = new PlatformFilterOptions();
            this.apiUrlBase = "/api/platforms/";
            this.options.sortBy = ["loadDateText", "platformName"];
            this.options.sortDesc = [true, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Platform", value: "platformName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "Data Owner", value: "dataOwnerName", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        // router link isn't working from within the data table, it was not recognising the use of 'item'
        platformLink(id: number) {
            this.$router.push({ path: '/admin/platforms/' + id });
        }

        private closeDeleteDialog() {
            this.deleteDialog = false;
        }

        private deleteItem(id: number) {
            this.deleteDialog = true;
            this.deleteId = id;
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            this.$axios.delete(this.apiUrlBase + this.deleteId)
                .then(response => {
                    this.showResponseFeedback(response);
                    this.getItemsFiltered();
                })
                .catch(err => {
                    this.showErrorMessage(err);
                }).finally(() => {
                    this.hideOverlay();
                });
        }

        constructor() {
            super();
        }

    }
