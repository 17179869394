import { ENV_CONFIG } from '../../env.config'

export const AUTH_CONFIG = {
    credentials: {
        clientId: ENV_CONFIG.Auth.ClientId,
        tenantId: 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e' // "organization" would be more appropriate but msal-browser doesn't seem to support it
    },
    configuration: {
        redirectUri: ENV_CONFIG.Auth.RedirectUri,
        postLogoutRedirectUri: '/logout'
    },
    resources: {
        // I have disabled the use of graph api so that we don't need to make 2 separate API calls with 2 different access tokens, now user data comes from the database
        //graphApi: {
        //    resourceUri: "https://graph.microsoft.com/v1.0/me",
        //    resourceScopes: ["User.Read"]
        //},
        ApiScope: {
            resourceScopes: [ENV_CONFIG.Auth.BaseUrl + "/access_as_user"]
        }
    }
}
