export class TargetSourceAttribute {
    targetSourceAttributeId: number;
    targetObjectAttributeId: number;
    targetObjectAttributeName: string;
    sourceObjectAttributeId: number;
    sourceObjectAttributeName: string;
    description: string;
    name: string;
    loadDate: string;
    refreshDate: string;
    isEditable: boolean;
}
