
    import { Component, Prop, Ref } from 'vue-property-decorator';
    import SubjectAreaForm from '@/components/SubjectAreaForm';
    import SubjectAreaTargetObjects from '@/components/SubjectAreaTargetObjects';
    import { ComponentBase } from '@/models/ComponentBase';
    import DashboardCard from '@/components/DashboardCard';
    import { FormStateType } from '@/models/FormStateType';
    import { IGetItemsFiltered } from '@/interfaces/IGetItemsFiltered';
    import { IGetItem } from '@/interfaces/IGetItem';

    @Component({
        components: {
            DashboardCard,
            SubjectAreaForm,
            SubjectAreaTargetObjects
        }
    })
    export default class SubjectAreasEdit extends ComponentBase {

        @Prop()
        public subjectAreaId: number; // this prop comes via route parameter
        public subjectAreaNameTitle = "Edit Subject Area";

        private formState: FormStateType = FormStateType.Edit;

        private breadcrumbItems = [
            {
                text: 'Back to subjectAreas',
                disabled: false,
                href: '/subjectAreas',
            },
            {
                text: 'Edit',
                disabled: true
            }
        ];

        @Ref() readonly subjectAreaFormRef!: IGetItem;
        @Ref() readonly subjectAreaTargetObjectRef!: IGetItemsFiltered;

        private async setSubjectAreaName(value: string) {
            this.subjectAreaNameTitle = value;
        }

        private refreshAll() {
            this.subjectAreaFormRef!.getItem(this.subjectAreaId);
            this.subjectAreaTargetObjectRef!.getItemsFiltered();
        }

        constructor() {
            super();
        }



    }
