import { ActionTree } from 'vuex'
import { EventsState } from './types'
import { RootState } from '../types'
import Vue from 'vue'
import { AxiosResponse } from 'axios'

export const actions: ActionTree<EventsState, RootState> = {
    async setBatchLock({ commit, state }) {

        await Vue.prototype.$axios.get('/api/batch/in-progress').then((resp: AxiosResponse<boolean>) => {
            commit("toggleBatchLock", resp.data);
        });

        if (state.isBatchLocked) {
            this.dispatch('notifications/showInfo', { title: 'Scheduled job in progress', message: 'There is a scheduled job in progress that must be completed. Please wait and try refreshing later. Editing/Saving is currently disabled.', timeout: -1 })
        }
    }
}
