import jsPDF from "jspdf";
export const getParsedData = (data) => {
    const dimensionTableGroups = groupBy(data, "primaryObjectName");
    const factTableGroups = groupBy(data, "foreignObjectName");
    return {
        dimensionTableGroups,
        factTableGroups
    };
};
let finalHashYforArrows={}



export const GetPointsForArrows=(factItemPosition,targetItemPosition,positionGroup,item)=>{
    let points;
    const y= checkIfYExists(targetItemPosition.y,item);
    finalHashYforArrows[item.primaryObjectName+item.foreignObjectName+item.primaryAttributeName+item.foreignAttributeName]=y
    finalHashYforArrows[y]=y;
    switch(positionGroup){
    case 'p1':{
        points= [
        factItemPosition.x - 6,
        factItemPosition.y +8,
        factItemPosition.x - 50,
        factItemPosition.y +8,
        factItemPosition.x - 50,
      y + 10,
        targetItemPosition.x +310,
      y + 10
    ];
   
    break;
}
   case  'p2':{
   
        points= [
            factItemPosition.x - 10,
            factItemPosition.y +8,
            factItemPosition.x - 20,
            factItemPosition.y +8,
            factItemPosition.x - 20,
          y,
            targetItemPosition.x + 310,
          y
    ];
       
        break;}
  case  'p3':{
    points=  [
        factItemPosition.x + 306,
        factItemPosition.y +8,
        factItemPosition.x + 320,
        factItemPosition.y+8,
        factItemPosition.x + 320,
      y + 10,
        targetItemPosition.x - 20,
      y + 10
    ];
    break;}
   case 'p4':{
    points=  [
        factItemPosition.x + 306,
        factItemPosition.y+8,
        factItemPosition.x + 326,
        factItemPosition.y+8,
        factItemPosition.x + 326,
      y,
        targetItemPosition.x - 10,
      y
];
    break;}
    case 'p5':{
        points= [
        factItemPosition.x - 6,
        factItemPosition.y+8,
        factItemPosition.x - 30,
        factItemPosition.y+8,
        factItemPosition.x - 30,
      y + 10,
        targetItemPosition.x - 5,
      y + 10
    ];
   
    break;}
case 'p6':{
   
    points=  [
    factItemPosition.x - 16,
                        factItemPosition.y+8,
                        factItemPosition.x - 40,
                    factItemPosition.y+8,
                        factItemPosition.x - 40,
                     y + 10,
                        targetItemPosition.x - 5,
                     y + 10
];
break;}
default:{

    points=  [
        factItemPosition?.x,
        factItemPosition?.y,
        targetItemPosition?.x,
        targetItemPosition?.y
    ];
}
    


}
return points;
}


const checkIfYExists=(y,item)=>{
   if(item.primaryObjectName+item.foreignObjectName+item.primaryAttributeName+item.foreignAttributeName in finalHashYforArrows){
    return finalHashYforArrows[item.primaryObjectName+item.foreignObjectName+item.primaryAttributeName+item.foreignAttributeName]
   }else{
       while(y in finalHashYforArrows){
           y=y+7;
       }
   }
    

    return y
}

export const getData =(factTableGroups,dimensionTableGroups)=>{
   
    Object.keys(factTableGroups).map((item,index)=>{
       
            if(dimensionTableGroups[item]){
                // if(dimensionTableGroups[item].length<factTableGroups[item].length){
                //     delete dimensionTableGroups[item];
                //     return
                // }
           if(factTableGroups[item].length>1){
              factTableGroups[item].map((innerItem,index)=>{
                //if(innerItem.primaryObjectName === innerItem.foreignObjectName) return;
               if(dimensionTableGroups[item]){
                   // get the value 
                   const getItem = dimensionTableGroups[item];
                  const itemToBeCheckedOne = getItem.filter(i=>i.foreignAttributeId === innerItem.foreignAttributeId);
                
                   if(itemToBeCheckedOne.length > 0){
                  itemToBeCheckedOne.map((j,itemToBeCheckedIndex)=>{

                   if(j.foreignAttributeId === innerItem.foreignAttributeId)return;

                  })
               }else{
                const itemToBeAdded = {...innerItem};
                   if(itemToBeAdded.foreignObjectType === itemToBeAdded.primaryObjectType){
                    itemToBeAdded['addedType']=itemToBeAdded.primaryObjectName===item?'dimension':'fact'
                   }else{
        
                       itemToBeAdded['addedType'] = 'dimension';
                   }
                   dimensionTableGroups[item]=[...dimensionTableGroups[item],itemToBeAdded];
                  
                   delete factTableGroups[item]
               }
            }
              
              })
           }else{
           const itemToBeAdded = {...factTableGroups[item][0]};
           if(itemToBeAdded.foreignObjectType === itemToBeAdded.primaryObjectType){
            itemToBeAdded['addedType']=itemToBeAdded.primaryObjectName===item?'dimension':'fact'
           }else{

               itemToBeAdded['addedType'] = 'dimension';
           }
           dimensionTableGroups[item]=[...dimensionTableGroups[item],itemToBeAdded];
           delete factTableGroups[item]
         }
        }
        
    })
    

    
    return {dimensionTableGroups,factTableGroups};
    
    }

export const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const getHeighAndWidthOfWindow = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    return { height, width };
};

export const attachWindowResizer = () => {
    window.addEventListener("resize", getHeighAndWidthOfWindow, false);
};

export const removeDuplicatesByKey = (arr, key) => {
    let unique = {};
    let result = [];

    for (let i = 0; i < arr.length; i++) {
        if (!unique[arr[i][key]]) {
            result.push(arr[i]);
            unique[arr[i][key]] = true;
        }
    }

    return result;
};


export const getUpdatedDate = (metadata) => {
    let date;
    metadata.map((item) => {
        if (date === undefined) {
            date = item.factLoadDate;
        } else {
            if (new Date(date).getTime() < new Date(item.factLoadDate).getTime()) {
                date = item.factLoadDate;
            }
        }
    });
    let updatedDate = new Date(date).toLocaleString("en-ZA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
    });
    const dateRegex = /\//gi;
    return updatedDate.replaceAll(dateRegex, "-").toUpperCase();
};

export const getUpdatedDateHelper = (data,key) => {
    let date;
    data.map((item) => {
        if (date === undefined) {
            date = item[key];
        } else {
            if (new Date(date).getTime() < new Date(item[key]).getTime()) {
                date = item[key];
            }
        }
    });
   return getDateAsCorrectFormat(date);
};

export const getDateAsCorrectFormat=(date)=>{
    let updatedDate = new Date(date).toLocaleString("en-ZA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
    });
    const dateRegex = /\//gi;
    return updatedDate.replaceAll(dateRegex, "-").toUpperCase();
}

export const ZoomInZoomOut = ({stage, scaleBy, direction,isERD=true}) => {
    var oldScale = stage.scaleX();
    // this from here can be cleaned and refactored
    const center = {
        x: stage.width() / 1.5,
        y: stage.height() / 1.5
    };
    const relatedTo = {
        x: (center.x - stage.x()) / oldScale,
        y: (center.y - stage.y()) / oldScale
    };
    const newScale = direction > 0 ? oldScale * scaleBy : oldScale /scaleBy;
    stage.scale({
        x: newScale,
        y: newScale
    });
    let newPos;
    if(isERD){

        if(direction>0){
            newPos={
                x: center.x - relatedTo.x * newScale,
                y: center.y - relatedTo.y * newScale
            }
        }else{
            newPos={
                x:  0,
                y: 0
            }
        }
    }else{
        newPos={
            x:  0,
            y: 0
        }
    }
  
    stage.position(newPos);
    stage.batchDraw();
};
export const downloadCanvasAsPdf = async ({
    width,
    konvaHeight,
    baseHeight,
    floatingHeight,
    currentStageForFloating,
    productName = "download",
    currentStageForTitle,
    showFloating = false,
    currentStage
}) => {
    var pdf = new jsPDF("l", "px", [width + 500, baseHeight]);
    pdf.addImage(currentStageForTitle.toDataURL({ pixelRatio: 2 }), 0, 0, 1500, 350);
    if (showFloating) {
        //   var currentStageForFloating = this.$refs.stageRefForFloating.getStage();
        pdf.addImage(
            currentStageForFloating.toDataURL({ pixelRatio: 2 }),
            0,
            330,
            400,
            floatingHeight
        );
        pdf.addImage(currentStage.toDataURL({ pixelRatio: 2 }), 350, 330, width, konvaHeight);
    } else {
        pdf.addImage(currentStage.toDataURL({ pixelRatio: 2 }), 0, 330, width, konvaHeight);
    }

    pdf.save(`${productName}.pdf`);
};

export const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
};
