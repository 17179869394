import Vue from 'vue'
import './plugins/axios'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from '@/store/index'
import './registerServiceWorker'
import dateFilter from '@/filters/date.filter'
import { AuthService } from '@/services/AuthService'
import { UserService } from '@/services/UserService'
import VueKonva from 'vue-konva'
Vue.config.productionTip = false

Vue.filter('date', dateFilter)
Vue.use(VueKonva)
// instance object for auth service so that multiple instances don't conflict during redirect & response
Vue.prototype.$authService = new AuthService()
Vue.prototype.$userService = new UserService()

function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

// don't render vue app inside of iframe this is to stop a silent auth request going to redirect uri (homepage) and then triggering an authentication loop and the below error
// block_iframe_reload: Request was blocked inside an iframe because MSAL detected an authentication response

if (!inIframe()) {
  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}
