
    import { Vue, Component, Provide, Prop, Watch } from 'vue-property-decorator'
    import { TargetSourceAttribute } from '@/models/TargetSourceAttribute';
    import { DataObjectList } from '@/models/DataObjectList';
    import { TargetSourceAttributeListItem } from '@/models/TargetSourceAttributeListItem';
    import { TargetSourceAttributeLookupData } from '@/models/TargetSourceAttributeLookupData';
    import { TargetSourceAttributeFilterData } from '@/models/TargetSourceAttributeFilterData';
    import FadeTooltip from '@/components/FadeTooltip'
    import { ObjectDataListEditorBase } from '@/models/ObjectDataListEditorBase';
    import { TargetSourceAttributeFilterOptions } from '@/models/TargetSourceAttributeFilterOptions';

    @Component({
        components: {
            FadeTooltip
        }
    })
    export default class TargetSourceAttributes extends ObjectDataListEditorBase<TargetSourceAttribute, TargetSourceAttributeListItem, DataObjectList<TargetSourceAttributeListItem>, TargetSourceAttributeLookupData, TargetSourceAttributeFilterData, TargetSourceAttributeFilterOptions>  {

        private headers: Array<any>;

        // editedItem is being overridden and made observable because the inherited class cannot initialise it using the generic type
        // this needs to be done on the property for it to be added to Vue @data and made observable, doesn't work in created()
        protected editedItem: TargetSourceAttribute = Vue.observable(new TargetSourceAttribute());

        created() {
            this.filteredLookupData = new TargetSourceAttributeFilterData();
            this.lookupData = new TargetSourceAttributeLookupData();
            this.filterOptions = new TargetSourceAttributeFilterOptions();
            this.apiUrlBase = "/api/targetsourceattributes/";
            this.options.sortBy = ["loadDateText", "targetObjectName", "targetObjectAttributeName", "sourceObjectName", "sourceObjectAttributeName"];
            this.options.sortDesc = [true, false, false, false, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Target Attribute Name", value: "targetObjectAttributeName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Target Object", value: "targetObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Source Attribute Name", value: "sourceObjectAttributeName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Source Object", value: "sourceObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        constructor() {
            super();
        }
    };
