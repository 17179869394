import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId';
import { DropdownOption } from '@/models/DropdownOption';

export class PlatformLookupData {
    platforms: Array<DropdownOptionIntegerId>;
    dataOwners: Array<DropdownOptionIntegerId>;
    platformLayers: Array<DropdownOption>;
    globalOrRegionals: Array<DropdownOption>;
    internalExternals: Array<DropdownOption>;
    //platformRegions: Array<DropdownOption>;
}