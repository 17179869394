import {
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
  AccountInfo,
  InteractionRequiredAuthError,
  RedirectRequest,
  PopupRequest,
  EndSessionRequest
} from '@azure/msal-browser'
import { MSAL_CONFIG } from '@/config/MSALConfig'
import { AUTH_CONFIG } from '@/config/AuthConfig'
import { SsoSilentRequest } from '@azure/msal-browser/dist/request/SsoSilentRequest'

export interface IAuthService {
  loadAuthModule(): Promise<AccountInfo | null>
  handleResponse(response: AuthenticationResult | null): void
  attemptSsoSilent(): void
  login(signInType: string): Promise<void>
  logout(): void
  //getProfileTokenRedirect(): Promise<string | null>;
  //getProfileTokenPopup(): Promise<string | null>;
  getAPITokenRedirect(): Promise<string | null>
  getAPITokenPopup(): Promise<string | null>
}

export class AuthService implements IAuthService {
  // For more info see: https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html
  public myMSALObj: PublicClientApplication
  private account: AccountInfo | null
  private loginRedirectRequest: RedirectRequest
  private loginRequest: PopupRequest
  //private profileRedirectRequest: RedirectRequest;
  //private profileRequest: PopupRequest;
  private APIRedirectRequest: RedirectRequest
  private APIRequest: PopupRequest
  //private silentProfileRequest: SilentRequest;
  private silentAPIRequest: SilentRequest
  private silentLoginRequest: SsoSilentRequest

  constructor() {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG)
    this.account = null

    this.loginRequest = {
      scopes: []
    }

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: AUTH_CONFIG.configuration.redirectUri,
      redirectUri: AUTH_CONFIG.configuration.redirectUri
    }

    this.APIRequest = {
      scopes: [...AUTH_CONFIG.resources.ApiScope.resourceScopes]
    }

    this.APIRedirectRequest = {
      ...this.APIRequest,
      redirectStartPage: AUTH_CONFIG.configuration.redirectUri,
      redirectUri: AUTH_CONFIG.configuration.redirectUri
    }

    this.silentAPIRequest = {
      scopes: [...AUTH_CONFIG.resources.ApiScope.resourceScopes],
      forceRefresh: false
    }

    this.silentLoginRequest = {
      //loginHint: ""
    }

    //this.profileRequest = {
    //    scopes: [...AUTH_CONFIG.resources.graphApi.resourceScopes]
    //};

    //this.profileRedirectRequest = {
    //    ...this.profileRequest,
    //    redirectStartPage: AUTH_CONFIG.configuration.redirectUri,
    //    redirectUri: AUTH_CONFIG.configuration.redirectUri
    //};

    //this.silentProfileRequest = {
    //    scopes: ["openid", "profile", ...AUTH_CONFIG.resources.graphApi.resourceScopes],
    //    forceRefresh: false
    //};
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
   * TODO: Add account chooser code
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  private getAccount(): AccountInfo | null {
    // need to call getAccount here?
    const currentAccounts = this.myMSALObj.getAllAccounts()
    if (currentAccounts === null) {
      console.log('No accounts detected')
      return null
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.log('Multiple accounts detected, need to add choose account code.')
      return currentAccounts[0]
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0]
    }

    return null
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  async loadAuthModule(): Promise<AccountInfo | null> {
    await this.myMSALObj
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
        this.handleResponse(resp)
      })
      .catch(console.error)
    return this.account
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  handleResponse(response: AuthenticationResult | null): void {
    if (response !== null) {
      this.account = response.account
    } else {
      this.account = this.getAccount()
    }
  }

  /**
   * Calls ssoSilent to attempt silent flow. If it fails due to interaction required error, it will prompt the user to login using popup.
   * @param request
   */
  attemptSsoSilent(): void {
    this.myMSALObj
      .ssoSilent(this.silentLoginRequest)
      .then(() => {
        this.account = this.getAccount()
        if (this.account) {
          // TODO: hook into showing feedback to the user
        } else {
          console.log('No account!')
        }
      })
      .catch((error) => {
        console.error('Silent Error: ' + error)
        if (error instanceof InteractionRequiredAuthError) {
          this.login('loginPopup')
        }
      })
  }

  /**
   * Calls loginPopup or loginRedirect based on given signInType.
   * @param signInType
   */
  async login(signInType: string): Promise<void> {
    if (signInType === 'loginPopup') {
      return await this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          this.handleResponse(resp)
        })
        .catch(console.error)
    } else if (signInType === 'loginRedirect') {
      return await this.myMSALObj.loginRedirect(this.loginRedirectRequest)
    }
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    let account: AccountInfo | undefined
    if (this.account) {
      account = this.account
    }
    const logOutRequest: EndSessionRequest = {
      account
    }

    this.myMSALObj.logout(logOutRequest)
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  //async getProfileTokenRedirect(): Promise<string | null> {
  //    if (this.account) {
  //        this.silentProfileRequest.account = this.account;
  //    }
  //    return this.getTokenRedirect(this.silentProfileRequest, this.profileRedirectRequest);
  //}

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
   */
  //async getProfileTokenPopup(): Promise<string | null> {
  //    if (this.account) {
  //        this.silentProfileRequest.account = this.account;
  //    }
  //    return this.getTokenPopup(this.silentProfileRequest, this.profileRequest);
  //}

  /**
   * Gets the token for API backend silently, or falls back to interactive redirect.
   */
  async getAPITokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.silentAPIRequest.account = this.account
    }
    return this.getTokenRedirect(this.silentAPIRequest, this.APIRedirectRequest)
  }

  /**
   * Gets the token to read API data from MS Graph silently, or falls back to interactive popup.
   */
  async getAPITokenPopup(): Promise<string | null> {
    if (this.account) {
      this.silentAPIRequest.account = this.account
    }
    return this.getTokenPopup(this.silentAPIRequest, this.APIRequest)
  }

  /**
   * Gets a token silently, or falls back to interactive popup.
   */
  private async getTokenPopup(
    silentRequest: SilentRequest,
    interactiveRequest: PopupRequest
  ): Promise<string | null> {
    try {
      const response: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(silentRequest)
      return response.accessToken
    } catch (e) {
      console.log('silent token acquisition fails.')
      if (e instanceof InteractionRequiredAuthError) {
        console.log('acquiring token using redirect')
        return this.myMSALObj
          .acquireTokenPopup(interactiveRequest)
          .then((resp) => {
            return resp.accessToken
          })
          .catch((err) => {
            console.error(err)
            return null
          })
      } else {
        console.error(e)
      }
    }

    return null
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest)
      return response.accessToken
    } catch (e) {
      console.log('silent token acquisition fails.')
      if (e instanceof InteractionRequiredAuthError) {
        console.log('acquiring token using redirect')
        this.myMSALObj.acquireTokenRedirect(interactiveRequest).catch(console.error)
      } else {
        console.error(e)
      }
    }

    return null
  }
}
