import Vue from "vue";
import { SchemaType } from "./types";
import { RootState } from "../types";
import { ActionTree } from "vuex";

export const actions: ActionTree<SchemaType, RootState> = {
    async setMainMetaData({ commit }, url) {
        try {
            await Vue.prototype.$axios.get(url).then((response: any) => {
                commit("setMetaData", response.data);
            });
        } catch (error) {
            this.dispatch("notifications/showError", {
                title: "Error",
                message: "Failed to get DMV Items"
            });
        }
    },
    async setStarSchemaAttributesForTargetObject({ commit }, targetObjectId) {
        try {
            await Vue.prototype.$axios
                .get(`/api/targetobjects/${targetObjectId}/star-schema-attributes`)
                .then((response: any) => {
                    commit("setToaData", { targetObjectId: targetObjectId, data: response.data });
                });
        } catch (error) {
            this.dispatch("notifications/showError", {
                title: "Error",
                message: "Failed to get DMV Items"
            });
        }
    },

    async setStarSchemaAttributesForTargetObjectErd({ commit }, targetObjectId) {
        try {
            await Vue.prototype.$axios
                .get(`/api/targetobjects/${targetObjectId}/star-schema-attributes`)
                .then((response: any) => {
                    const data = { targetObjectId: targetObjectId, data: response.data };
                    commit("setToaDataForErd", data);
                });
        } catch (error) {
            this.dispatch("notifications/showError", {
                title: "Error",
                message: "Failed to get ERD Items"
            });
        }
    },

    async addPositionsOfChildNodes({ commit }, data) {
        try {
            commit("setPositions", data);
        } catch (error) {
            Promise.reject(error);
        }
    },

    async setChildNodePositions({ commit }) {
        try {
            commit("setInitialPositions");
        } catch (error) {
            Promise.reject(error);
        }
    }
};
