export interface IProcessEnv {
  NODE_ENV: string
  BASE_URL: string
  VUE_APP_BASE_URL: string
  VUE_APP_AUTH_CLIENT_ID: string // custom variables for VUE have to be prefixed with VUE_APP_
  VUE_APP_AUTH_URI: string
  VUE_APP_AUTH_REDIRECT_URI: string
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ProcessEnv extends IProcessEnv {}
  }
}

export const ENV_CONFIG = {
  Auth: {
    ClientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    Uri: process.env.VUE_APP_AUTH_URI,
    RedirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI,
    BaseUrl: process.env.VUE_APP_BASE_URL
  }
}
