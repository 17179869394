
export class ProductDimensionListItem {
    productId: number;
    dimensionId: number;
    dimensionName: string;
    platformId: number;
    platformName: string;
    loadDate: string;
    loadDateText: string;
}
