
    import { Component, Provide, Vue, Watch, ProvideReactive } from 'vue-property-decorator'
    //import ObjectRelationAttributeRow from '@/components/ObjectRelationAttributeRow.vue'
    import store from '@/store'
    import { ComponentBase } from '@/models/ComponentBase'
    import { DataObjectList } from '@/models/DataObjectList';
    import { GraphEdgeListItem } from '@/models/GraphEdgeListItem';
    import { GraphEdgeRelation } from '@/models/GraphEdgeRelation';
    //import { ObjectRelationAttribute } from '@/models/ObjectRelationAttribute';
    import { GraphEdgeLookupData } from '@/models/GraphEdgeLookupData';
    import { GraphEdgeFilterData } from '@/models/GraphEdgeFilterData';
    import { GraphEdgeFilterOptions } from '@/models/GraphEdgeFilterOptions';
    import { GraphEdgeValidation } from '@/models/GraphEdgeValidation';
    import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId'
    import { DataOptions } from 'vuetify/types'

    @Component({
        components: {
            //  ObjectRelationAttributeRow
        }
    })
    export default class GraphEdges extends ComponentBase {

        public headerClass = "text-uppercase text-subtitle-1 primary--text font-weight-bold";
        public cellClass = "text-subtitle-1 primary--text";
        public totalItems = 0;

        public items: Array<GraphEdgeListItem> = [];
        // The editedItem object is initialised here so that vue-property-decorator makes it an observable @data property
        // any reassigning of whole object needs to be done like this: Object.assign({}, x); so that the observable object is not replaced
        public editedItem: GraphEdgeRelation = new GraphEdgeRelation();

        @ProvideReactive()
        public lookupData: GraphEdgeLookupData = new GraphEdgeLookupData();
        public filteredLookupData: GraphEdgeFilterData = new GraphEdgeFilterData();
        public filterOptions: GraphEdgeFilterOptions = new GraphEdgeFilterOptions();
        public validation: GraphEdgeValidation = new GraphEdgeValidation();


        private apiUrlBase = "/api/graphEdges/";

        private timerId: number;
        private loading = true;
        private overlay = false;
        private attrOverlay = false;


        public options: DataOptions = {
            page: 1,
            sortBy: ["loadDateText", "graphEdgeObjectName"],
            itemsPerPage: 20,
            sortDesc: [true, false],
            groupBy: [],
            groupDesc: [false],
            multiSort: true,
            mustSort: false
        };

        private isBatchLocked = false;
        public editDialog = false;
        public deleteDialog = false;
        public dqRiConfirmDialog = false;
        public editItemIsValid = false;
        public IsRelationValid = false;
        private isExisting = false;
        public relationTypeId = 0;
        public createNew = false;

        private headers: Array<any> = [
            { text: "Graph Edge", value: "graphEdgeObjectName", class: this.headerClass, cellClass: this.cellClass },
            { text: "Source Vertex", value: "sourceVertexObjectName", class: this.headerClass, cellClass: this.cellClass },
            { text: "Target Vertex", value: "targetVertexObjectName", class: this.headerClass, cellClass: this.cellClass },
            { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
            { text: "Actions", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
        ];


        private async getItems() {
            try {
                this.loading = true;
                // if (this.filterOptions.relationTypeIds == 0) { this.filterOptions.relationTypeIds = 4; }
                await this.$axios.get<DataObjectList<GraphEdgeListItem>>(this.apiUrlBase, {
                    params: Object.assign(this.options, this.filterOptions)
                })
                    .then(response => {
                        this.items = response.data.items;
                        this.totalItems = response.data.totalItems;
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get list");
                        return Promise.reject(err);
                    });
            } finally {
                this.loading = false;
            }
        }

        private async getItem(id: number) {
            await this.$axios.get<GraphEdgeRelation>(this.apiUrlBase + id)
                .then(response => {
                    this.editedItem = Object.assign({}, response.data);
                    //this.objectRelationAttributes = [...response.data.objectRelationAttributes]; // clone the array to the existing one to not lose the Vue change tracking
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get item");
                    return Promise.reject(err);
                });
        }

        private async getExistStatus() {
            if (this.editedItem.graphEdgeObjectId && this.editedItem.sourceVertexObjectId && this.editedItem.targetVertexObjectId) {
                await this.$axios.get<GraphEdgeValidation>(this.apiUrlBase + "exist-status", {
                    params: { gid: this.editedItem.graphEdgeObjectId, sid: this.editedItem.sourceVertexObjectId, tid: this.editedItem.targetVertexObjectId }
                })
                    .then(response => {
                        this.validation = response.data;
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get existing status");
                        return Promise.reject(err);
                    });
            }
        }

        private async getFilteredLookupData() {
            //if (this.filterOptions.relationTypeIds == null) {
            //    this.filterOptions.relationTypeIds = 4
            //}
            await this.$axios.get<GraphEdgeFilterData>(this.apiUrlBase + "filter-data", {
                params: this.filterOptions
            }).then(response => {
                this.filteredLookupData = response.data;
            })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

        private async getLookupData(graphEdgeId: number, sourceVertex: number, targetVertex: number, edgeDirection: number) {
            await this.$axios.get<GraphEdgeLookupData>(this.apiUrlBase + "lookup-data", {
                params: { gid: graphEdgeId, sid: sourceVertex, tid: targetVertex, ed: edgeDirection }
            })
                .then(response => {
                    this.lookupData = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

    

        private createItem() {
            this.createNew = true;
            this.showOverlay();
            this.resetLookupData();
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                this.getLookupData(0, 0, 0, 0)
                    .then(x => {
                        this.editedItem = Object.assign({}, new GraphEdgeRelation());
                        this.editDialog = true;
                    }).finally(() => {
                        this.hideOverlay();
                    });
            });
        }

        private editItem(item: GraphEdgeListItem) {
            this.showOverlay();
            this.createNew = false;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                this.getLookupData(item.graphEdgeObjectId, item.sourceVertexObjectId, item.targetVertexObjectId, item.edgeDirection)
                    .then(x => {
                        this.getItem(item.id) // must be fetched before setting attr lookup data
                            .then(x => {
                                //  this.getAttributeLookupData()
                                //    .then(x => {
                                this.editDialog = true;
                            }).finally(() => {
                                this.hideOverlay();
                                // });
                            });
                    });
            });
        }

        private loadVertexLookup() {
            //this.editedItem.primaryTargetObjectId = null;
            //this.editedItem.foreignTargetObjectId = null;
            this.resetLookupData()

            this.getLookupData(this.editedItem.graphEdgeRelationId, this.editedItem.sourceVertexObjectId, this.editedItem.targetVertexObjectId, this.editedItem.edgeDirection)
            this.getExistStatus();
            //this.setObjectRelationAttributes();
        }


        private resetLookupData() {
            this.validation.recordExists = false;
            this.validation.sourceVertexMorePrimary = false;
            this.validation.sourceVertexNoPrimary = false;
            this.validation.targetVertexMorePrimary = false;
            this.validation.targetVertexNoPrimary = false;
        }

       
        private deleteItem(item: GraphEdgeListItem) {
            this.getItem(item.id)
                .then(x => {
                    this.deleteDialog = true;
                });
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.$axios.delete(this.apiUrlBase + this.editedItem.graphEdgeRelationId)
                        .then(response => {
                            this.showResponseFeedback(response);
                            this.getItemsFiltered();
                        })
                        .catch(err => {
                            this.showErrorMessage(err);
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            });
        }


        private async save() {
            this.editDialog = false; // not using closeEditDialog because we do not want to reset the lookup data before it is saved
            this.showOverlay();
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        //this.editedItem.objectRelationAttributes = this.objectRelationAttributes;

                        if (this.editedItem.graphEdgeRelationId > 0) {
                            this.$axios.put(this.apiUrlBase + this.editedItem.graphEdgeRelationId, this.editedItem)
                                .then(response => {
                                    this.showResponseFeedback(response);
                                    this.getItemsFiltered();
                                })
                                .catch(err => {
                                    this.showErrorMessage(err);
                                }).finally(() => {
                                    this.hideOverlay();
                                });
                        }
                        else {
                            this.$axios.post(this.apiUrlBase, this.editedItem)
                                .then(response => {
                                    this.showResponseFeedback(response);
                                    this.getItemsFiltered();
                                })
                                .catch(err => {
                                    this.showErrorMessage(err);
                                }).finally(() => {
                                    this.hideOverlay();
                                });
                        }
                    } else {
                        this.hideOverlay();
                    }
                }

            });
        }

        get isEditLocked() {
            return (this.editedItem);// && this.editedItem.graphEdgeObjectId > 0);
        }

        get isCreate() {
            return (this.createNew);
        }

        private closeEditDialog() {
            this.resetLookupData();
            this.editDialog = false;
        }

        private closeDeleteDialog() {
            this.deleteDialog = false;
        }

        @Watch("options", { deep: true })
        public getItemsByOptions(val: string | DataOptions) {
            // cancel the delay
            clearTimeout(this.timerId);

            // delay on searching when user types
            this.timerId = setTimeout(() => {
                this.getItemsFiltered();
            }, 1500);
        }

        private getItemsFiltered() {
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            });

            this.showOverlay();
            this.getFilteredLookupData().then(x => {
                this.getItems().finally(() => {
                    this.hideOverlay();
                });
            });
        }

        private showOverlay() {
            this.overlay = true;
        }

        private hideOverlay() {
            this.overlay = false;
        }

        private showAttrOverlay() {
            this.attrOverlay = true;
        }

        private hideAttrOverlay() {
            this.attrOverlay = false;
        }

        constructor() {
            super();
        }
    }
