export class ProductDimensionMember {
    productDimensionMemberId: number;
    productId: number;
    dimensionMemberId: number;
    dimensionMemberName: string;
    description: string;
    displayName: string;
    isAllMember: boolean;
    loadDate: string;
    refreshDate: string;
}
