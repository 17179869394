import { ActionTree } from 'vuex'
import { NotificationState } from './types'
import { RootState } from '../types'

export const actions: ActionTree<NotificationState, RootState> = {
    showError({commit}, options) {
        commit("showError", options)
    },
    showWarning({ commit }, options) {
        commit("showWarning", options)
    },
    showInfo({ commit }, options) {
        commit("showInfo", options)
    },
    showSuccess({commit}, options) {
        commit("showSuccess", options)
    },
    hideNotification({ commit }) {
        commit("hideNotification")
    }
}
