export const UserRole = {
    User: 0,
    UserAdmin: 1,
    DataAdmin: 2,
    DataOwner: 3,
    DataSetOwner: 4,
    DataArchitect: 5,
    UDLPlatformApprovers: 7,
    DQEngagement: 13,
    DQTranslator: 14,
    DQAdmin: 15
}
