import { Action, Mutation, Getter } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import { SnackbarNotificationOptions } from './SnackbarNotificationOptions'
import { AxiosResponse } from 'axios'

@Component
export class ComponentBase extends Vue {
    protected notAuthorisedText = "You are not authorised";

    @Action('showSuccess', { namespace: 'notifications' })
    public showSuccessMessage!: (options: SnackbarNotificationOptions) => void
    @Action('showError', { namespace: 'notifications' })
    public showFailureMessage!: (options: SnackbarNotificationOptions) => void
    @Getter('isAuthenticated', { namespace: 'auth' })
    public isAuthenticated: boolean
    @Getter('isUserAdmin', { namespace: 'auth' })
    public isUserAdmin: boolean
    @Getter('isDataAdmin', { namespace: 'auth' })
    public isDataAdmin: boolean

    protected showResponseFeedback(response: AxiosResponse) {
        switch (response.status) {
            case 200:
                this.showSuccessMessage({ title: "Success", message: "Success! Your changes have been applied" });
                break;
            case 404:
                this.showFailureMessage({ title: "Not Found", message: "Item was not found" });
                break;
            default:
                this.showFailureMessage({ title: "Error", message: "Sorry an error occurred" });
                break;
        }
    }

    protected showErrorMessage(err: any, defaultText?: string) {
        let errorTitle = "Error";
        let errorMessage = defaultText ?? "Sorry an error occurred";
        if (err.response && (err.response.status == 401 || err.response.status == 403)) {
            errorMessage = this.notAuthorisedText;
        }

        if (err.response && (err.response.status == 404)) {
            return this.$router.push({ path: '/notfound' });
        }

        if (err.response && err.response.data && err.response.status == 400) {
            errorTitle = err.response.data.title;
            errorMessage = err.response.data.detail;
        }

        this.showFailureMessage({ title: errorTitle, message: errorMessage });
    }

}
