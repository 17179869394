
    import { Component, Provide, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
    import store from '@/store'
    import { ComponentBase } from '@/models/ComponentBase';
    import { Platform } from '@/models/Platform';
    import { PlatformLookupData } from '@/models/PlatformLookupData';
    import { FormStateType } from '@/models/FormStateType';
    import { IGetItem } from '@/interfaces/IGetItem';

    @Component
    export default class PlatformsForm extends ComponentBase implements IGetItem {
        @Prop()
        public platformId: number;
        @Prop()
        public formState: FormStateType;

        // The editedItem object is initialised here so that vue-property-decorator makes it an observable @data property
        // any reassigning of whole object needs to be done like this: Object.assign({}, x); so that the observable object is not replaced
        public editedItem: Platform = new Platform();

        public lookupData: PlatformLookupData = new PlatformLookupData();

        protected apiUrlBase = "/api/platforms/";
        protected overlay = false;
        protected isBatchLocked = false;
        private editItemIsValid = true;
        private isNameExisting = false;

        private numberRule: Array<(data: string) => void> = [
            (v: any) => (!v || /^(0|[1-9]\d{0,8})$/.test(v)) || 'Please enter a number 0 to 999999999.'
        ];

        public async getItem(id: number) {
            if (this.formState == FormStateType.Edit) {
                this.showOverlay();
                await this.$axios.get<Platform>(this.apiUrlBase + id)
                    .then(response => {
                        this.$nextTick(() => {
                            this.editedItem = Object.assign({}, response.data);
                            this.setPlatformName(this.editedItem.platformName);
                        });
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get item");
                    }).finally(() => {
                        this.hideOverlay();
                    });
            }

        }

        protected async getLookupData() {
            await this.$axios.get<PlatformLookupData>(this.apiUrlBase + "lookup-data")
                .then(response => {
                    this.lookupData = Object.assign({}, response.data);
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                });
        }


        private async create() {
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.getNameExists().then(() => {
                            if (!this.isNameExisting) {
                                this.$axios.post(this.apiUrlBase, this.editedItem)
                                    .then(response => {
                                        this.$router.push({ path: '/admin/platforms/' }).then(() => {
                                            this.showResponseFeedback(response);
                                        });
                                    })
                                    .catch(err => {
                                        this.showErrorMessage(err);

                                    }).finally(() => {
                                        this.hideOverlay();
                                    });
                            } else {
                                this.hideOverlay();
                            }
                        });
                    }
                }
            });

        }

        private async save() {
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.getNameExists().then(() => {
                            if (!this.isNameExisting) {
                                this.$axios.put(this.apiUrlBase + this.editedItem.platformId, this.editedItem)
                                    .then(response => {
                                        this.showResponseFeedback(response);
                                    })
                                    .catch(err => {
                                        this.showErrorMessage(err);
                                    }).finally(() => {
                                        this.hideOverlay();
                                    });
                            } else {
                                this.hideOverlay();
                            }
                        });
                    }
                }
            });

        }

        private async getNameExists() {
            await this.$axios.get<boolean>(this.apiUrlBase + "name-exists", {
                params: { platformId: this.platformId, platformName: this.editedItem.platformName }
            })
                .then(response => {
                    this.isNameExisting = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get existing name status");
                });
        }

        protected showOverlay() {
            this.overlay = true;
        }

        protected hideOverlay() {
            this.overlay = false;
        }

        // used to set the Platform name in the parent component
        private setPlatformName(value: string) {
            return this.$emit("setPlatformName", value);
        }

        get isCreateState() {
            return this.formState == FormStateType.Create;
        }

        get isEditState() {
            return this.formState == FormStateType.Edit;
        }

        created() {
            this.lookupData = new PlatformLookupData();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                Promise.all([this.getLookupData(), this.getItem(this.platformId)])
                    .finally(() => {
                        this.hideOverlay();
                    });
            });
        }

        constructor() {
            super();
        }



    }
