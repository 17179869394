export class SubjectAreaTargetObject {
    subjectAreaTargetObjectId: number;
    subjectAreaId: number;
    targetObjectIds: Array<number>;
    dataOwnerIds: Array<number>;
    platformIds: Array<number>;
    dataSetIds: Array<number>;
    loadDate: string;

    // additional properties
    subjectAreaName: string;
}
