
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import TopNavigation from '@/components/TopNavigation';

    @Component({
        components: {
            TopNavigation
        }
    })
    export default class PageHeader extends Vue {

        homeLink() {
            // conditional check to stop a navigation duplicate error
            var path = '/';
            if (this.$route.path !== path) {
                this.$router.push({ path: path });
            }
        }

        constructor() {
            super()
        }
    }
