import { MutationTree } from "vuex";
import { SchemaType } from "./types";

export const mutations: MutationTree<SchemaType> = {
    setData(state, data) {
        state.data = [...state.data, ...data];
    },
    setToaData(state, data) {
        state.toaData[data.targetObjectId] = data.data;
    },

    setMetaData(state, data) {
        state.metaData = data;
    },
    setInitialPositions(state) {
        state.childNodePositions = {};
        state.data = [];
        state.metaData = [];
    },

    setFloatingData(state, data) {
        state.floatingData = data;
    },
    setToaDataForErd(state, data) {
        state.toaData[data.targetObjectId] = data.data;
    },
    setPositions(state, data) {
        state.childNodePositions = { ...state.childNodePositions, ...data };
    }
};
