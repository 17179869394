export class TargetObjectAttribute {
    targetObjectAttributeId: number;
    targetObjectAttributeName: string;
    aggregationMethod: string;
    alternateKey: boolean;
    dataClassificationDetailId: number;
    dataFormat: string;
    dataOwnerId: number;
    dataTypeCheck: boolean;
    dataTypeId: number;
    description: string;
    defaultValue: string;
    displayName: string;
    duplicateCheck: boolean;
    foreignKey: boolean;
    fsreviewRename: string;
    glossaryTermId: number;
    hierarchyLevel: string;
    kpiareaId: number;
    measureGroupId: number;
    nullable: boolean;
    pdsAttributeType: string;
    pdsAttributeTypeId: number;
    personalIdentifiableInformationId: number;
    primaryKey: boolean;
    regionalVariationDetails: string;
    sortOrder: number;
    smenotesToa: string;
    targetObjectId: number;
    templateToaid: number;
    tobfilePartition: string;
    loadDate: string;
    refreshDate: string;
    nullCheck: boolean;
    isEditable: boolean;
}
