
    import { Component, Provide, Vue } from 'vue-property-decorator'
    import { ComponentBase } from '@/models/ComponentBase'
    import PlatformsForm from '@/components/PlatformsForm';
    import { FormStateType } from '@/models/FormStateType';

    @Component({
        components: {
            PlatformsForm
        }
    })
    export default class PlatformsCreate extends ComponentBase {

        private breadcrumbItems = [
            {
                text: 'Back to Platforms',
                disabled: false,
                href: '/admin/platforms',
            },
            {
                text: 'Create',
                disabled: true
            }
        ];

        private formState: FormStateType = FormStateType.Create;

        constructor() {
            super();
        }

    }
