
    import { Component, Provide, Vue } from 'vue-property-decorator'
    import DashboardCard from '@/components/DashboardCard'
    import TargetObject from '@/components/TargetObject'
    import TargetObjectAttribute from '@/components/TargetObjectAttribute'
    import TargetCalculation from '@/components/TargetCalculation'
    import TargetSourceAttribute from '@/components/TargetSourceAttribute'
    import SourceObject from '@/components/SourceObject'
    import SourceObjectAttribute from '@/components/SourceObjectAttribute'
   // import DQCalcCheck from '@/components/DQCalcCheck'
    import SubjectAreasIndex from '@/components/SubjectAreasIndex'
    import { ComponentBase } from '@/models/ComponentBase'
    import { ObjectDataListEditorBase } from '@/models/ObjectDataListEditorBase'

    @Component({
        components: {
            DashboardCard,
            ObjectDataListEditorBase,
            TargetObject,
            TargetObjectAttribute,
            TargetCalculation,
            TargetSourceAttribute,
            SourceObject,
            SourceObjectAttribute,
          //  DQCalcCheck,
            SubjectAreasIndex

        }
    })
    export default class ObjectDefinitions extends ComponentBase {
        constructor() {
            super();
        }

    }
