
    import { Component, Provide, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
    import store from '@/store'
    import { ComponentBase } from '@/models/ComponentBase';
    import { SubjectArea } from '@/models/SubjectArea';
    import { FormStateType } from '@/models/FormStateType';
    import { IGetItem } from '@/interfaces/IGetItem';

    @Component
    export default class SubjectAreaForm extends ComponentBase implements IGetItem {

        @Prop()
        public subjectAreaId: number;
        @Prop()
        public formState: FormStateType;
        public numberList: Array<number> = [];

        // The editedItem object is initialised here so that vue-property-decorator makes it an observable @data property
        // any reassigning of whole object needs to be done like this: Object.assign({}, x); so that the observable object is not replaced
        public editedItem: SubjectArea = new SubjectArea();

        //public lookupData: ProductLookupData = new ProductLookupData();
        protected apiUrlBase = "/api/subjectareas/";
        protected overlay = false;
        protected isBatchLocked = false;
        private editItemIsValid = true;
        private deliveryDateExpectedMenu = false;
        private deliveryDateCommittedMenu = false;
        private isNameExisting = false;

        public async getItem(id: number) {
            if (this.formState == FormStateType.Edit) {
                this.showOverlay();
                await this.$axios.get<SubjectArea>(this.apiUrlBase + id)
                    .then(response => {
                        this.$nextTick(() => {
                            this.editedItem = Object.assign({}, response.data);
                            this.setSubjectAreaName(this.editedItem.subjectAreaName);
                        });
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get item");
                    }).finally(() => {
                        this.hideOverlay();
                    });;
            }

        }       


        private async create() {
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.getNameExists().then(() => {
                            if (!this.isNameExisting) {
                                this.$axios.post(this.apiUrlBase, this.editedItem)
                                    .then(response => {
                                        this.$router.push({ path: '/subjectAreas/' }).then(() => {
                                            this.showResponseFeedback(response);
                                        });
                                    })
                                    .catch(err => {
                                        this.showErrorMessage(err);

                                    }).finally(() => {
                                        this.hideOverlay();
                                    });
                            } else {
                                this.hideOverlay();
                            }
                        });
                    }
                }
            });

        }

        private async save() {
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.getNameExists().then(() => {
                            if (!this.isNameExisting) {
                                this.$axios.post(this.apiUrlBase , this.editedItem)
                                    .then(response => {
                                        this.showResponseFeedback(response);
                                    })
                                    .catch(err => {
                                        this.showErrorMessage(err);
                                    }).finally(() => {
                                        this.hideOverlay();
                                    });
                            } else {
                                this.hideOverlay();
                            }
                        });
                    }
                }
            });

        }

        private async getNameExists() {
            await this.$axios.get<boolean>(this.apiUrlBase + "name-exists", {
                params: { subjectAreaId: this.subjectAreaId, subjectAreaName: this.editedItem.subjectAreaName }
            })
                .then(response => {
                    this.isNameExisting = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get existing name status");
                });
        }

        protected showOverlay() {
            this.overlay = true;
        }

        protected hideOverlay() {
            this.overlay = false;
        }

        // used to set the product name in the parent component
        private setSubjectAreaName(value: string) {
            return this.$emit("setSubjectAreaName", value);
        }

        get isCreateState() {
            return this.formState == FormStateType.Create;
        }

        get isEditState() {
            return this.formState == FormStateType.Edit;
        }

        created() {            
            this.showOverlay();
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                this.getItem(this.subjectAreaId)
                    .finally(() => {
                        this.hideOverlay();
                    });
            });
        }

        constructor() {
            super();
        }



    }
