import { GetterTree } from 'vuex'
import { NotificationState } from './types'
import { RootState } from '../types'

export const getters: GetterTree<NotificationState, RootState> = {
    snackBarIcon(state):string {
        return state.snackBarIcon;
    },
    snackBarColour(state): string {
        return state.snackBarColour;
    },
    snackBarMessage(state): string {
        return state.snackBarMessage;
    },
    snackBarTitle(state): string {
        return state.snackBarTitle;
    },
    snackBarTimeout(state): number {
        return state.snackBarTimeout;
    },
    showSnackBar(state): boolean {
        return state.showSnackBar;
    }
}
