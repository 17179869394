<template >   
  <div  ref="container" :style="{overflowY: 'scroll',overflowX: 'scroll',height: windowHeight}">
    <TitleComponentForER
            @zoom-in-zoom-out="zoomInZoomOutOnClick"
            @download-image-as-pdf="downloadImageAsPdf"
            @download-as-png="downloadImageAsPng"
            :showButtons="allPromisesDone"
            :breadcrumbItems="breadcrumbItems"
        />
       

 
        <div v-if="!allPromisesDone" class="mt-9">
            <SkeletonLoaderTable />
        </div>
        <div v-if="allPromisesDone">
            <v-container fill-height  v-if="getMetadata.length <= 0 && floatingTablesData?.length<=0">
             <NoData/>
            </v-container>
        </div>
        <v-stage :config="{height:150,width:500}" ref="titleRef" v-if="allPromisesDone">
          <v-layer>
            <v-text
                              v-if="getMetadata.length>0"
                            :config="{
                                text: getMetadata[0]?.productName ,
                                x: 10,
                                y: 10,
                                padding: 10,
                                fill: 'blue',
                                fontSize:21
                            }"
                        />
                        <v-text v-if="getMetadata.length>0" :config="{text: `LAST UPDATED: ${getLatestUpdatedDate()} - GMT`, x: 10,
                                y: 45,
                                padding: 10,
                                fill: 'black',
                                fontSize:15}"/>
                            <v-rect  v-if="getMetadata.length>0 || floatingTablesData.length>0" :config="buttonConfig"  
                            @mouseenter="showPointerEnter"
                            @mouseleave="showPointerLeave"
                            @click="showHideFloatingTables"/>
                              <v-text v-if="getMetadata.length>0 || floatingTablesData.length>0" :config="{x:40,y:93,text:`FLOATING OBJECTS: ${floatingTablesData.length}`, fontSize:14,fontFamily:'Roboto, sans-serif'}"   @mouseenter="showPointerEnter"
                            @mouseleave="showPointerLeave"
                            @click="showHideFloatingTables"/>

          </v-layer>
        </v-stage>
        <div ref="containertwo" v-if="showFloating">
    <div :style="{ display: 'flex', flexDirection: 'row' }">
    <div >
        <v-stage ref="stageRefForFloating" :config="configForStageOfFloating"  >
    <v-layer>
      <v-group>
  
                    <div v-for="(item,floatingIndex) in floatingTablesData" :key="'floatingTablesUniqueKey'+floatingIndex">
                          <FactOrDimensionTable
                                  :title-text="item.targetObjectName"
                                  :isItAFloating="true"
                                  :data="item"
                                  :position="getPosition({ type: item.targetObjectTypeName, item: item.targetObjectName+item.targetObjectId, x: 50,isItFloating:true })"
                                  :type="item.targetObjectTypeName"
                                  :key="'floating' + floatingIndex * 1"
                                  
                              />
                        </div> 
         
  </v-group>
    </v-layer>

  </v-stage>
</div>
      <div>
      <v-stage ref="stageRef" :config="configKonva">
            <!-- /arrows here/  -->
                      <v-layer v-if="hasBeenMounted">              
                          <v-group v-if="arrowsShouldAppear">
                              <div >
                                  <div v-for="(item, indexForMetadata) in getMetadata" :key="indexForMetadata">
                                      <div :key="'arrowdiv' + indexForMetadata">
                                          <v-arrow
                                              :ref="'arrow' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName"
                                              :config="uniqueHashMapForArrows[item.primaryObjectName + item.foreignObjectName + item.colorCode]"
                                              :key="'arrowKey' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName"
                                              @click="
                                                  ($event) =>
                                                      showHighlightedEnter(
                                                          $event,
                                                          item,
                                                          '#0533cc',
                                                          '#0533cc',
                                                          item.colorCode
                                                      )
                                              "
                                          />
                                          <v-circle :key="'circle'+indexForMetadata" :config="getPositionForCircle(item)"/>
                                      </div>
 
                                  </div>
                                  
                              </div>
                              </v-group>
                              <v-group >
                          <!-- / only for highlights / -->
                              <div  v-if="arrowsOfHighlightToAppear">
                                  <div
                                   
                                      v-for="(item, indexForArrows) in highlightArrowsItems"
                                      :key="indexForArrows"
                                  >
                                      <div :key="'arrowdiv' + indexForArrows">
                                          <v-arrow
                                              :ref="'arrow' + indexForArrows"
                                              :config="item"
                                              :key="'arrowKey' + indexForArrows"
                                              @click="
                                                  ($event) =>
                                                      showHighlightedEnter(
                                                          $event,
                                                          item,
                                                          '#0533cc',
                                                          '#0533cc',
                                                          item.colorCode
                                                      )" 
                                          />
                                      </div>
                                  </div>
                              </div>
                          </v-group>
                      </v-layer>
                   <!-- /tables here/  -->
                      <v-layer v-if="hasBeenMounted ">
                        <div>
                        
                        </div>
                          <v-group>
                         
                            <div  :style="{overflowY:'scroll',overflowX:'scroll'}">
                              <div
                                  v-for="(item, index) in Object.keys(
                                      getFullDataList['dimensionTableGroups']
                                  )"
                                  :key="'dim' + index"
                              >
                             
                                  <FactOrDimensionTable
                                      :isItAFloating="false"
                                      :title-text="item"
                                      :data="getFullDataList['dimensionTableGroups'][item]"
                                      :position="getPosition({ type: 'dimension', item: item, x: 50 })"
                                      type="dimension"
                                      :key="'dim' + index * 1"
                                      
                                  />
                              </div>
                              <div v-if="showHighlight">
                                  <div v-for="(positionItem, positionItemIndex) in positionOfHighlightElements" :key="positionItemIndex">
                                      <v-rect
                                      :key="positionItem?.xPositionOfSource + positionItemIndex"
                                          :config="{
                                              x: positionItem?.xPositionOfSource,
                                              y: positionItem?.yPositionOfSource,
                                              width: positionItem?.width,
                                              height: 30,
                                              stroke: 'black',
                                              fill: black,
          
                                              padding: 3,
                                              zIndex: 90
                                          }"
                                      />
                                      
                                  </div>    
                              </div>
                              <div
                                  v-for="(factItem, factIndex) in Object.keys(
                                      getFullDataList['factTableGroups']
                                  )"
                                  :key="'fact' + factIndex"
                              >
                             
                             

                                  <FactOrDimensionTable
                                       :isItAFloating="false"
                                      :title-text="factItem"
                                      :data="getFullDataList['factTableGroups'][factItem]"
                                      :position="getPosition({ type: 'fact', item: factItem, x: 50 })"
                                      type="fact"
                                      :key="'fact' + factIndex * 1"
                                      @show-fact-relations="showFactRelations"
                                  />
                              </div>
                            </div>
                            </v-group>
                      </v-layer>
                    </v-stage>
                    
      </div>
    </div>
  </div>
  <div v-else>

    <!-- <StageMain/> -->
    <v-stage ref="stageRef" :config="configKonva">
 <!-- /arrows here/  -->
          <v-layer v-if="hasBeenMounted">
            <v-group>
             <!-- / this is here to make sure the downloaded image gets the white background / -->
              <v-rect
           :style="{ zIndex: 0 }"
           :config="{
                    x: 0,
               y:0,
               width: configKonva.width,
              height:1500,
               stroke: '#fff',
               fill: '#fff'
           }"
       />
            </v-group>
              <v-group v-if="!showFloating && arrowsShouldAppear">
                  <div >
                      <div
                       
                          v-for="(item, indexForMetadata) in getMetadata"
                          :key="indexForMetadata"
                      >
                          <div :key="'arrowdiv' + indexForMetadata">
                              <v-arrow
                                  :ref="'arrow' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName"
                                  :config="uniqueHashMapForArrows[item.primaryObjectName + item.foreignObjectName + item.colorCode]"
                                  :key="'arrowKey' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName"
                                  @click="
                                      ($event) =>
                                          showHighlightedEnter(
                                              $event,
                                              item,
                                              '#0533cc',
                                              '#0533cc',
                                              item.colorCode
                                          ) "/>
                              <v-circle :key="'circle'+indexForMetadata" :config="getPositionForCircle(item)"/>
                          </div>
                         
                  
            
                      </div>
                  </div>
                  </v-group>
                  <v-group >
                <!-- / only for highlights / -->
                  <div  v-if="arrowsOfHighlightToAppear">
                      <div
                       
                          v-for="(item, indexForArrows) in highlightArrowsItems"
                          :key="indexForArrows"
                      >
                          <div :key="'arrowdiv' + indexForArrows">
                              <v-arrow
                                  :ref="'arrow' + indexForArrows"
                                  :config="item"
                                  :key="'arrowKey' + indexForArrows"
                                  @click="
                                      ($event) =>
                                          showHighlightedEnter(
                                              $event,
                                              item,
                                              '#0533cc',
                                              '#0533cc',
                                              item.colorCode
                                          ) "
                              />
                          </div>
                      </div>
                  </div>
              </v-group>
          </v-layer>
      <!-- //tables here/  -->
          <v-layer v-if="hasBeenMounted ">
              <v-group >
                <div  :style="{overflowY:'scroll',overflowX:'scroll'}">
                  <div
                      v-for="(item, index) in Object.keys(
                          getFullDataList['dimensionTableGroups']
                      )"
                      :key="'dim' + index"
                  >
           
                      <FactOrDimensionTable
                          :isItAFloating="false"
                          :title-text="item"
                          :data="getFullDataList['dimensionTableGroups'][item]"
                          :position="getPosition({ type: 'dimension', item: item, x: 50 })"
                          type="dimension"
                          :key="'dim' + index * 1"/>
                  </div>
                  <div v-if="showHighlight">
                      <div v-for="(positionItem, positionItemIndex) in positionOfHighlightElements" :key="positionItemIndex">
                          <v-rect
                          :key="positionItem?.xPositionOfSource + positionItemIndex"
                              :config="{
                                  x: positionItem?.xPositionOfSource,
                                  y: positionItem?.yPositionOfSource,
                                  width: positionItem?.width,
                                  height: 30,
                                  stroke: 'black',
                                  fill: black,

                                  padding: 3,
                                  zIndex: 90
                              }"
                          />
                           
                      </div>    
                  </div>
                  <div
                      v-for="(factItem, factIndex) in Object.keys(
                          getFullDataList['factTableGroups']
                      )"
                      :key="'fact' + factIndex"
                  >
                  <div :style="{zIndex:81}">
                    <v-circle :config="uniqueHashMapForCircles[getFullDataList['factTableGroups'][factItem].primaryObjectName + getFullDataList['factTableGroups'][factItem].foreignObjectName + getFullDataList['factTableGroups'][factItem].colorCode]"/>
                  </div>
                 
                      <FactOrDimensionTable
                           :isItAFloating="false"
                          :title-text="factItem"
                          :data="getFullDataList['factTableGroups'][factItem]"
                          :position="getPosition({ type: 'fact', item: factItem, x: 50 })"
                          type="fact"
                          :key="'fact' + factIndex * 1"
                          @show-fact-relations="showFactRelations"
                      />
                  </div>
                </div>
                </v-group>
              
       
          </v-layer>
   
      </v-stage>

</div>
<div v-if="allPromisesDone">
        <DiagramCommentsBox :productId="productId" :url="url"/>  
</div>
    </div>
</template>

<script>
import store from "@/store";
import { removeDuplicatesByKey, downloadURI,getHeighAndWidthOfWindow,attachWindowResizer,getUpdatedDate,ZoomInZoomOut } from "@/helpers/DMVHelpers";
import FactOrDimensionTable from "./FactOrDimensionTable.vue";
import SkeletonLoaderTable from "./SkeletonLoaderTable.vue";
import NoData from './NoData.vue'
import DiagramCommentsBox from "./DiagramCommentsBox.vue";
import TitleComponentForER from "@/components/TitleComponentForER.vue";
import {jsPDF} from 'jspdf';
export default {
    data() {
        return {
            overflow: {
                overflow: "auto"
            },
            yForAttributeNew: {},
            arrowsOfHighlightToAppear:false,
            highlightArrowsItems:[],
            data: [],
            prevEvent: null,
            prevItem: null,
            url:'',
            prevColor: null,
            hashTableForCalculatingY: {},
            hashTableForCalculatingYInNodes: {},
            factHashes: {},
            factHashesForFloating: {},
            dimensionHashesForFloating: {},
            initialKeyForArrows: "arrow1",
            height: 0,
            scaleBy : 1.21,
            width: 1200,
            showFloating: false,
            hasBeenMounted: false,
            allPromisesDone: false,
            dimensionHashes: {},
            positionOfHighlightElements: [],
            isHighlightVisible: false,
            highlightedExists: true,
            showHighlightTargetConfig: {},
            showHighlightSourceConfig: {},
            showHighlight: true,
            highlightedConfig: {},
            floatingTablesData:{},
            breadcrumbItems : [
            {
                text: 'Back to products',
                disabled: false,
                href: '/products',
            },
            {
                text: 'Star Schema Diagram',
                disabled: true
            }
        ],
            configForCurrentArrows: {},
            yExistsForAttribute: {},
            xPositionOfSource: 0,
            yPositionOfSource: 0,
            targetPositionItemsPosition: {},
            nodePositionForArrows: {},
            positions: {},
            arrows: [],
            productId:0,
            FloatingTables:[],
            arrowsShouldAppear:false,
            updatedArrowsOne: false,
            windowHeight:'500px',
            uniqueHashMapForArrows: {},
            uniqueHashMapForCircles:{},
            arrowsExisting: {},
            configForStageOfFloating:{
              width:450,
              height:400
            },
            buttonConfig:{
              height:40,
              width:200,
              cornerRadius:6,
              fill:'#f5f5f5',
              x:20,
              y:80,
              shadowBlur:5,shadowColor:'grey',shadowOpacity:0.5,
               shadowOffset: { x: 0, y: 4 },
            },
            zoomedIn:false,
            configKonva: {
                width: 1600,
                height: 1500,
                offsetX:0,
                offsetY:0
            }
        };
    },

    computed: {
        checkIfPositionOfTargetExists() {
            return (x, y) => {
                if (Object.prototype.hasOwnProperty.call(this.yExistsForAttribute, (x+y))) {
                    return this.checkIfPositionOfTargetExists(x, y + 10);
                }
                this.yExistsForAttribute[x + y] = 0;
                return { x, y };
            };
        },

        getYForArrow() {
            return (y = this.height / 3.5) => {
                if (!this.hashTableForCalculatingY[y]) {
                    this.hashTableForCalculatingY[y] = y;
                    return y;
                }
                return this.getYForArrow(y + 3);
            };
        },

        getFinalY() {
            return (item) => {
                let finalY = { x: 0, y: 0 };
                let dt = this.getMetadata.filter(
                    (newitem) => newitem.primaryObjectName === item.primaryObjectName
                );
                for (let i in dt) {
                    let pos =
                        this.getPositionsOfNodes[
                            dt[i].primaryAttributeName + dt[i].primaryObjectName
                        ];

                    if (pos?.y > finalY.y) {
                        finalY = { y: pos?.y, x: pos?.x };
                    }
                }

                if (finalY.y > this.height + 200) {
                    finalY = this.getPositionsOfNodes[item.primaryObjectName];
                }
                return finalY;
            };
        },
        MetaItems() {
            return removeDuplicatesByKey(this.getMetadata, "primaryAttributeName");
        },

        getData() {
            return this.$store.getters["schema/getData"];
        },
        getFullDataList() {
            return this.$store.getters["schema/getDimensions"];
        },
        getMetadata() {
            return this.$store.getters["schema/getMetaData"];
        },
        getToaData() {
            return this.$store.getters["schema/getToaData"];
        },
        getPositionsOfNodes() {
            return this.$store.getters["schema/getPositionsOfChildNodes"];
        }
    },
    created:function () {
        this.productId=this.$route.params.productId;
        this.url =`/api/products/${this.productId}/star-schema-diagramNotes`
         window.scrollTo(0,0);
  
        attachWindowResizer();
        const {height}=getHeighAndWidthOfWindow();
        this.windowHeight=`${height-180}px`;
        this.height = height;
        if(height>1500){
            this.height=800;
           
        }
    },
   
    mounted: function () {
        window.addEventListener('resize', this.getDimensions);
        const {height}=getHeighAndWidthOfWindow();
        this.height = height;
        this.windowHeight=`${height-180}px`;
        if(height>1500){
            this.height=800
            
        }
        this.getInitialProductData();
     
        this.initialKeyForArrows = "arrow1";
        const container = this.$refs.container;
        const stage = this.$refs.stageRef.getStage();
        stage.container(container);
        // this.setZoomListener(stage);
        this.$store.dispatch("schema/setChildNodePositions");
        this.positionOfHighlightElements = [];
        this.yExistsForAttribute = {};

    },
    destroyed() {
        this.yExistsForAttribute = {};
        this.factHashes = {};
        this.hasBeenMounted = false;
        this.arrowsShouldAppear=false;
        this.dimensionHashes = {};
    },
    watch: {
        //watching the state of child node positions to change and only then create the y position of the arrows.
        //doing it otherwise causes fluctuations on the lines every time a rerender happens
        "$store.state.schema.childNodePositions": function () {
                Object.keys(this.getPositionsOfNodes).map((item, index) => {
                const y = this.getYForArrow();
                this.hashTableForCalculatingYInNodes[item] = y;
            });
            this.getMetadata.map((item, index) => {
                const posOfTarget = this.getFinalY(item);
                if (this.yForAttributeNew[item.primaryObjectName]) {
                    this.yForAttributeNew[item.primaryObjectName].x += 10;
                } else {
                    this.yForAttributeNew[item.primaryObjectName] = {
                        x: posOfTarget.x,
                        hasBeenReninstantiated: false
                    };
                }

                this.yForAttributeNew[item.primaryAttributeName + item.foreignAttributeName] =
                    this.yForAttributeNew[item.primaryObjectName].x;
            });

            this.getMetadata.map(item=>this.getConfigForArrows(item));
            this.arrowsShouldAppear=true

             this.hasBeenMounted=true;
        }  ,
        "$store.state.schema.metaData": function () {
              const dimensionItems = this.getFullDataList['dimensionTableGroups'];
             
                const factItems = this.getFullDataList['factTableGroups'];
                if(Object.keys(factItems).length<Object.keys(dimensionItems).length ) {
                  if(Object.keys(dimensionItems).length> 3){
                    for(let i=3 ;i<=Object.keys(dimensionItems).length;i++){
                      this.configKonva.width+=550;
                    }
                  }
                }else{
                  if(Object.keys(factItems).length> 3){
                for(let i=6 ;i<=Object.keys(factItems).length;i++){
                this.configKonva.width+=550;
              }
              }
              }
              } ,
        "floatingTablesData": function () {
                this.floatingTablesData.map((_,index)=>{
                  if(index>13){
                    this.configForStageOfFloating.height+=100;
                  }
                })
              }  
    },

    methods: {
        getDimensions(){
            const {height}=getHeighAndWidthOfWindow();
             this.windowHeight=`${height-180}px`;        
        },
      showPointerEnter(){
        this.buttonConfig={...this.buttonConfig,fill:'#E0E0E0'}
      },
      showPointerLeave(){
        this.buttonConfig={...this.buttonConfig,fill:'#f5f5f5'}
      },
      getPositionForCircle(data) {
       return this.uniqueHashMapForCircles[data.primaryObjectName + data.foreignObjectName + data.colorCode]
      },  
      getLatestUpdatedDate() {
        return getUpdatedDate(this.getMetadata)
      },
      showFactRelations(event,value) {   
      //  check if this table already exists 
        if(this.highlightArrowsItems.length>0& this.highlightArrowsItems[0]?.foreignObjectName===event[0]?.foreignObjectName){
          this.highlightArrowsItems=[];
          this.arrowsOfHighlightToAppear=false;
        }else{
          this.highlightArrowsItems=[];
          if(event){
            event.map(item=>{
              const value = item.primaryObjectName +
                      item.foreignObjectName +
                      item.colorCode; 
              const uniqueItem = {...item,...this.uniqueHashMapForArrows[value]};
              uniqueItem.strokeWidth=6;
              uniqueItem.strokeColor='blue'
              uniqueItem.stroke='blue'
              this.highlightArrowsItems=[...this.highlightArrowsItems, uniqueItem];
            })
            this.arrowsOfHighlightToAppear=true;
          }
        }
      },
      getConfigForParticularArrowsFromHash(item) {
        const value =    item.primaryObjectName +
                    item.foreignObjectName +
                    item.colorCode;
        return this.uniqueHashMapForArrows[value];
      },
    
        showHideFloatingTables() {
          this.showFloating=!this.showFloating;
        },
        removeDuplicatesForTables(data, type) {
            let nonDuplicateItems;
            if (type == "dimension") {
                let repeatingKeysForOneFactTable = removeDuplicatesByKey(
                    data,
                    "foreignAttributeID"
                );
                repeatingKeysForOneFactTable = removeDuplicatesByKey(
                    repeatingKeysForOneFactTable,
                    "primaryAttributeName"
                );

                nonDuplicateItems = repeatingKeysForOneFactTable;
            } else {
                nonDuplicateItems = removeDuplicatesByKey(data, "foreignAttributeName");
            }
            return nonDuplicateItems;
        },

        getConfigForArrows(item) {
            
            const factTableData = this.getFullDataList["factTableGroups"][
                item.foreignObjectName
            ].filter((i) => i.primaryObjectID === item.primaryObjectID);
            const dimensionTableData = this.getFullDataList["dimensionTableGroups"][
                item.primaryObjectName
            ].filter((i) => i.foreignObjectID === item.foreignObjectID);
            const removedDuplicatedFact = this.removeDuplicatesForTables(factTableData, "fact");
            const removedDuplicatedDimension = this.removeDuplicatesForTables(
                dimensionTableData,
                "dimension"
            );
            if (removedDuplicatedFact.length === removedDuplicatedDimension.length) {
                const value = item.primaryObjectName + item.foreignObjectName + item.colorCode;
                if (!(value in this.uniqueHashMapForArrows)) {
                  const arrowConfig=this.getCurrentArrow(item)
                    this.uniqueHashMapForArrows[value] = arrowConfig;
                    this.uniqueHashMapForCircles[value]={
                      x:arrowConfig?.points[0]-6,
                      y:arrowConfig?.points[1],
                      radius:6.5,
                      fill: arrowConfig?.fill,
                      stroke:'#fff',
                      zIndex:81
                    }
                } else {
                  this.uniqueHashMapForArrows[value];
                }
            } else {
                const value =
                    item.primaryObjectName +
                    item.foreignObjectName +
                    item.colorCode +
                    item.primaryAttributeName;

                this.uniqueHashMapForArrows[value] = this.getCurrentArrow(item);
                this.uniqueHashMapForCircles[value]={
                      x:this.getCurrentArrow(item)?.points[0],
                      y:this.getCurrentArrow(item)?.points[1],
                      radius:6.5,
                      stroke:'#fff',
                      fill: this.getCurrentArrow(item)?.fill,
                      zIndex:81
                    }
              
            }
        },
       // remove this is we switched to pdf fully
        async downloadImageAsPng() {
            //stage
            const st1 = await this.$refs.stageRef.getStage();
            //title
            const st2 = await this.$refs.titleRef.getStage();
            //floating items

            const canvas1 =  st1.toDataURL({pixelRatio:2});
            const canvas2 =  st2.toDataURL({pixelRatio:2});
            
            const {width:konvaWidth,height:konvaHeight} = this.configKonva;
             // main API:
            var imageObjOne = new Image();
            var imageObjTwo = new Image();
            var imageObjThree = new Image();
            imageObjOne.src = canvas1;
            imageObjTwo.src = canvas2;
            let st3 = this.$refs.stageRefForFloating?.getStage();
            let canvas3 = st3?.toDataURL({pixelRatio:2});
            if(this.showFloating){
            imageObjThree.src=canvas3; 
            }
        
            // Create a Promise to wait for images to load
            const loadImages = new Promise((resolve) => {
            let count = 0;
            const checkCount = () => {
                count++;
                if (count === (this.showFloating?3:2)) {
                resolve();
                }
            };

            imageObjOne.onload = checkCount;
            imageObjTwo.onload = checkCount;
            if(this.showFloating){
            imageObjThree.onload = checkCount;
            }
          
            });

            // Wait for all images to load
            await loadImages;

            var hiddenCanvas=document.createElement('canvas');
            let hiddenCtx=hiddenCanvas.getContext('2d');
            //set the dimensions of the canvas according to stage height
            //if stages are different height you'll have to calculate how to handle this
            hiddenCanvas.width=konvaWidth*2;
            hiddenCanvas.height=konvaHeight*2;
            hiddenCtx.fillStyle = '#FFFFFF'; // Set the background color to white
            hiddenCtx.fillRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
            //now draw the images on the hidded canvas
           try{ 
            const height = this.floatingTablesData?.length<10?this.configKonva.height*4: this.configForStageOfFloating.height*2;
            
            if(this.showFloating){
                hiddenCanvas.height=height;
                hiddenCtx.fillStyle = '#FFFFFF'
                hiddenCtx.fillRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
                hiddenCtx.drawImage(imageObjTwo,100,40);
                hiddenCtx.drawImage(imageObjThree,0,500);
                hiddenCtx.drawImage(imageObjOne,900,500);
            }else{
                hiddenCtx.drawImage(imageObjTwo,100,40);
                hiddenCtx.drawImage(imageObjOne,0,200);
            }   
        }catch(e){
            e.printStackTrace()
        }
      const finalImage =  hiddenCanvas.toDataURL({pixelRatio:2})
      downloadURI(finalImage, `${this.getMetadata[0].productName}.png`)

        },

        downloadImageAsPdf() {
            if(this.getMetadata && this.getMetadata.length>0){

          
          var currentStage = this.$refs.stageRef.getStage();

          var currentStageForTitle = this.$refs.titleRef.getStage();
          const height = this.floatingTablesData?.length<10?this.configKonva.height+500: this.configForStageOfFloating.height;
          var pdf = new jsPDF('l', 'px', [this.configKonva.width+500, height]);
          pdf.addImage(
            currentStageForTitle.toDataURL({pixelRatio:2}),
            0,0,1500,350
          );
          if(this.showFloating){
            var currentStageForFloating = this.$refs.stageRefForFloating.getStage();
            pdf.addImage(
            currentStageForFloating.toDataURL({ pixelRatio: 2 }),
            0,
            330,
           400,
           this.configForStageOfFloating.height
          );
            pdf.addImage(
              currentStage.toDataURL({ pixelRatio: 2 }),
            350,
            330,
            this.configKonva.width,
            this.configKonva.height
          );
          }else{
            pdf.addImage(
              currentStage.toDataURL({ pixelRatio: 2 }),
            0,
            330,
            this.configKonva.width,
            this.configKonva.height
          );
          }
  
      
      pdf.save(`${this.getMetadata[0].productName}.pdf`);
        }
        },

        getCurrentArrow(item) {
          
            const fact =
                item?.foreignAttributeName + item?.foreignObjectName + item?.foreignAttributeID;
                
            const targetItemPosition = this.getPositionsOfNodes[item?.primaryObjectName];
            const newPos = this.getFinalY(item);
           
            const factItemPosition =
                this.getPositionsOfNodes[
                    item?.foreignAttributeName + item.foreignObjectName + item?.foreignAttributeID
                ];
               

            let points;
            if (factItemPosition && targetItemPosition) {
              const title = item.foreignObjectName;
                //const targetNewPosition= this.checkIfPositionOfTargetExists(targetItemPosition.x,targetItemPosition.y)
                // fact and dimension table are in same y axis
                if (factItemPosition.x === targetItemPosition.x) {
                    if (targetItemPosition.y < this.height + 200) {
                        points = [
                            factItemPosition?.x,
                            factItemPosition?.y,
                            factItemPosition.x - 45,
                            factItemPosition.y,
                            factItemPosition.x - 45,
                            newPos.y + 20,
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] - 20,
                            newPos.y + 20
                        ];
                    } else {
                        points = [
                            factItemPosition?.x,
                            factItemPosition?.y,
                            targetItemPosition.x,
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ]
                        ];
                    }
                } else if (factItemPosition.x > targetItemPosition.x) {
                    if (targetItemPosition.y < this.height + 200) {
                        points = [
                            factItemPosition?.x,
                            factItemPosition?.y + 20,
                            factItemPosition.x - 30,
                            factItemPosition.y + 20,
                            factItemPosition.x - 30,

                            this.hashTableForCalculatingYInNodes[fact],
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 20,
                            this.hashTableForCalculatingYInNodes[fact],
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 20,
                            newPos.y + 40
                        ];
                    } else {
                        points = [
                            factItemPosition?.x,
                            factItemPosition?.y,
                            factItemPosition.x - 20,
                            factItemPosition.y,
                            factItemPosition.x - 20,
                            factItemPosition.y + 300,
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 10,
                            factItemPosition.y + 300,
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 10,
                            newPos.y - 20
                        ];
                    }
                } else {
                    if (targetItemPosition.y < this.height + 200) {
                        points = [
                            factItemPosition.x + (title.length > 47 ? title.length*7+30 : 320),
                            factItemPosition.y + 20,
                            factItemPosition.x + (title.length > 47 ? title.length*7+50 : 340),
                            factItemPosition.y + 20,
                            factItemPosition.x + (title.length > 47 ? title.length*7+50 : 340),
                            this.hashTableForCalculatingYInNodes[fact],
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 20,
                            this.hashTableForCalculatingYInNodes[fact],
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 20,
                            newPos.y + 40
                        ];
                    } else {
                        points = [
                            factItemPosition?.x,
                            factItemPosition?.y,
                            factItemPosition.x - 20,
                            factItemPosition.y,
                            factItemPosition.x - 20,
                            newPos.y - 50,
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 10,
                            newPos.y - 50,
                            this.yForAttributeNew[
                                item.primaryAttributeName + item.foreignAttributeName
                            ] + 10,
                            newPos.y - 20
                        ];
                    }
                }
            }
          
            return {
                points: points,
                fill: item.colorCode,
                stroke: item.colorCode,
                strokeWidth: 4,
                pointerWidth:12,
                pointerLength:12
            };
        },
        zoomInZoomOutOnClick(direction) {
            const scaleBy = 1.1;
        // @ts-expect-error  Property 'getStage' does not exist on type 'Element
        const stage = this.$refs?.stageRef?.getStage();

        ZoomInZoomOut({stage,scaleBy,direction,isERD:false});
        },
        showHighlightedLeave(event, item, fillColor, strokeColor) {
            event.target.fill(fillColor);
            event.target.stroke(strokeColor);
            event.target.strokeWidth(4);
            this.showHighlight = false;
            this.showHighlightSourceConfig = {};
            this.showHighlightTargetConfig = {};
            this.isHighlightVisible = false;
            this.positionOfHighlightElements = [];
            
        },
        showHighlightedEnter(event, item, fillColor, strokeColor, leaveColor) {
            const isTheItemSameAsPrevious = item.foreignObjectName+item.foreignAttributeName+item.primaryObjectName+item.primaryAttributeName ===this.prevItem?.foreignObjectName+this.prevItem?.foreignAttributeName+this.prevItem?.primaryObjectName+this.prevItem?.primaryAttributeName;
            if(isTheItemSameAsPrevious){
                this.showHighlightedLeave(
                    this.prevEvent,
                    this.prevItem,
                    this.prevColor,
                    this.prevColor
                ); 
                this.prevItem=null;
                return; 
            }
            if (this.prevEvent !== null && this.prevItem !== null && this.prevColor !== null) {
                this.showHighlightedLeave(
                    this.prevEvent,
                    this.prevItem,
                    this.prevColor,
                    this.prevColor
                );
            }
            const primaryAttribute = item.primaryObjectName;
            const foreignAttribute = item.foreignObjectName;
            const filteredForeignElements = this.getMetadata.filter(
                (i) =>
                    i.primaryObjectName === primaryAttribute &&
                    i.foreignObjectName === foreignAttribute
            );
            //get the dimension items
            const factTableData = this.getFullDataList["factTableGroups"][
                item.foreignObjectName
            ].filter((i) => i.primaryObjectID === item.primaryObjectID);
            const dimensionTableData = this.getFullDataList["dimensionTableGroups"][
                item.primaryObjectName
            ].filter((i) => i.foreignObjectID === item.foreignObjectID);
            const removedDuplicatedFact = this.removeDuplicatesForTables(factTableData, "fact");
            const removedDuplicatedDimension = this.removeDuplicatesForTables(
                dimensionTableData,
                "dimension"
            );
            let targetItemPosition;
            let factItemPosition;

            //this is used by the circle at the destination to show the starting point
            const destinationPosition= this.getPositionsOfNodes[
                        filteredForeignElements[0]?.foreignAttributeName + filteredForeignElements[0].foreignObjectName + filteredForeignElements[0]?.foreignAttributeID
                    ];
                    if(removedDuplicatedDimension.length === removedDuplicatedFact.length){

                      for (let i of filteredForeignElements) {
                          targetItemPosition =
                              this.getPositionsOfNodes[i?.primaryAttributeName + i?.primaryObjectName];
                          factItemPosition =
                              this.getPositionsOfNodes[
                                  i?.foreignAttributeName + i.foreignObjectName + i?.foreignAttributeID
                              ];
                          this.positionOfHighlightElements = [
                              ...this.positionOfHighlightElements,
                              {
                                  xPositionOfSource: targetItemPosition.x,
                                  yPositionOfSource: targetItemPosition.y,
                                  xPositionOfDestination:targetItemPosition.x>factItemPosition.x&&targetItemPosition.y<factItemPosition.y?destinationPosition.x+300:destinationPosition.x,
                                  yPositionOfDestination:targetItemPosition.y<factItemPosition.y? targetItemPosition.x>factItemPosition.x&&targetItemPosition.y<factItemPosition.y?destinationPosition.y+20:targetItemPosition.x<factItemPosition.x?destinationPosition.y+20:destinationPosition.y:destinationPosition.y,
                                  width:  item.primaryObjectName.length > 47 ?  item.primaryObjectName.length*7 : 300
                              },
                              {
                                  xPositionOfSource: factItemPosition.x,
                                  yPositionOfSource: factItemPosition.y,
                                  width: item.foreignObjectName.length > 50 ? item.foreignObjectName.length*7 : 300
                              }
                          ];
                      }
                    }
                    else{
                      const filteredForeignElementsNotEqual = this.getMetadata.filter(
                (i) =>
                    i.primaryObjectName === primaryAttribute &&
                    i.foreignObjectName === foreignAttribute &&
                    i.foreignAttributeID === item.foreignAttributeID
            );

            for (let i of filteredForeignElementsNotEqual) {
                          targetItemPosition =
                              this.getPositionsOfNodes[i?.primaryAttributeName + i?.primaryObjectName];
                          factItemPosition =
                              this.getPositionsOfNodes[
                                  i?.foreignAttributeName + i.foreignObjectName + i?.foreignAttributeID
                              ];
                          this.positionOfHighlightElements = [
                              ...this.positionOfHighlightElements,
                              {
                                  xPositionOfSource: targetItemPosition.x,
                                  yPositionOfSource: targetItemPosition.y,
                                  xPositionOfDestination:destinationPosition.x,
                                  yPositionOfDestination: destinationPosition.y,
                                  width: item.primaryObjectName.length > 50 ? 400 : 300
                              },
                              {
                                  xPositionOfSource: factItemPosition.x,
                                  yPositionOfSource: factItemPosition.y,
                                  width: item.foreignObjectName.length > 50 ? 400 : 300
                              }
                          ];
                      }        
          
          
          }

            this.isHighlightVisible = true;
            this.xPositionOfSource = targetItemPosition.x;
            this.yPositionOfSource = targetItemPosition.y;
           
            this.showHighlight = true;
            event.target.fill(fillColor);
            event.target.stroke(strokeColor);
            event.target.strokeWidth(5);
            event.target.moveToTop();
            this.prevEvent = event;
            this.prevItem = item;
            this.prevColor = leaveColor;
        },

       
        getPosition({ type, item, x = 250,isItFloating=false }) {
            let y, data, hash,title;
            
            if(this.positions[item]){
              return this.positions[item]
            }
            if(isItFloating){
            
              x=20,y=50,hash=this.factHashesForFloating
              if(hash.length>13){
                this.configForStageOfFloating.height+=190
              }
            while (hash[x + y]) {
              
                    y += 70;
                
            }
            }else{

              switch (type) {
                  case "fact":
                    //this needs to be changed to achieve less space between objects
                    //get the item with same x as this here 
                    //get the last item from the list 
                    // add some pixels and you will get the y;
                        
                      y = this.height / 1.8;
                      // if(x>1200)x=50;
                      hash = this.factHashes;
                      break;
                  case "dimension":
                   
                      y = 20;
                      hash = this.dimensionHashes;
                      break;
                  default:
                      return null;
              }
              while (hash[x + y]) {
                  if (type === "dimension" && x > 2500 && y === 20) {
                      x = 250;
                      y = this.height + 200;
                  } else {
                      x += 480;
                  }
              }
            }


            hash[x + y] = { x: x, y: y };
            data = { x: x, y: y };
            this.positions[item] = data;

            return this.positions[item];
        },
        async getFloatingTablesData(productId) {
        try {
            await this.$axios
                .get(`/api/products/${productId}/star-schema-metadataNoRelation`)
                .then((response) => {
                    this.floatingTablesData = response.data
                });
        } catch (error) {
            console.log(error);
        }
    },

        // get the initial data for metadata as well as for the floating tables
        async getInitialProductData() {
            const productId = this.$route.params.productId
            const url = `/api/products/${productId}/star-schema-metadata`
            await Promise.all([
                store.dispatch("schema/setMainMetaData", url),
                this.getFloatingTablesData(productId),
            ]).then(() => {
                this.allPromisesDone = true;
            });

            this.data = store.dispatch["schema/getData"];
        }
    },
    components: { FactOrDimensionTable, SkeletonLoaderTable,NoData,DiagramCommentsBox,TitleComponentForER }
};
</script>
<style scoped>
ul.v-breadcrumbs.breadcrumbs{
  padding:0 1rem;
}
</style>