
    import { Vue, Component } from 'vue-property-decorator'
    import { TargetObjectAttribute } from '@/models/TargetObjectAttribute';
    import { DataObjectList } from '@/models/DataObjectList';
    import { TargetObjectAttributeListItem } from '@/models/TargetObjectAttributeListItem';
    import { TargetObjectAttributeLookupData } from '@/models/TargetObjectAttributeLookupData';
    import { TargetObjectAttributeFilterData } from '@/models/TargetObjectAttributeFilterData';
    import FadeTooltip from '@/components/FadeTooltip'
    import { ObjectDataListEditorBase } from '@/models/ObjectDataListEditorBase';
    import { TargetObjectAttributeFilterOptions } from '@/models/TargetObjectAttributeFilterOptions';

    @Component({
        components: {
            FadeTooltip
        }
    })
    export default class TargetObjectAttributes extends ObjectDataListEditorBase<TargetObjectAttribute, TargetObjectAttributeListItem, DataObjectList<TargetObjectAttributeListItem>, TargetObjectAttributeLookupData, TargetObjectAttributeFilterData, TargetObjectAttributeFilterOptions>  {

        private headers: Array<any>;

        // editedItem is being overridden and made observable because the inherited class cannot initialise it using the generic type
        // this needs to be done on the property for it to be added to Vue @data and made observable, doesn't work in created()
        protected editedItem: TargetObjectAttribute = Vue.observable(new TargetObjectAttribute());

        created() {
            this.filteredLookupData = new TargetObjectAttributeFilterData();
            this.lookupData = new TargetObjectAttributeLookupData();
            this.filterOptions = new TargetObjectAttributeFilterOptions();
            this.editedItem = new TargetObjectAttribute();
            this.apiUrlBase = "/api/targetobjectattributes/";
            this.options.sortBy = ["loadDateText", "targetObjectName", "targetObjectAttributeName"];
            this.options.sortDesc = [true, false, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Attribute Name", value: "targetObjectAttributeName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Target Object", value: "targetObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Data Owner", value: "dataOwnerName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];

        }

        constructor() {
            super();
        }
    };
