import store from "@/store";
import { Component, Watch } from "vue-property-decorator";
import { ComponentBase } from "@/models/ComponentBase";
import { DataOptions } from "vuetify/types";
import { IDataObjectList } from "@/interfaces/IDataObjectList";
import { IDataObjectListItem } from "@/interfaces/IDataObjectListItem";
import { IGetItemsFiltered } from "@/interfaces/IGetItemsFiltered";

@Component
export class ObjectDataListBase<
        TListItem extends IDataObjectListItem,
        TList extends IDataObjectList<TListItem>,
        TFilteredLookupData,
        TFilterOptions
    >
    extends ComponentBase
    implements IGetItemsFiltered
{
    protected headerClass = "text-uppercase text-subtitle-1 primary--text font-weight-bold";
    protected cellClass = "text-subtitle-1 primary--text";
    protected totalItems = 0;
    protected items: Array<TListItem> = [];
    protected filteredLookupData: TFilteredLookupData;
    protected filterOptions: TFilterOptions;
    protected apiUrlBase = "";
    protected loading = true;
    protected overlay = false;
    protected isBatchLocked = false;

    protected timerId: number;

    protected options: DataOptions = {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [false],
        multiSort: true,
        mustSort: false
    };

    protected async getItems() {
        try {
            this.loading = true;

            await this.$axios
                .get<TList>(this.apiUrlBase, {
                    params: Object.assign(this.options, this.filterOptions)
                })
                .then((response) => {
                    this.items = response.data.items;
                    this.totalItems = response.data.totalItems;
                })
                .catch((err) => {
                    this.showErrorMessage(err, "Could not get list");
                    return Promise.reject(err);
                });
        } finally {
            this.loading = false;
        }
    }

    protected async getFilteredLookupData() {
        await this.$axios
            .get<TFilteredLookupData>(this.apiUrlBase + "filter-data", {
                params: this.filterOptions
            })
            .then((response) => {
                this.filteredLookupData = response.data;
            })
            .catch((err) => {
                this.showErrorMessage(err, "Could not get lookup data");
                return Promise.reject(err);
            });
    }

    @Watch("options", { deep: true })
    protected getItemsByOptions(val: string | DataOptions) {
        // cancel the delay
        clearTimeout(this.timerId);

        // delay on searching when user types
        this.timerId = setTimeout(() => {
            this.getItemsFiltered();
        }, 1500);
    }

    public getItemsFiltered() {
        store.dispatch("events/setBatchLock").then(() => {
            this.isBatchLocked = store.getters["events/isBatchLocked"];
        });

        this.showOverlay();
        Promise.all([this.getFilteredLookupData(), this.getItems()]).finally(() => {
            this.hideOverlay();
        });
    }

    protected showOverlay() {
        this.overlay = true;
    }

    protected hideOverlay() {
        this.overlay = false;
    }

    constructor() {
        super();
    }
}
