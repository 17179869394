import { Component, Watch, Vue } from "vue-property-decorator";
import ERDTable from "@/components/ERDTable.vue";
import DiagramCommentsBox from "@/components/DiagramCommentsBox.vue";
import SkeletonLoaderTable from "../components/SkeletonLoaderTable.vue";
import NoData from "../components/NoData.vue";
import TitleComponentForER from "@/components/TitleComponentForER.vue";
import { getData, getParsedData } from "@/helpers/DMVHelpers";
import BaseHeader from "@/components/BaseHeader.vue";
import store from "@/store";

@Component({
    components: {
        DiagramCommentsBox,
        ERDTable,
        SkeletonLoaderTable,
        NoData,
        TitleComponentForER,
        BaseHeader
    }
})

/**
 * This class is responsible for the configurations for ERDiagram Tool as well as for watching and setting variables
 * */
export class ERDBase extends Vue {
    name: string = this.$route?.params?.subjectAreaId;
    stageId = "";
    uniqueHashMapForArrows: { [key: string]: any } = {};
    configKonva = {
        width: 0,
        height: 0,
        draggable: false,
        x: 0,
        y: 0
    };
    data: any[] = [];
    positions: any = {};
    factHashesForFloating: any = {};
    configForStageOfFloating = {
        width: 450,
        height: 400
    };
    showTables = false;

    configForStageOfHighlight = {
        width: 450,
        height: 400
    };
    height = 400;
    showFloating = false;
    uniqueHashMapForCircles: { [key: string]: any } = {};
    factHashes: any = {};
    factPositions = {
        x: 100,
        y: 50,
        index: 0
    };
     breadcrumbItems = [
            {
                text: "Back to Subject Areas",
                disabled: false,
                href: "/subjectAreas"
            },
            {
                text: "ER Diagram",
                disabled: true
            }
        ];
    finalPositionValue: any;
    dimensionHashes: any = {};
    floatingTablesData: any = {};
    factTables: { [key: string]: any } = {};
    dimensionTables: { [key: string]: any } = {};
    dimensionToFactAssociatedHash: any = {};
    showArrows = false;
    subjectAreaName: string;
    createdDate: any;
    showHighlight = false;
    xPositionOfSource: string;
    yPositionOfSource: string;
    isHighlightVisible: boolean;
    positionOfHighlightElements: any = [];
    showHighlightSourceConfig = {};
    showHighlightTargetConfig = {};
    prevEvent: any = null;
    prevItem: any = null;
    prevColor: any = "";
    arrowsOfHighlightToAppear = false;
    highlightArrowsItems: any = [];
    public windowHeight = "0";
    windowWidth = "0";
    url = "";
    numberOfTablesPerColumn = 0;
    lastDetails: any = {};
    factItemsForHighlight: any = {};
    allPromisesDone = false;
    showHighlights = false;
    highlightItems: any = [];
    finalYforArrows: any = {};
    lastDate: any;
    showHideFloatingTables() {
        this.showFloating = !this.showFloating;
    }
    initialHeightForFloating = 0;
    dashLineArrow = new Image(10, 10);
    solidArrowLine = new Image(10, 10);
    // showTables = false;
    buttonConfig = {
        height: 40,
        width: 200,
        cornerRadius: 6,
        fill: "#f5f5f5",
        x: 20,
        y: 80,
        shadowBlur: 5,
        shadowColor: "grey",
        shadowOpacity: 0.5,
        shadowOffset: { x: 0, y: 4 }
    };
    floatingTablesDataLength: number;

    highlightStyle = {
        border: "1px dashed black",
        borderSpacing: "2px",
        margin: "1rem",
        height: "30vw",
        background: "#CFD2CD",
        borderRadius: "5px",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 0px )"
    };
    relationshipPreviewConfig = {
        text: `Relationship Preview`,
        x: 120,
        y: 20,
        padding: 10,
        fill: "black",
        fontSize: 18,
        fontFamily: "Roboto, sans-serif"
    };

    textConfigForFloating = {
        x: 40,
        y: 83,
        padding: 10,
        fill: "black",
        fontSize: 14,
        fontFamily: "Roboto, sans-serif"
    };

    getLegendsConfig(indexForLegends: number, item: any) {
        return {
            text: item,
            x: 25,
            y: indexForLegends === 0 ? 5 : 5 + indexForLegends * 25,
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            height: 190
        };
    }

    getFullData() {
        return store.getters("schema/getMetaData");
    }

    getMainData() {
        const url = `/api/subjectareas/${this.$route?.params.subjectAreaId}/object-relation`;
        store.dispatch("schema/setMainMetaData", url);
    }
    @Watch("$store.state.schema.childNodePositions")
    getChildPositions() {
        const data = store.getters["schema/getPositionsOfChildNodes"];
        return data;
    }

    @Watch("$store.state.schema.metaData")
    putInitailData() {
        const data = store.getters["schema/getMetaData"];
        const floatingData = store.getters["schema/getFloatingData"];
        this.data = data;
        this.subjectAreaName = data[0]?.subjectAreaName
            ? data[0]?.subjectAreaName
            : floatingData[0]?.subjectAreaName;
        this.createdDate = data[0]?.orCreationDate;
        const parsedData = getParsedData(data);
        const { factTableGroups, dimensionTableGroups } = getData(
            parsedData["factTableGroups"],
            parsedData["dimensionTableGroups"]
        );
        this.data?.forEach((item, index) => {
            if(item?.addedType === 'dimension'){
                if (this.dimensionToFactAssociatedHash[item?.primaryObjectName]) {
                    this.dimensionToFactAssociatedHash[item?.primaryObjectName] = [
                        ...this.dimensionToFactAssociatedHash[item?.primaryObjectName],
                        item?.foreignObjectName
                    ];
                } else {
                    this.dimensionToFactAssociatedHash[item?.primaryObjectName] = [
                        item?.foreignObjectName
                    ];
                }
            }else{
                if (this.dimensionToFactAssociatedHash[item?.foreignObjectName]) {
                    this.dimensionToFactAssociatedHash[item?.foreignObjectName] = [
                        ...this.dimensionToFactAssociatedHash[item?.foreignObjectName],
                        item?.primaryObjectName
                    ];
                } else {
                    this.dimensionToFactAssociatedHash[item?.foreignObjectName] = [
                        item?.primaryObjectName
                    ];
                }
            }
        });
        this.factTables = factTableGroups;

        this.dimensionTables = dimensionTableGroups;

        if (floatingData.length > 1) {
            floatingData.map((_: any, index: number) => {
                if (index > 5) {
                    this.configForStageOfFloating.height += 50;
                }
            });
        }
        this.floatingTablesData = floatingData;
        // this.showTables = true;
    }

    constructor() {
        super();
        this.getMainData();
    }
}
