
import { Component, Watch } from "vue-property-decorator";
import { ERDBaseMain } from "@/models/ERDBaseMain";



import {
    downloadURI,
    getParsedData,
    ZoomInZoomOut,
    removeDuplicatesByKey,
    attachWindowResizer,
    getHeighAndWidthOfWindow,
    getData,
    getUpdatedDateHelper,
    GetPointsForArrows
} from "@/helpers/DMVHelpers";
import jsPDF from "jspdf";



@Component
export default class ERDiagramVisualisation extends ERDBaseMain {
    public titleRef:any
    public currentWindowHeight=this.windowHeight;
    public containerHeight:any;
    public stylesForDiv:any;


    mounted() {
        this.setComputedHeight(this.windowHeight);
        

        window.addEventListener("resize", ()=>{
        let height = this.setDimensionsForWindow();
          this.setComputedHeight(height);
        });
        this.getDimensionsWithConfig();

    }
    showHideFloatingTablesInner(){
        this.showHideFloatingTables()
    }
    setComputedHeight(calculatedHeight:any){
        this.stylesForDiv = {overflowX:'scroll',overflowY:'scroll',height:calculatedHeight}

    }
    

    created() {

        this.stageId = this.$route.params.subjectAreaId;
        window.addEventListener("resize", this.setDimensionsForWindow);
        window.scrollTo(0, 0);

        Promise.all([
            this.getMainData(),
            this.getFloatingObjects(),
            this.getDimensionsWithConfig()
        ]).then(() => {
            this.allPromisesDone = true;
            this.getDate();
        });

        this.url = `/api/subjectareas/${this.stageId}/diagramNotes`;

    }


    getHeight(){
        this.currentWindowHeight=this.windowHeight
    }

    
    zoomInZoomOutOnClick(direction: any) {
        const scaleBy = 1.2;
        // @ts-expect-error  Property 'getStage' does not exist on type 'Element
        const stage = this.$refs?.stageRef?.getStage();
        if(direction == 2){
            this.configKonva.height+=200
            this.configKonva.width+=100;
            this.configKonva.x+=20;
            this.configKonva.y+=20
        }
       else{
           this.configKonva.height-=200
           this.configKonva.x=20;
           this.configKonva.y=20;
            this.configKonva.width-=100;
        }
        ZoomInZoomOut({ stage, scaleBy, direction,isERD:true });
    }
    async getTitleRef(childThis:any){
        this.titleRef=childThis.$refs
    }
    getToaData(data:any){ 
        this.initialHeightForFloating=this.configForStageOfFloating.height;
    if(data.isOpen){
        data?.data?.map((item:any)=>{
            this.configForStageOfFloating.height+=20;
        })
    }else{
        this.configForStageOfFloating.height=this.initialHeightForFloating;

    }
       
    }

    getUniqueCircle(key: string) {
        return this.uniqueHashMapForCircles[key];
    }

    showFactRelations(event: any, value: string) {
        if (this.highlightArrowsItems!==null &&
            this.highlightArrowsItems?.length > 0 &&
            this.highlightArrowsItems[0]?.foreignObjectName === event[0]?.foreignObjectName
        ) {
            this.highlightArrowsItems = [];
            this.arrowsOfHighlightToAppear = false;
        } else {
            this.highlightArrowsItems = [];
            if (event) {
                event.map((item: any) => {
                    const value =
                        item.primaryObjectName +
                        item.foreignObjectName +
                        item.colorCode +
                        item.primaryAttributeName;
                    const getAllItems = Object.keys(this.uniqueHashMapForArrows).filter((i: any) =>
                        i.includes(item.primaryObjectName + item.foreignObjectName)
                    );
                    getAllItems.map((innerItem) => {
                        const uniqueItem = { ...item, ...this.uniqueHashMapForArrows[innerItem] };
                        uniqueItem.strokeWidth = 6;
                        uniqueItem.strokeColor = "blue";
                        uniqueItem.stroke = "blue";
                        this.highlightArrowsItems = [...this.highlightArrowsItems, uniqueItem];
                    });
                });
                this.arrowsOfHighlightToAppear = true;
            }
        }
    }



    async downloadAsPng() {
        //   @ts-expect-error getStage does exist but having type issues
        //stage
        const st1 = await this.$refs.stageRef.getStage();
        //title
        const st2 = await this.titleRef.titleRef.getStage();
        const st4 = await this.titleRef.titleRefForLegendsLine.getStage();
        const st5 = await this.titleRef.titleRefForLegends.getStage();
        //floating items
        const canvas1 = st1.toDataURL({ pixelRatio: 2 });
        const canvas2 = st2.toDataURL({ pixelRatio: 2 });
        const canvas4=st4.toDataURL({ pixelRatio:2})
        const canvas5=st5.toDataURL({ pixelRatio:2});

        const { width: konvaWidth, height: konvaHeight } = this.configKonva;
        // main API:
        var imageObjOne = new Image();
        var imageObjTwo = new Image();
        var imageObjThree = new Image();
        var imageObjFour = new Image();
        var imageObjFive = new Image();

        imageObjOne.src = canvas1;
        imageObjTwo.src = canvas2;
        imageObjFour.src=canvas4;
        imageObjFive.src = canvas5;
        //   @ts-expect-error getStage does exist but having type issues
        let st3 = this.$refs.stageRefForFloating?.getStage();

        let canvas3 = st3?.toDataURL({ pixelRatio: 2 });
        if (this.showFloating) {
            imageObjThree.src = canvas3;
        }

        // Create a Promise to wait for images to load
        const loadImages = new Promise<void>((resolve) => {
            let count = 0;
            const checkCount = () => {
                count++;

                if (count === 2) {
                    resolve();
                }
            };

            imageObjOne.onload = checkCount;
            imageObjTwo.onload = checkCount;
            imageObjThree.onload = checkCount;
            imageObjFour.onload = checkCount;
        });

        // Wait for all images to load
        await loadImages;

        var hiddenCanvas = document.createElement("canvas");
        let hiddenCtx = hiddenCanvas.getContext("2d");
        // set the dimensions of the canvas according to stage height
        // if stages are different height you'll have to calculate how to handle this
        let height: number, width: number;
        if (this.showFloating && this.floatingTablesData.length > this.data.length) {
            height = konvaHeight * 2;
            width = (konvaWidth + this.configForStageOfFloating.width) * 2;
        } else {
            height = konvaHeight * 2;
            width = konvaWidth * 2;
        }

        hiddenCanvas.width = width;
        hiddenCanvas.height = height;
        hiddenCtx.fillStyle = "#FFFFFF"; // Set the background color to white
        hiddenCtx.fillRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
        //now draw the images on the hidded canvas
        try {
            if (this.showFloating) {
                hiddenCanvas.height = height;
                hiddenCtx.fillStyle = "#FFFFFF";
                hiddenCtx.fillRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
                hiddenCtx.drawImage(imageObjTwo, 100, 40);
                hiddenCtx.drawImage(imageObjThree, 0, 500);
                hiddenCtx.drawImage(imageObjOne, 900, 500);
                hiddenCtx.drawImage(imageObjFour,1500,40)
                hiddenCtx.drawImage(imageObjFive,2000,40)
            } else {
                hiddenCtx.drawImage(imageObjTwo, 100, 40);
                hiddenCtx.drawImage(imageObjOne, 0, 400);
                hiddenCtx.drawImage(imageObjFour,1500,40)
                hiddenCtx.drawImage(imageObjFive,2000,40)
            }
        } catch (e) {
            //e.printStackTrace()
        }
        //   @ts-expect-error getStage does exist but having type issues
        const finalImage = hiddenCanvas.toDataURL({ pixelRatio: 2 });
        downloadURI(finalImage, `${this.data[0]?.subjectAreaName}.png`);
    }
    async downloadImageAsPdf() {
        if (this.data && this.data.length > 0) {
            //   @ts-expect-error getStage does exist but having type issues
            var currentStage = this.$refs.stageRef.getStage();
           
            var currentStageForTitle = await this.titleRef.titleRef.getStage();
            var titleRefForLegendsLines = await this.titleRef.titleRefForLegendsLine.getStage();
            var titleRefForLegends = await this.titleRef.titleRefForLegends.getStage();
            const height = this.configKonva.height;
            var pdf = new jsPDF("p", "px", [this.configKonva.width, this.configKonva.height],true);
            pdf.addImage(currentStageForTitle.toDataURL({ pixelRatio: 2 }), 0, 0, 1000, 200,undefined,'FAST');
            pdf.addImage(titleRefForLegendsLines.toDataURL({ pixelRatio: 2 }), 500, 50, 400, 200,undefined,'FAST')
            pdf.addImage(titleRefForLegends.toDataURL({ pixelRatio: 2 }), 1200, 50, 800, 250,undefined,'FAST')
            if (this.showFloating) {
                // @ts-expect-error getStage does exist but having type issues
                var currentStageForFloating = this.$refs.stageRefForFloating.getStage();
                pdf.addImage(
                    currentStageForFloating.toDataURL({ pixelRatio: 2 }),
                    0,
                    230,
                    400,
                    this.configForStageOfFloating.height,
                    undefined,'FAST'
                );
                pdf.addImage(
                    currentStage.toDataURL({ pixelRatio: 2 }),
                    350,
                    230,
                    this.configKonva.width,
                    this.configKonva.height,
                    undefined,'FAST'
                );
            } else {
                pdf.addImage(
                    currentStage.toDataURL({ pixelRatio: 2 }),
                    0,
                    300,
                    this.configKonva.width - 200,
                    this.configKonva.height,
                    undefined,'FAST'
                );
            }

            pdf.save(`${this.data[0]?.subjectAreaName}.pdf`);
        }
    }
    getWidthForTable(item: any) {
        return item.primaryObjectName.length > 47 ? item.primaryObjectName.length * 7 : 300;
    }

    constructor() {
        super();
    }
}
