import { GetterTree } from 'vuex'
import { AuthState } from './types'
import { RootState } from '../types'
import { UserRole } from '@/models/UserRole'
import { IUser } from '@/interfaces/IUser'

export const getters: GetterTree<AuthState, RootState> = {
    userName(state): string {
        if (state.currentUser && state.currentUser.userName) {
            return state.currentUser.userName
        } else {
            return ''
        }
    },
    currentUser(state): IUser {
        return state.currentUser
    },
    isAuthenticated(state): boolean {
        return !!state.accountInfo
    },
    isUserAdmin(state): boolean {
        if (state.currentUser) {
            return state.currentUser.userRoleIds.includes(UserRole.UserAdmin);
        } else {
            return false;
        }
    },
    isDataAdmin(state): boolean {
        if (state.currentUser) {
            return state.currentUser.userRoleIds.includes(UserRole.DataAdmin);
        } else {
            return false;
        }
    }
}
