
    import { Vue, Component, Provide, Prop, Watch } from 'vue-property-decorator'
    import store from '@/store'
    import { ProductDimension } from '@/models/ProductDimension';
    import { ProductDimensionMember } from '@/models/ProductDimensionMember';
    import { ComponentBase } from '@/models/ComponentBase';
    import { DataOptions } from 'vuetify/types'
    import { DataObjectList } from '@/models/DataObjectList';
    import { ProductDimensionListItem } from '@/models/ProductDimensionListItem';
    import { ProductDimensionFilterData } from '@/models/ProductDimensionFilterData';
    import { ProductDimensionFilterOptions } from '@/models/ProductDimensionFilterOptions';
    import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId';
    import { IGetItemsFiltered } from '@/interfaces/IGetItemsFiltered'

    @Component
    export default class ProductDimensions extends ComponentBase implements IGetItemsFiltered {

        @Prop()
        public productId: number;

        protected headerClass = "text-uppercase text-subtitle-1 primary--text font-weight-bold";
        protected cellClass = "text-subtitle-1 primary--text";
        protected totalItems = 0;
        protected items: Array<ProductDimensionListItem> = [];
        protected filteredLookupData = new ProductDimensionFilterData();
        protected filterOptions = new ProductDimensionFilterOptions();
        protected editedItem: ProductDimension = new ProductDimension();
        protected editedMemberItem: ProductDimensionMember = new ProductDimensionMember();
        protected dimensionLookup: Array<DropdownOptionIntegerId> = [];
        protected dimensionMemberLookup: Array<DropdownOptionIntegerId> = [];

        protected apiUrlBase = "/api/productdimensions/";
        private apiMemberUrl = "/api/productdimensionmembers/";
        private loading = true;
        private overlay = false;
        private dimensionOverlay = false;
        private memberOverlay = false;
        private isBatchLocked = false;
        private editDialog = false;
        private editMemberDialog = false;
        private editMemberItemIsValid = true;
        private isMemberEditUnlocked = false;
        private isEdit = false;

        private deleteDialog = false;
        private deletedItem: ProductDimensionListItem = new ProductDimensionListItem();

        private headers: Array<any> = [
            { text: "Dimension", value: "dimensionName", class: this.headerClass, cellClass: this.cellClass },
            { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
            { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
        ];

        protected timerId: number;

        protected options: DataOptions = {
            page: 1,
            itemsPerPage: 20,
            sortBy: ["loadDateText","dimensionName"],
            sortDesc: [true, false],
            groupBy: [],
            groupDesc: [false],
            multiSort: true,
            mustSort: false
        };

        protected async getItems() {
            try {
                this.loading = true;

                await this.$axios.get<DataObjectList<ProductDimensionListItem>>(this.apiUrlBase, {
                    params: Object.assign(this.options, this.filterOptions)
                })
                    .then(response => {
                        this.items = response.data.items;
                        this.totalItems = response.data.totalItems;
                    })  
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get list");
                        return Promise.reject(err);
                    });
            } finally {
                this.loading = false;
            }
        }

        protected async getFilteredLookupData() {
            this.filterOptions.productId = this.productId;

            await this.$axios.get<ProductDimensionFilterData>(this.apiUrlBase + "filter-data", {
                params: this.filterOptions
            }).then(response => {
                this.filteredLookupData = response.data;
            })
            .catch(err => {
                this.showErrorMessage(err, "Could not get filter lookup data");
                return Promise.reject(err);
            });
        }

        @Watch("options", { deep: true })
        protected getItemsByOptions(val: string | DataOptions) {
            // cancel the delay
            clearTimeout(this.timerId);

            // delay on searching when user types
            this.timerId = setTimeout(() => {
                this.getItemsFiltered();
            }, 1500);
        }

        public getItemsFiltered() {
            this.showOverlay();
            Promise.all([this.getFilteredLookupData(), this.getItems()])
            .finally(() => {
                this.hideOverlay();
            });
        }

        protected showOverlay() {
            this.overlay = true;
        }

        protected hideOverlay() {
            this.overlay = false;
        }

        protected showMemberOverlay() {
            this.memberOverlay = true;
        }

        protected hideMemberOverlay() {
            this.memberOverlay = false;
        }

        protected showDimensionOverlay() {
            this.dimensionOverlay = true;
        }

        protected hideDimensionOverlay() {
            this.dimensionOverlay = false;
        }

        protected async getItem(productId: number, dimensionId: number) {
            await this.$axios.get<ProductDimension>(this.apiUrlBase + 'products/' + productId + '/dimensions/' + dimensionId)
                .then(response => {
                    this.editedItem = Object.assign({}, response.data);
                    this.isMemberEditUnlocked = false;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get item");
                    return Promise.reject(err);
                });
        }

        protected async getMemberItem(id: number) {
            await this.$axios.get<ProductDimensionMember>(this.apiMemberUrl + id)
                .then(response => {
                    this.editedMemberItem = Object.assign({}, response.data);
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get member item");
                    return Promise.reject(err);
                });
        }

        protected async getDimensionLookup(productId: number) {
            await this.$axios.get<Array<DropdownOptionIntegerId>>(this.apiUrlBase + 'products/' + productId + '/dimensions-lookup/')
                .then(response => {
                    this.dimensionLookup = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

        protected async getDimensionMembersLookup(dimensionId: number) {
            await this.$axios.get<Array<DropdownOptionIntegerId>>(this.apiUrlBase + "dimensions/"+ dimensionId + "/members-lookup")
                .then(response => {
                    this.dimensionMemberLookup = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

        protected editItem(item: ProductDimensionListItem) {
            this.showOverlay();
            this.isEdit = true;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    Promise.all([this.getDimensionMembersLookup(item.dimensionId), this.getItem(item.productId, item.dimensionId)])
                        .then(() => {
                            this.editDialog = true;
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            });
        }

        protected editMemberItem(item: ProductDimensionMember) {
            this.showOverlay();
            this.isEdit = true;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.getMemberItem(item.productDimensionMemberId)
                        .then(() => {
                            this.editMemberDialog = true;
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            });
        }

        protected add() {
            this.showOverlay();
            this.isEdit = false;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.getDimensionLookup(this.productId)
                        .then(x => {
                            this.editedItem = Object.assign({}, new ProductDimension());
                            this.editedItem.productId = this.productId;
                            this.editDialog = true;
                            this.isMemberEditUnlocked = true;
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            })
        }

        private async create() {
            this.closeEditDialog();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.isEditItemValid) {
                        this.$axios.post(this.apiUrlBase, this.editedItem)
                            .then(response => {
                                this.showResponseFeedback(response);
                                this.getItemsFiltered();
                            })
                            .catch(err => {
                                this.showErrorMessage(err);
                            }).finally(() => {
                                this.hideOverlay();
                            });
                    }
                }
            });
        }

        private async update() {
            this.showDimensionOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideDimensionOverlay();
                } else {
                    if (this.isEditItemValid) {
                        this.$axios.put(this.apiUrlBase, this.editedItem)
                            .then(response => {
                                this.showResponseFeedback(response);
                                this.isMemberEditUnlocked = false;
                            })
                            .catch(err => {
                                this.showErrorMessage(err);
                            }).finally(() => {
                                this.hideDimensionOverlay();
                            });
                    }
                }
            });
        }

        private async updateMember() {
            this.showMemberOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideMemberOverlay();
                } else {
                    if (this.editMemberItemIsValid) {
                        this.$axios.put(this.apiMemberUrl + this.editedMemberItem.productDimensionMemberId, this.editedMemberItem)
                            .then(response => {
                                this.showResponseFeedback(response);
                            })
                            .catch(err => {
                                this.showErrorMessage(err);
                            }).finally(() => {
                                this.hideMemberOverlay();
                            });
                    }
                }
            });
        }

        private closeEditDialog() {
            this.editDialog = false;
            this.isEdit = false;
        }

        @Watch('editDialog')
        private editDialogChange(val: boolean) {
            val || this.closeEditDialog();
        }

        private closeEditMemberDialog() {
            this.editMemberDialog = false;
        }

        @Watch('editMemberDialog')
        private editMemberDialogChange(val: boolean) {
            val || this.closeEditMemberDialog();
        }

        private closeDeleteDialog() {
            this.deleteDialog = false;
        }

        private deleteItem(item: ProductDimensionListItem) {
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (!this.isBatchLocked) {
                    this.deleteDialog = true;
                    this.deletedItem = Object.assign({}, item);
                }
            });
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            this.$axios.delete(this.apiUrlBase + 'products/' + this.deletedItem.productId + '/dimensions/' + this.deletedItem.dimensionId)
                .then(response => {
                    this.showResponseFeedback(response);
                    this.getItemsFiltered();
                })
                .catch(err => {
                    this.showErrorMessage(err);
                }).finally(() => {
                    this.hideOverlay();
                });
        }

        private toggleMemberListSelection() {
            this.$nextTick(() => {
                if (this.selectedAllMembers) {
                    this.editedItem.dimensionMemberIds = [];
                } else {
                    this.editedItem.dimensionMemberIds = this.dimensionMemberLookup.map(i => { return i.id; });
                }
            })
        }

        @Watch('isMemberEditUnlocked')
        private resetEdit(val: boolean) {
            if (!val && this.isEdit) {
                this.showMemberOverlay();
                this.getItem(this.editedItem.productId, this.editedItem.dimensionId).then(() => {
                    this.getDimensionMembersLookup(this.editedItem.dimensionId)
                        .finally(() => {
                            this.hideMemberOverlay();
                        });
                });;
            }
        }

        get selectedAllMembers() {
            return this.editedItem.dimensionMemberIds && this.editedItem.dimensionMemberIds.length === this.dimensionMemberLookup.length;
        }

        get selectedSomeMembers() {
            return this.editedItem.dimensionMemberIds && this.editedItem.dimensionMemberIds.length > 0 && !this.selectedAllMembers;
        }

        get hasMembers() {
            return this.editedItem.dimensionMembers && this.editedItem.dimensionMembers.length > 0;
        }

        get isDimensionSelected() {
            return this.editedItem.dimensionId > 0;
        }

        get isDimensionMembersValid() {
            return this.editedItem.hasAllMembers || (this.editedItem.dimensionMemberIds && this.editedItem.dimensionMemberIds.length > 0);
        }

        get isEditItemValid() {
            return this.isDimensionSelected && this.isDimensionMembersValid;
        }

        get memberListIcon() {
            if (this.selectedAllMembers) return 'mdi-close-box';
            if (this.selectedSomeMembers) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        }

        constructor() {
            super();
        }
    };
