
    import { mixins } from 'vue-class-component'
    import { Component } from 'vue-property-decorator'
    import SnackbarNotification from '@/components/SnackbarNotification'
    import { AppUpdate } from '@/mixins/appUpdate'
    @Component({
        components: { SnackbarNotification }
    })
    export default class App extends mixins(AppUpdate) {
    }
