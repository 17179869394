
    import { Vue, Component } from 'vue-property-decorator'
    import { TargetObject } from '@/models/TargetObject';
    import { DataObjectList } from '@/models/DataObjectList';
    import { TargetObjectListItem } from '@/models/TargetObjectListItem';
    import { TargetObjectLookupData } from '@/models/TargetObjectLookupData';
    import { TargetObjectFilterData } from '@/models/TargetObjectFilterData';
    import FadeTooltip from '@/components/FadeTooltip'
    import InfoPanel from '@/components/InfoPanel'
    import { ObjectDataListEditorBase } from '@/models/ObjectDataListEditorBase';
    import { TargetObjectFilterOptions } from '@/models/TargetObjectFilterOptions';
    import { FSReviewListItem } from '@/models/FSReviewListItem';
    import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId'
    import { DropdownOption } from '@/models/DropdownOption'

    @Component({
        components: {
            FadeTooltip,
            InfoPanel
        }
    })

    export default class TargetObjects extends ObjectDataListEditorBase<TargetObject, TargetObjectListItem, DataObjectList<TargetObjectListItem>, TargetObjectLookupData, TargetObjectFilterData, TargetObjectFilterOptions>  {

        private headers: Array<any>;
        // editedItem is being overridden and made observable because the inherited class cannot initialise it using the generic type
        // this needs to be done on the property for it to be added to Vue @data and made observable, doesn't work in created()
        protected editedItem: TargetObject = Vue.observable(new TargetObject());

        public dialogFSReviewInfo = false;
        public fsDialogTitle = "FS Review Warnings";
        public fsReviewItems: Array<FSReviewListItem> = [];
        //public geoLookupData = new TargetObjectGeoCoverageLookupData();
        public geoLookupData: Array<DropdownOption> = [];
        private loadGeo = false;
        

        created() {
            this.filteredLookupData = new TargetObjectFilterData();
            this.lookupData = new TargetObjectLookupData();
            this.filterOptions = new TargetObjectFilterOptions();
            this.apiUrlBase = "/api/targetobjects/";
            this.options.sortBy = ["loadDateText", "targetObjectName"];
            this.options.sortDesc = [true, false];
            

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Target Object", value: "targetObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Platform", value: "platformName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Data Owner", value: "dataOwnerName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Data Set", value: "dataSetName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Target Object Type", value: "targetObjectTypeName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        private showFSReviewDialog(title: string) {
            this.fsDialogTitle = "FS Review Warnings - " + title;
            this.dialogFSReviewInfo = true;
        }

        private closeFSReviewInfo() {
            this.dialogFSReviewInfo = false;
        }

        private async getFSReviewData(item: TargetObjectListItem) {
            var parentKey = "TOB" + item.id;
            await this.$axios.get<Array<FSReviewListItem>>("/api/fsreview/" + parentKey)
                .then(response => {
                    this.fsReviewItems = response.data;
                    this.showFSReviewDialog(item.targetObjectName + " [" + item.id + "]");
                })
                .catch(err => {
                    let errorMessage = "Could not get FS Review data";
                    this.showFailureMessage({ title: "Error", message: errorMessage });
                });
        }

        private async editItemClick(item: TargetObjectListItem) {
            
            this.editItem(item);
            this.getGeoLookupData(item.geoCoverageLevelId);
        }

        private async getGeoLookupData(GeoCoverageLevelId: number) {
            if (GeoCoverageLevelId) {
                await this.$axios.get<Array<DropdownOption>>("/api/targetobjects/" + GeoCoverageLevelId + "/geo-coverage-lookup").then(response => {
                    this.geoLookupData = response.data;
                }).catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
            }            
        }       

        constructor() {
            super();
        }
    };
