import { Module } from "vuex";
import { RootState } from "../types";
import { SchemaType } from "./types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
export const state: SchemaType = {
    data: [],
    metaData: [],
    toaData: [],
    childNodePositions: {},
    floatingData: []
};

const namespaced = true;

export const schema: Module<SchemaType, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
