
    import { Vue, Component, Provide, Prop, Watch } from 'vue-property-decorator'
    import { SourceObjectAttribute } from '@/models/SourceObjectAttribute';
    import { DataObjectList } from '@/models/DataObjectList';
    import { SourceObjectAttributeListItem } from '@/models/SourceObjectAttributeListItem';
    import { SourceObjectAttributeLookupData } from '@/models/SourceObjectAttributeLookupData';
    import { SourceObjectAttributeFilterData } from '@/models/SourceObjectAttributeFilterData';
    import FadeTooltip from '@/components/FadeTooltip'
    import { ObjectDataListEditorBase } from '@/models/ObjectDataListEditorBase';
    import { SourceObjectAttributeFilterOptions } from '@/models/SourceObjectAttributeFilterOptions';

    @Component({
        components: {
            FadeTooltip
        }
    })
    export default class SourceObjectAttributes extends ObjectDataListEditorBase<SourceObjectAttribute, SourceObjectAttributeListItem, DataObjectList<SourceObjectAttributeListItem>, SourceObjectAttributeLookupData, SourceObjectAttributeFilterData, SourceObjectAttributeFilterOptions>  {

        private headers: Array<any>;
        // editedItem is being overridden and made observable because the inherited class cannot initialise it using the generic type
        // this needs to be done on the property for it to be added to Vue @data and made observable, doesn't work in created()
        protected editedItem: SourceObjectAttribute = Vue.observable(new SourceObjectAttribute());

        created() {
            this.filteredLookupData = new SourceObjectAttributeFilterData();
            this.lookupData = new SourceObjectAttributeLookupData();
            this.filterOptions = new SourceObjectAttributeFilterOptions();
            this.apiUrlBase = "/api/sourceobjectattributes/";
            this.options.sortBy = ["loadDateText", "sourceObjectName", "sourceObjectAttributeName"];
            this.options.sortDesc = [true, false, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Attribute Name", value: "sourceObjectAttributeName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Source Object", value: "sourceObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        constructor() {
            super();
        }
    };
