import Vue from "vue";
import { ActionTree } from "vuex";
import { AuthState } from "@/store/auth/types";
import { RootState } from "@/store/types";
import { AccountInfo } from "@azure/msal-browser";
import { IUser } from "@/interfaces/IUser";
import { AxiosResponse } from "axios";

export const actions: ActionTree<AuthState, RootState> = {
    async login({ commit, state }) {
        try {
            // auth service will check local storage for the account first
            await Vue.prototype.$authService
                .loadAuthModule()
                .then((account: AccountInfo | null) => {
                    if (account) {
                        commit("updateAccountInfo", account);
                        if (state.currentUser == null) {
                            return Vue.prototype.$userService
                                .getUserByEmail(account.username)
                                .then((resp: AxiosResponse<IUser>) => {
                                    commit("updateCurrentUser", resp.data);
                                });
                        }
                    } else {
                        return Vue.prototype.$authService.login("loginRedirect");
                    }
                });
        } catch (err) {
            this.dispatch("notifications/showError", {
                title: "Error",
                message: "Failed to login"
            });
        }
    },
    async getAPIAccessToken() {
        try {
            // auth service will check local storage for the account first
            await Vue.prototype.$authService.loadAuthModule();
            return await Vue.prototype.$authService
                .getAPITokenRedirect()
                .then((token: string | null) => {
                    return token;
                });
        } catch (err) {
            this.dispatch("notifications/showError", {
                title: "Error",
                message: "Failed to get API token."
            });
        }
    },
    logout({ commit }) {
        try {
            Vue.prototype.$authService.logout();
            commit("updateAccountInfo", null);
            commit("updateCurrentUser", null);
        } catch (err) {
            this.dispatch("notifications/showError", {
                title: "Error",
                message: "Failed to logout."
            });
        }
    }
};
