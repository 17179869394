'use strict'

import Vue from 'vue'
import store from '@/store'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

// Set config defaults when creating the instance
// TODO: remove this example later if we don't need to manage multiple instances in axios
//const instance = axios.create({
//    baseURL: 'https://designapp-d.unilever.com'
//});
//instance.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
//instance.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('APIAccessToken');

// tslint:disable-next-line: variable-name
const _axios = axios.create(config)

// the below interceptors are used to make all requests use an access token to our API
_axios.interceptors.request.use(
  // Do something before request is sent
    async (config: AxiosRequestConfig) => {
        await store.dispatch("auth/getAPIAccessToken").then((token) => {
            if (token && token.length > 0) {
                config.headers = {
                    'Authorization': `Bearer ${token}`
                }
            }
        });
        return config;
    },
  // Do something with request error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: any) => Promise.reject(error)
)

// Add a response interceptor
_axios.interceptors.response.use(
  // Do something with response data
  (response) => response,
    // Do something with response error
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            await store.dispatch("auth/getAPIAccessToken").then((token) => {
                if (token && token.length > 0) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                }
            });

            return _axios(originalRequest);
        }
        return Promise.reject(error);
    }
)

function AxiosPlugin(vue: typeof Vue): void {
  vue.prototype.$axios = _axios
}

declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosInstance
  }
}

Vue.use(AxiosPlugin)

export default AxiosPlugin

