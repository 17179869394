import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { auth } from "./auth/index";
import { notifications } from "./notifications/index";
import { schema } from "./schema/index";
import { events } from "./events/index";

Vue.use(Vuex);

// Vuex structure based on https://codeburst.io/vuex-and-typescript-3427ba78cfa8

const store: StoreOptions<RootState> = {
    state: {
        version: "1.0.0" // a simple property
    },
    modules: {
        auth,
        notifications,
        events,
        schema
    }
};

export default new Vuex.Store<RootState>(store);
