import { MutationTree } from 'vuex'
import { AuthState } from './types'

export const mutations: MutationTree<AuthState> = {
  updateCurrentUser(state, user) {
    state.currentUser = user
  },
  updateAccountInfo(state, accountInfo) {
    state.accountInfo = accountInfo
  }
}
