
    import { Component, Provide, Vue } from 'vue-property-decorator'
    import { ComponentBase } from '@/models/ComponentBase'

@Component
export default class NotAuthenticated extends ComponentBase {
  constructor() {
    super()
  }
}
