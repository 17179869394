
import { Action, Getter } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
const namespace = 'notifications'

@Component
export default class SnackbarNotification extends Vue {
    @Getter('showSnackBar', { namespace })
    private showSnackBar!: boolean;
    @Getter('snackBarTitle', { namespace })
    private snackBarTitle!: string;
    @Getter('snackBarMessage', { namespace })
    private snackBarMessage!: string
    @Getter('snackBarIcon', { namespace })
    private snackBarIcon!: string
    @Getter('snackBarColour', { namespace })
    private snackBarColour!: string
    @Getter('snackBarTimeout', { namespace })
    private snackBarTimeout!: string

    @Action('hideNotification', { namespace })
    private hideNotification!: () => void

    get showNotification() {
        return this.showSnackBar;
    }

    set showNotification(value) {
        this.hideNotification()
    }
}
