
    import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
    import {ref} from 'vue';
    import { ObjectDataListBase } from '@/models/ObjectDataListBase';
    import { ProductListItem } from '@/models/ProductListItem';
    import { ProductFilterData } from '@/models/ProductFilterData';
    import { ProductFilterOptions } from '@/models/ProductFilterOptions';
    import { DataObjectList } from '@/models/DataObjectList';   

    @Component
    export default class ProductsIndex extends ObjectDataListBase<ProductListItem, DataObjectList<ProductListItem>, ProductFilterData, ProductFilterOptions> {

        private headers: Array<any>;
        private deleteDialog = false;
        private deleteId: number;
        public hasDmvFlagItems:Array<any> =null;
        created() {
            this.filteredLookupData = new ProductFilterData();
            this.filterOptions = new ProductFilterOptions();
            this.apiUrlBase = "/api/products/";
            this.options.sortBy = ["loadDateText", "productName"];
            this.options.sortDesc = [true, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Product Name", value: "productName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Platform", value: "platformName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Lead SME", value: "leadSMEName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "Action", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
            this.getDiagramAvailability();
        }

        // router link isn't working from within the data table, it was not recognising the use of 'item'
        productLink(id: number) {
            this.$router.push({ path: '/products/' + id });
        }
        visualiseStarSchemaOfProduct(id: number) {
            this.$router.push({ path: '/products/' + id +'/visualiseschema'});
        }

        private closeDeleteDialog() {
            this.deleteDialog = false;
        }


        private diagramExists(productId:number){
            if(this.hasDmvFlagItems!==null){
                return this.hasDmvFlagItems?.filter((i: { productId: number; })=>i.productId===productId)[0]?.diagramFlag===0
            }else{
                return true;
            } 
        }

        private deleteItem(id: number) {
            this.deleteDialog = true;
            this.deleteId = id;
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            this.$axios.delete(this.apiUrlBase + this.deleteId)
                .then(response => {
                    this.showResponseFeedback(response);
                    this.getItemsFiltered();
                })
                .catch(err => {
                    this.showErrorMessage(err);
                }).finally(() => {
                    this.hideOverlay();
                });
        }
        public async getDiagramAvailability(){
           return await this.$axios.get(this.apiUrlBase+'star-schema-hasDMVFlag/')
                .then((response) => {
                    this.hasDmvFlagItems = response.data;  
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
                
        }

        constructor() {
            super();
        }

    }
