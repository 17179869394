
    import { Component, Prop, Ref } from 'vue-property-decorator';
    import ProductsForm from '@/components/ProductsForm';
    import ProductMeasureGroups from '@/components/ProductMeasureGroups';
    import ProductDimensions from '@/components/ProductDimensions';
    import { ComponentBase } from '@/models/ComponentBase';
    import DashboardCard from '@/components/DashboardCard';
    import { FormStateType } from '@/models/FormStateType';
    import { IGetItemsFiltered } from '@/interfaces/IGetItemsFiltered';
    import { IGetItem } from '@/interfaces/IGetItem';

    @Component({
        components: {
            DashboardCard,
            ProductsForm,
            ProductMeasureGroups,
            ProductDimensions
        }
    })
    export default class ProductsEdit extends ComponentBase {

        @Prop()
        public productId: number; // this prop comes via route parameter
        public productNameTitle = "Edit Product";

        private formState: FormStateType = FormStateType.Edit;

        private breadcrumbItems = [
            {
                text: 'Back to products',
                disabled: false,
                href: '/products',
            },
            {
                text: 'Edit',
                disabled: true
            }
        ];

        @Ref() readonly productFormRef!: IGetItem;
        @Ref() readonly productMeasureGroupsRef!: IGetItemsFiltered;
        @Ref() readonly productDimensionsRef!: IGetItemsFiltered;

        private async setProductName(value: string) {
            this.productNameTitle = value;
        }

        private refreshAll() {
            this.productFormRef!.getItem(this.productId);
            this.productMeasureGroupsRef!.getItemsFiltered();
            this.productDimensionsRef!.getItemsFiltered();
        }

        constructor() {
            super();
        }



    }
