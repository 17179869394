
    import { Vue, Component, Provide, Prop, Watch } from 'vue-property-decorator'
    import { TargetCalculation } from '@/models/TargetCalculation';
    import { DataObjectList } from '@/models/DataObjectList';
    import { TargetCalculationListItem } from '@/models/TargetCalculationListItem';
    import { TargetCalculationLookupData } from '@/models/TargetCalculationLookupData';
    import { TargetCalculationFilterData } from '@/models/TargetCalculationFilterData';
    import FadeTooltip from '@/components/FadeTooltip'
    import { ObjectDataListEditorBase } from '@/models/ObjectDataListEditorBase';
    import { TargetCalculationFilterOptions } from '@/models/TargetCalculationFilterOptions';

    @Component({
        components: {
            FadeTooltip
        }
    })
    export default class TargetCalculations extends ObjectDataListEditorBase<TargetCalculation, TargetCalculationListItem, DataObjectList<TargetCalculationListItem>, TargetCalculationLookupData, TargetCalculationFilterData, TargetCalculationFilterOptions>  {

        private headers: Array<any>;
        // editedItem is being overridden and made observable because the inherited class cannot initialise it using the generic type
        // this needs to be done on the property for it to be added to Vue @data and made observable, doesn't work in created()
        protected editedItem: TargetCalculation = Vue.observable(new TargetCalculation());

        created() {
            this.filteredLookupData = new TargetCalculationFilterData();
            this.lookupData = new TargetCalculationLookupData();
            this.filterOptions = new TargetCalculationFilterOptions();
            this.apiUrlBase = "/api/targetcalculations/";
            this.options.sortBy = ["loadDateText", "targetObjectName", "targetObjectAttributeName"];
            this.options.sortDesc = [true, false, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Target Object Attribute", value: "targetObjectAttributeName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Target Object", value: "targetObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Calculation Type", value: "calculationTypeName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        constructor() {
            super();
        }
    };
