
    import { Component, Vue, Prop } from 'vue-property-decorator'

    @Component
    export default class InfoPanel extends Vue {
        @Prop()
        public header: string;
        @Prop()
        public text: string;
    };
