
import { Component, Vue, Prop} from 'vue-property-decorator';
@Component
 export default class DiagramCommentsBox extends Vue{
    @Prop()
      productId:number;
    @Prop()
    url:string;
    public alert = false;
    public diagramNotesData = [{diagramNotes: {}}];



async getDiagramNotes(){
        try {
            await Vue.prototype.$axios
                .get(this.url)
                .then((response:any) => {
                    if(response.data){                
                        if(response.data.length!==undefined){
                          this.diagramNotesData=response.data
                        }else{
                          this.diagramNotesData=[response.data];
                        }
                      //this.alert=true
                    }
                });
        } catch (error) {
          this.alert=false;
        }
    }

created() {
        this.getDiagramNotes();
    }
    
    }
