<template>
    <div>

   
   <v-row v-if="showArrows">
            <v-col md="7">
                <v-stage :config="{ height: 150, width: 800 }" ref="titleRef">
                    <v-layer>
                        <v-text
                            v-if="dataLength > 0"
                            :config="{
                                text: subjectAreaName,
                                x: 10,
                                y: 0,
                                padding: 10,
                                fill: 'blue',
                                fontSize: 21
                            }"
                        />
                        <v-text
                            v-if="dataLength > 0"
                            :config="{
                                text: `LAST UPDATED: ${date} - GMT`,
                                x: 10,
                                y: 35,
                                padding: 10,
                                fill: 'black',
                                fontSize: 15,
                                fontFamily: 'Roboto, sans-serif'
                            }"
                        />

                        <v-rect
                            v-if="floatingTablesDataLength>0"
                            :config="buttonConfig"
                            @mouseenter="showPointerEnter"
                            @mouseleave="showPointerLeave"
                            @click="showHideFloatingTablesInner"
                        />
                        <v-text
                            v-if="floatingTablesDataLength>0"
                            @mouseenter="showPointerEnter"
                            @mouseleave="showPointerLeave"
                            @click="showHideFloatingTablesInner"
                            :config="{
                                ...textConfigForFloating,
                                x: 30,
                                text: `FLOATING OBJECTS: ${floatingTablesDataLength}`
                            }"
                        />
                    </v-layer>
                </v-stage>
            </v-col>
            <v-col md="3" class="justify-center">
                <!-- <div > -->

                    <v-stage
            :config="{ height: 150, width: 200 }"
            ref="titleRefForLegendsLine">
            <v-layer>
                <div v-for="(item, indexForLineLegends) in Object.keys(lineLegendData)"
                    :key="indexForLineLegends">
                    <v-arrow
                    :config="{
                         points: getPoints(indexForLineLegends),
                        fill: 'black',
                        stroke:'black',
                        strokeWidth: 4,
                        pointerWidth: 12,
                        pointerLength: 12,
                        dash: [8, 8],
                        dashEnabled:indexForLineLegends===1,
                    }"
                    />
                    <v-text :config="getLegendsConfig(indexForLineLegends,lineLegendData[item],true)" />
                </div>
            </v-layer>
        </v-stage>
                <!-- </div> -->

            
            </v-col>
         <v-col md="2" class="padding-left-nill">
                <v-stage
                    :config="{ height: 150, width: 400 }"
                    ref="titleRefForLegends"
                    v-if="showArrows"
                >
                    <v-layer>
                        <div
                            v-for="(item, indexForLegends) in Object.keys(legendData)"
                            :key="indexForLegends"
                        >
                            <v-rect
                                :config="{
                                    x: 0,
                                    y: indexForLegends === 0 ? 0 : indexForLegends * 25,
                                    height: 20,
                                    width: 20,
                                    fill: legendData[item]
                                }"
                            />
                            <v-text :config="getLegendsConfig(indexForLegends, item)" />
                        </div>
                    </v-layer>
                </v-stage>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import store from '@/store';
export default{
    props:{
        showArrows:Boolean,
        subjectAreaName:String,
        dataLength:Number,
        floatingTablesDataLength:Number,
        date:String 
    },
    data(){
        return{
        floatingItemsLength:0,
        buttonConfig :{
        height: 40,
        width: 200,
        cornerRadius: 6,
        fill: "#f5f5f5",
        x: 20,
        y: 80,
        titleRef:"",
        shadowBlur: 5,
        shadowColor: "grey",
        shadowOpacity: 0.5,
        shadowOffset: { x: 0, y: 4 }
    },
    textConfigForFloating : {
        x: 40,
        y: 83,
        padding: 10,
        fill: "black",
        fontSize: 14,
        fontFamily: "Roboto, sans-serif"
    },
    legendData : {
        Fact: "#c76e1f",
        Dimension: "#B2AE36",
        Undefined: "grey",
        DimensionTypeDimandFactData: "#252DCF",
        DimensionTypeDimOnlyData: "pink",
        DimensionTypeFactOnlyData: "black"
    },
    lineLegendData : {
        Solid: "CERTIFIED",
        Dotted: "UNCERTIFIED"
    },
}
    },
    mounted(){
        this.titleRef=this.$refs.titleRef
        this.$emit('get-title-ref',this)
    },
    computed:{
        getToaData() {
            return store.getters["schema/getToaData"];
        }
    },
    methods: {
        getLegendsConfig(indexForLegends, item,isLine=false) {
        return {
            text: item,
            x: isLine?55:35,
            y: indexForLegends === 0 ? 5 : 5 + indexForLegends * 25,
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            height: 190
        };
    },

    showPointerEnter() {
        this.buttonConfig = { ...this.buttonConfig, fill: "#E0E0E0" };
    },
    showPointerLeave() {
        this.buttonConfig = { ...this.buttonConfig, fill: "#f5f5f5" };
    },
        showHideFloatingTablesInner(){
            this.$emit('show-hide-floating-inner')
        },
        getPoints(index){
           return index===0?[0,10,50,10]:[0,30,50,30]
        }
    },

}
</script>
<style scoped>
.text-end{
    text-align: end;
}
.justify-center{
justify-content: end !important;
display:flex
}
.padding-left-nill{
    padding:0 !important;
}
</style>