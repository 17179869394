
    import { Vue, Component, Provide, Prop, Watch } from 'vue-property-decorator'
    import store from '@/store'
    import { SubjectAreaTargetObject } from '@/models/SubjectAreaTargetObject';
    import { DataObjectList } from '@/models/DataObjectList';
    import { SubjectAreaTargetObjectListItem } from '@/models/SubjectAreaTargetObjectListItem';
    import { SubjectAreaTargetObjectLookupData } from '@/models/SubjectAreaTargetObjectLookupData';
    import { SubjectAreaTargetObjectFilterData } from '@/models/SubjectAreaTargetObjectFilterData';
    import { ObjectDataListBase } from '@/models/ObjectDataListBase';
    import { SubjectAreaTargetObjectFilterOptions } from '@/models/SubjectAreaTargetObjectFilterOptions';

    @Component
    export default class SubjectAreaTargetObjects extends ObjectDataListBase<SubjectAreaTargetObjectListItem, DataObjectList<SubjectAreaTargetObjectListItem>, SubjectAreaTargetObjectFilterData, SubjectAreaTargetObjectFilterOptions>  {

        @Prop()
        public subjectAreaId: number;
        protected editedItem: SubjectAreaTargetObject = new SubjectAreaTargetObject();
        //protected items:

        protected lookupData: SubjectAreaTargetObjectLookupData = new SubjectAreaTargetObjectLookupData();
        protected editDialog = false;
        protected editItemIsValid = false;

        private headers: Array<any>;
        private deleteDialog = false;
        private deletedItem: SubjectAreaTargetObjectListItem = new SubjectAreaTargetObjectListItem();
        private isSubjectAreaExisting = false;
        private isEdit = false;

        // override of the existing lookup function to hard set the productId
        // calling base class function was not working due to the promise being called by subsequent functions
        protected async getFilteredLookupData() {
            this.filterOptions.subjectAreaId = this.subjectAreaId;
            //this.filterOptions.subjectAreaName = this.sub

            await this.$axios.get<SubjectAreaTargetObjectFilterData>(this.apiUrlBase + "filter-data", {
                params: this.filterOptions
            }).then(response => {
                this.filteredLookupData = response.data;
            })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                });
        }

        protected async getItem(id: number) {
            await this.$axios.get<SubjectAreaTargetObject>(this.apiUrlBase + id)
                .then(response => {
                    this.editedItem = Object.assign({}, response.data);                   
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get item");
                    return Promise.reject(err);
                });
        }

        protected async getLookupData() {
            this.filterOptions.subjectAreaId = this.subjectAreaId;
            this.editedItem.subjectAreaId = this.subjectAreaId;
            await this.$axios.get<SubjectAreaTargetObjectLookupData>(this.apiUrlBase + "lookup-data", {
                params: { subjectAreaId: this.subjectAreaId, subjectAreaName: this.editedItem.subjectAreaName, targetObjectIds: this.editedItem.targetObjectIds, platformIds: this.editedItem.platformIds, dataSetIds: this.editedItem.dataSetIds, dataOwnerIds: this.editedItem.dataOwnerIds }
                //params: this.filterOptions
            })
                .then(response => {
                    this.lookupData = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

        protected add() {
            this.showOverlay();
            this.isEdit = false;
            this.editItemIsValid = true;
            this.editedItem.dataOwnerIds = null;
            this.editedItem.dataSetIds = null;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.getLookupData(), this.getItem(this.subjectAreaId)
                        .then(x => {
                            this.editedItem = Object.assign({}, new SubjectAreaTargetObject());
                            this.editedItem.subjectAreaId = this.subjectAreaId;                         
                            this.editDialog = true;
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            })
        }

        protected editItem(item: SubjectAreaTargetObjectListItem) {
            this.showOverlay();
            this.isEdit = true;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    Promise.all([this.getLookupData(), this.getItem(item.id)])
                        .then(x => {
                            this.editDialog = true;
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            })
        }

        private async create() {
            this.closeEditDialog();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.$axios.post(this.apiUrlBase, this.editedItem)
                            .then(response => {
                                this.showResponseFeedback(response);
                                this.getItemsFiltered();
                            })
                            .catch(err => {
                                this.showErrorMessage(err);
                            }).finally(() => {
                                this.hideOverlay();
                            });
                    }
                }
            });
        }

        private async update() {
            this.showOverlay();
            this.closeEditDialog();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.$axios.put(this.apiUrlBase + this.editedItem.subjectAreaId, this.editedItem)
                            .then(response => {
                                this.showResponseFeedback(response);
                            })
                            .catch(err => {
                                this.showErrorMessage(err);
                            }).finally(() => {
                                this.hideOverlay();
                            });
                    }
                }
            });
        }

        protected closeEditDialog() {
            this.editDialog = false;
            this.isEdit = false;
        }

        private closeDeleteDialog() {
            this.deleteDialog = false;
        }

        private deleteItem(item: SubjectAreaTargetObjectListItem) {
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (!this.isBatchLocked) {
                    this.deleteDialog = true;
                    this.deletedItem = Object.assign({}, item);
                }
            });
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.$axios.delete(this.apiUrlBase + this.deletedItem.id)
                        .then(response => {
                            this.showResponseFeedback(response);
                            this.getItemsFiltered();
                        })
                        .catch(err => {
                            this.showErrorMessage(err);
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            });
        }

        private getExists() {
            if (this.editedItem && this.editedItem.subjectAreaId && this.editedItem.subjectAreaId) {
                this.$axios.get<boolean>(this.apiUrlBase + "exists", {
                    params: { subjectAreaId: this.editedItem.subjectAreaId, target: this.editedItem.subjectAreaId }
                })
                    .then(response => {
                        this.isSubjectAreaExisting = response.data;
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get existing measure group status");
                    });
            } else {
                this.isSubjectAreaExisting = false;
            }
        }

        created() {
            this.filteredLookupData = new SubjectAreaTargetObjectFilterData();
            this.lookupData = new SubjectAreaTargetObjectLookupData();
            this.filterOptions = new SubjectAreaTargetObjectFilterOptions();
            this.editedItem = new SubjectAreaTargetObject();

            this.apiUrlBase = "/api/SubjectAreaTargetObjects/";
            this.options.sortBy = ["loadDateText", "targetObjectName"];
            this.options.sortDesc = [true, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Target Objects", value: "targetObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        constructor() {
            super()
        }
    }
