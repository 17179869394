
    import { Vue, Component, Provide, Prop, Watch } from 'vue-property-decorator'
    import { SourceObject } from '@/models/SourceObject';
    import { DataObjectList } from '@/models/DataObjectList';
    import { SourceObjectListItem } from '@/models/SourceObjectListItem';
    import { SourceObjectLookupData } from '@/models/SourceObjectLookupData';
    import { SourceObjectFilterData } from '@/models/SourceObjectFilterData';
    import FadeTooltip from '@/components/FadeTooltip'
    import { ObjectDataListEditorBase } from '@/models/ObjectDataListEditorBase';
    import { SourceObjectFilterOptions } from '@/models/SourceObjectFilterOptions';
    import { FSReviewListItem } from '@/models/FSReviewListItem';
    import { DropdownOption } from '@/models/DropdownOption'

    @Component({
        components: {
            FadeTooltip
        }
    })
    export default class SourceObjects extends ObjectDataListEditorBase<SourceObject, SourceObjectListItem, DataObjectList<SourceObjectListItem>, SourceObjectLookupData, SourceObjectFilterData, SourceObjectFilterOptions>  {

        private headers: Array<any>;
        // editedItem is being overridden and made observable because the inherited class cannot initialise it using the generic type
        // this needs to be done on the property for it to be added to Vue @data and made observable, doesn't work in created()
        protected editedItem: SourceObject = Vue.observable(new SourceObject());

        private numberRules: Array<(data: string) => void> = [
            (v: any) => (!v || /^(0|[1-9]\d{0,8})$/.test(v)) || 'Please enter a number 0 to 999999999.'
        ];

        private numberIPrRules: Array<(data: string) => void> = [
            (v: any) => (!v || /^-?\d+$/.test(v)) || 'Please enter a valid Number.'
        ];

        public dialogFSReviewInfo = false;
        public fsDialogTitle = "FS Review Warnings";
        public fsReviewItems: Array<FSReviewListItem> = [];
        public geoLookupData: Array<DropdownOption> = [];
        private loadGeo = false;
        private sasExpiryDateMenu = false;

        created() {
            this.filteredLookupData = new SourceObjectFilterData();
            this.lookupData = new SourceObjectLookupData();
            this.filterOptions = new SourceObjectFilterOptions();
            this.apiUrlBase = "/api/sourceobjects/";
            this.options.sortBy = ["loadDateText", "sourceObjectName"];
            this.options.sortDesc = [true, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Source Object", value: "sourceObjectName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Platform", value: "platformName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Requesting Data SME", value: "dataOwnerName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Data Set", value: "dataSetName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        private showFSReviewDialog(title: string) {
            this.fsDialogTitle = "FS Review Warnings - " + title;
            this.dialogFSReviewInfo = true;
        }

        private closeFSReviewInfo() {
            this.dialogFSReviewInfo = false;
        }

        private async getFSReviewData(item: SourceObjectListItem) {
            var parentKey = "SOB" + item.id;
            await this.$axios.get<Array<FSReviewListItem>>("/api/fsreview/" + parentKey)
                .then(response => {
                    this.fsReviewItems = response.data;
                    this.showFSReviewDialog(item.sourceObjectName + " [" + item.id + "]");
                })
                .catch(err => {
                    let errorMessage = "Could not get FS Review data";
                    this.showFailureMessage({ title: "Error", message: errorMessage });
                });
        }

        private async editItemClick(item: SourceObjectListItem) {

            this.editItem(item);
            this.getGeoLookupData(item.geoCoverageLevelId);
        }

        private async getGeoLookupData(GeoCoverageLevelId: number) {
            if (GeoCoverageLevelId) {
                await this.$axios.get<Array<DropdownOption>>("/api/targetobjects/" + GeoCoverageLevelId + "/geo-coverage-lookup").then(response => {
                    this.geoLookupData = response.data;
                }).catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
            }
        }

        constructor() {
            super();
        }
    };
