import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import ObjectDefinitions from "@/components/ObjectDefinitions.vue";
import ObjectRelations from "@/components/ObjectRelations.vue";
import ProductsIndex from "@/components/ProductsIndex.vue";
import ProductsCreate from "@/components/ProductsCreate.vue";
import ProductsEdit from "@/components/ProductsEdit.vue";
import PlatformsIndex from "@/components/PlatformsIndex.vue";
import ERDiagramVisualisation from "@/components/ERDiagramVisualistion.vue";
import PlatformsCreate from "@/components/PlatformsCreate.vue";
import PlatformsEdit from "@/components/PlatformsEdit.vue";
import ProductsSchemaVisualisation from "@/components/ProductsSchemaVisualisation.vue";
import Logout from "@/views/Logout.vue";
import store from "@/store";
import { RootState } from "../store/types";
import { Store } from "vuex";
import Users from "@/views/Users.vue";
import { UserRole } from "@/models/UserRole";
import NotAuthorised from "@/views/NotAuthorised.vue";
import NotAuthenticated from "@/views/NotAuthenticated.vue";
import NotFound from "@/views/NotFound.vue";
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";
import Admin from "@/components/Admin.vue";
import PassThrough from "@/components/PassThrough.vue";
import SubjectAreasIndex from '@/components/SubjectAreasIndex.vue'
import SubjectAreasCreate from '@/components/SubjectAreasCreate.vue'
import SubjectAreasEdit from '@/components/SubjectAreasEdit.vue'
import GraphEdges from '@/components/GraphEdges.vue'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        components: {
            default: Home,
            pageHeader: PageHeader, // separate components are setup so the header and footer can be changeable on specific pages if necessary
            pageFooter: Footer
        },
        meta: { authorise: [] }
    },
    {
        path: "/logout",
        name: "Logout",
        components: {
            default: Logout,
            pageHeader: PageHeader,
            pageFooter: Footer
        }
        // do not set authorize meta
    },
    {
        path: "/notauthorised",
        name: "NotAuthorised",
        components: {
            default: NotAuthorised,
            pageHeader: PageHeader,
            pageFooter: Footer
        }
        // do not set authorize meta
    },
    {
        path: "/notauthenticated",
        name: "NotAuthenticated",
        components: {
            default: NotAuthenticated,
            pageHeader: PageHeader,
            pageFooter: Footer
        }
        // do not set authorize meta
    },
    {
        path: "/notfound",
        name: "NotFound",
        components: {
            default: NotFound,
            pageHeader: PageHeader,
            pageFooter: Footer
        }
        // do not set authorize meta
    },
    {
        path: "/object/definitions",
        name: "ObjectDefinitions",
        components: {
            default: ObjectDefinitions,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        meta: { authorise: [] }
    },
    {
        path: "/object/relations",
        name: "ObjectRelations",
        components: {
            default: ObjectRelations,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        meta: { authorise: [] }
    },
    {
        path: "/object/graphEdges",
        name: "GraphEdges",
        components: {
            default: GraphEdges,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        meta: { authorise: [] }
    },
    {
        path: "/products",
        name: "ProductsIndex",
        components: {
            default: ProductsIndex,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
         meta: { authorise: [] }
    },
    {
        path: "/products/create",
        name: "ProductsCreate",
        components: {
            default: ProductsCreate,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        meta: { authorise: [] }
    },
    {
        path: "/products/:productId",
        name: "ProductsEdit",
        components: {
            default: ProductsEdit,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        props: {
            default: true
        },
        meta: { authorise: [] }
    },
    {
        path: "/products/:productId/visualiseschema",
        name: "ProductsSchemaVisualisation",
        components: {
            default: ProductsSchemaVisualisation,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        props: {
            default: true
        },
        meta: { authorise: [] }
    },

    {
        path: '/subjectareas',
        name: 'subjectareas-parent',
        components: {
            default: PassThrough,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        children:[{
            path: '',
            name: 'SubjectAreasIndex',
            components: {
                default: SubjectAreasIndex,
                pageHeader: PageHeader,
                pageFooter: Footer
            },
            meta: { authorise: [] }
        },
        {
            path: 'create',
            name: 'SubjectAreasCreate',
            components: {
                default: SubjectAreasCreate,
                pageHeader: PageHeader,
                pageFooter: Footer
            },
           
            meta: { authorise: [] }
        },
        {
            path: ':subjectAreaId',
            name: 'SubjectAreasEdit',
            components: {
                default: SubjectAreasEdit,
                pageHeader: PageHeader,
                pageFooter: Footer
            },
           
            props: {
                default: true
            },
            meta: { authorise: [] }
        },
        {
            path: ":subjectAreaId/ERdiagram",
            name: "SubjectAreaDiagramVisualisation",
            components: {
                default: ERDiagramVisualisation,
                pageHeader: PageHeader,
                pageFooter: Footer
            },
           meta: { authorise: [] } 
        }
 



        ],
        meta: { authorise: [] }
    },
   
    {
        path: '/admin',
        name: 'admin-parent',
        components: {
            default: PassThrough,
            pageHeader: PageHeader,
            pageFooter: Footer
        },
        children: [
            {
                path: "",
                name: "Admin",
                components: {
                    default: Admin,
                    pageHeader: PageHeader,
                    pageFooter: Footer
                },
                meta: { authorise: [UserRole.UserAdmin, UserRole.DataAdmin] }
            },
            {
                path: "users",
                name: "Users",
                components: {
                    default: Users,
                    pageHeader: PageHeader,
                    pageFooter: Footer
                },
                meta: { authorise: [UserRole.UserAdmin] }
            },
            {
                path: "platforms",
                name: "PlatformsIndex",
                components: {
                    default: PlatformsIndex,
                    pageHeader: PageHeader,
                    pageFooter: Footer
                },
                meta: { authorise: [UserRole.DataAdmin] }
            },
            {
                path: "platforms/create",
                name: "PlatformsCreate",
                components: {
                    default: PlatformsCreate,
                    pageHeader: PageHeader,
                    pageFooter: Footer
                },
                meta: { authorise: [UserRole.DataAdmin] }
            },
            {
                path: "platforms/:platformId",
                name: "PlatformsEdit",
                components: {
                    default: PlatformsEdit,
                    pageHeader: PageHeader,
                    pageFooter: Footer
                },
                props: {
                    default: true
                },
                meta: { authorise: [UserRole.DataAdmin] }
            },

        ],
        meta: { authorise: [UserRole.UserAdmin, UserRole.DataAdmin] }
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    const { authorise } = to.meta;
    // check if the route requires authorisation
    if (authorise) {
        // The login will redirect the user unless the account info is already available in localStorage
        store.dispatch("auth/login").then(() => {
            const currentUser = store.getters["auth/currentUser"];
            if (currentUser) {
                // check if route is restricted by user role
                if (
                    authorise.length &&
                    !currentUser.userRoleIds.some((u: number) => authorise.indexOf(u) !== -1)
                ) {
                    return next({ path: "/NotAuthorised" });
                } else {
                    // authorise used with an empty array will allow any user role but user must be authenticated
                    return next();
                }
            } else {
                // You can be authenticated with the API but NOT in the user list with an assigned role
                return next({ path: "/NotAuthenticated" });
            }
        });
    } else {
        // follow route without logging in
        return next();
    }
});

export default router;
