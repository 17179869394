import { GetterTree } from "vuex";
import { RootState } from "../types";
import { SchemaType } from "./types";
import { getParsedData } from "@/helpers/DMVHelpers";
export const getters: GetterTree<SchemaType, RootState> = {
    getData(state): { [t: string]: string | number }[] {
        return state.data;
    },
    getToaData(state): { [t: string]: string | number }[] {
        return state.toaData;
    },
    getMetaData(state): { [t: string]: string | number }[] {
        return state.metaData;
    },
    getFloatingData(state) {
        return state.floatingData;
    },

    getDimensions(state): any {
        const { dimensionTableGroups, factTableGroups } = getParsedData([...state.metaData]);
        return { dimensionTableGroups, factTableGroups };
    },
    getPositionsOfChildNodes(state): any {
        return state.childNodePositions;
    }
};
