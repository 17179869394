import Vue from 'vue'
import { IUser } from '@/interfaces/IUser'

export class UserService {
  async getUserById(id: number): Promise<IUser | null> {
    return await Vue.prototype.$axios.get('/api/users/' + id)
  }
  async getUserByEmail(email: string): Promise<IUser | null> {
    return await Vue.prototype.$axios.get('/api/users/by-email/' + email)
  }
}
