<template :key="currentKey">
    <div :style="{ 'z-index': 8,height:'300px',overflow:'scroll'}" :onClick="callParent">


        <v-rect
            @click="callParent"
            :style="{ zIndex: 10 }"
            :config="{
                x: positionsOfNodes[0].x,
                y: positionsOfNodes[0].y - 30,
                width: titleText.length > 47 ? titleText.length*7 : 300,
                height: 30,
                stroke: '#fff',
                fill: type === 'dimension' || type==='Dimension' ? '#B2AE36' : '#c76e1f'
            }"
        />

        <v-text
            @click="callParent"
            :style="{ zIndex: 10 }"
            :config="{
                text: titleText,
                x: positionsOfNodes[0].x,
                y: positionsOfNodes[0].y - 30,
                padding: 10,
                fill: '#fff'
            }"
        />

        <v-image
            @click="setShowFull"
            :config="{
                x: positionsOfNodes[0].x + (this.titleText.length > 47 ? this.titleText.length*7 -20 : 280),
                y: positionsOfNodes[0].y - 20,
                image: this.showFull ? this.caretUpImg : this.caretDownImg,
                height: 10,
                width: 10
            }"
        />


        <div v-for="(generalItem, index) in nonDuplicateItems" :key="index * 2">
            <div v-if="!generalItem.targetObjectAttributeName">
                <v-rect
                    :style="{ zIndex: 10 }"
                    :config="{
                          x: positionsOfNodes[index].x,
                        y: positionsOfNodes[index].y,
                        width: positionsOfNodes[index].width,
                       height:30,
                        stroke: '#fff',
                        fill: '#F0F0F0'
                    }"
                />
                <!-- color blocks for dimension tables -->
                <div  v-if="type !== 'fact'">
             
                <div
                    v-for="(item, indexOfItem) in getAllColorBlocks(generalItem, type)"
                    :key="indexOfItem * 3 + 'color'"
                >
                    <v-rect
                        :style="{ zIndex: 10 }"
                        :config="{
                            x:
                                positionsOfNodes[index].x +
                                (indexOfItem === 0 ? 0 : indexOfItem * 10),
                            y: positionsOfNodes[index].y + 10,
                            width: 10,
                            height: 10,
                            // stroke: '#fff',
                            fill: item?.colorCode,
                            padding: 3
                        }"
                    />
                </div>
            </div>

                <v-text
                    :style="{ zIndex: 10 }"
                
                    :config="{
                        x: positionsOfNodes[index].x + 30,
                        y: positionsOfNodes[index].y,
                        width: 300,
                     height:30,
                        wrap:'word',
                        text:
                            type === 'dimension'
                                ? generalItem.primaryAttributeName ||
                                  generalItem.targetObjectAttributeName
                                : generalItem.foreignAttributeName ||
                                  generalItem.targetObjectAttributeName,

                        fill: '#000',
                        padding: 10
                    }"
                    class="odd"
                />
            </div>
            <!-- TOA for dimension tables -->
            <div v-if="showFull && generalItem.targetObjectAttributeName">
                <v-rect
               
                    :style="{ zIndex: 10 }"
                    :config="{
                             x: positionsOfNodes[index].x,
                        y: positionsOfNodes[index].y,
                        width: titleText.length > 47 ? titleText.length*7 : 300,
                       height:30,
                        stroke: '#fff',
                        padding:20,
                        fill: '#F0F0F0'
                    }"
                />

                <v-text
                    :style="{ zIndex: 10 }"
                   
                    :config="{
                        x: positionsOfNodes[index].x - 5,
                        y: generalItem.targetObjectAttributeName.length>46?positionsOfNodes[index].y-10:positionsOfNodes[index].y,
                        width: titleText.length > 47 ? titleText.length*7 : 300 ,
                        height:generalItem.targetObjectAttributeName.length>46?45:30,
                        wrap:'word',
                        // ellipsis:true,
                        text: generalItem.targetObjectAttributeName,

                        fill: '#000',
                        padding: 10
                    }"
                    class="odd"
                />
            </div>

            <!-- color blocks for fact tables -->
            <div v-if="type === 'fact' && getAllColorBlocks(generalItem, 'fact').length <= 3">
            <div
                v-for="(item, indexOfItem) in getAllColorBlocks(generalItem, 'fact')"
                :key="indexOfItem * 3 + 'color'"
            >
            <!-- v-if="indexOfItem < 3" -->
                <v-rect
                    :style="{ zIndex: 10 }"
                    :config="{
                        x: positionsOfNodes[index].x + (indexOfItem === 0 ? 3 : indexOfItem * 10 + 3),
                        y: positionsOfNodes[index].y + 10,
                        width: 8,
                        height: 8,
                        // stroke: '#fff',
                        fill: item?.colorCode,
                        padding: 5
                    }"
                />
            </div></div>
            <v-image
                v-if="type === 'fact' && getAllColorBlocks(generalItem, 'fact').length > 3"
                :config="{
                    x: positionsOfNodes[index].x+13,
                    y: positionsOfNodes[index].y + 10,
                
                    ...imageConfig
                }"
            />
           
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { removeDuplicatesByKey } from "../helpers/DMVHelpers";
import logo from "../assets/MultiColorIcon.svg";
import caretUp from "../assets/arrow-up.svg";
import caretDown from "../assets/arrow-down.svg";
import progress from "../assets/Progress.svg";

export default {
    props: {
        data: [],
        titleText: String,
        position: { x: Number, y: Number },
        type: String,
        isItAFloating:Boolean,
    },
    data() {
        return {
            isItFirstToAppear:false,
            toaLoading: false,
            currentKey: 0,
            showFull: false,
            initialYValue: 0,
            testImg: new Image(10, 10),
            caretUpImg: new Image(10, 10),
            caretDownImg: new Image(10, 10),
            progressImg: new Image(10, 10),
            loadingToa: false,
            configKonva: {
                width: 400,
                height: 500
            },
            types: {
                Fact: "fact",
                Dimension: "dimension"
            },
            nonDuplicateItems: [],
            currentConfig: undefined,
            hasBeenMounted: false,
            positionsOfNodes: {},
            getSameItemsCount(item) {
                return this.data.filter(
                    (i) => i.primaryAttributeName === item.primaryAttributeName
                );
            }
        };
    },
    watch: {
        "$store.state.schema.toaData": function (value) {
     
            if (value.length > 1) {
                this.hasBeenMounted = true;
            }
        }
    },
    created() {
        if(!this.isItAFloating) {
        this.initialYValue = this.position.y;
        if (this.type == "dimension") {
            let repeatingKeysForOneFactTable = removeDuplicatesByKey(
                this.data,
                "foreignAttributeID"
            );
            repeatingKeysForOneFactTable = removeDuplicatesByKey(
                repeatingKeysForOneFactTable,
                "primaryAttributeName"
            );

            this.nonDuplicateItems = repeatingKeysForOneFactTable;
        } else {
            this.nonDuplicateItems = removeDuplicatesByKey(this.data, "foreignAttributeName");
        }

        if (this.type === "dimension") {
            const toaItemsForThisTable = this.getToaData.filter(
                (i) => i.targetObjectId === this.nonDuplicateItems[0].primaryObjectID
            );
            this.nonDuplicateItems = [...this.nonDuplicateItems, ...toaItemsForThisTable];
        } else {
            const toaItemsForThisTable = this.getToaData.filter(
                (i) => i.targetObjectId === this.nonDuplicateItems[0].foreignObjectID
            );
            this.nonDuplicateItems = [...this.nonDuplicateItems, ...toaItemsForThisTable];
        }

        this.nonDuplicateItems.map((i, index) => {
            const title =
                this.type === "dimension"
                    ? i?.primaryObjectName + i?.primaryAttributeName || i?.targetObjectAttributeName
                    : i?.foreignObjectName + i?.foreignAttributeName ||
                      i?.targetObjectAttributeName;
            const yUpdated =
                this.type === "dimension" ? this.initialYValue + 30 : this.position.y + index * 30;
            this.positionsOfNodes[index] = {
                title,
                x: this.position.x,
                y: yUpdated,
                width: this.titleText.length > 47 ? this.titleText.length*7 : 300,
                height: 30
            };
            this.initialYValue +=30;
        });
    }else{
        this.positionsOfNodes[0]={
            x:this.position.x,
            y:this.position.y
        }
    }
    },
    computed: {
        //not being used yet
        getHeightOfItem() {
            return(generalItem)=>{
            const text= this.type === 'dimension'
                     ? generalItem.primaryAttributeName ||
                       generalItem.targetObjectAttributeName
                     : generalItem.foreignAttributeName ||
                       generalItem.targetObjectAttributeName;                     
            return text.length>50?70:30;
}
},
        expandCollapseImageConfig() {
            return {
                image: this.loadingToa ? this.progressImg : this.caretDownImg,
                height: 10,
                width: 10
            };
        },
        imageConfig() {
            return {
                image: this.testImg,
                height: 10,
                width: 10
            };
        },
        getNodes() {
            return this.$store.getters["schema/getPositionsOfChildNodes"];
        },
        getMetadata() {
            return this.$store.getters["schema/getMetaData"];
        },
        getToaData() {
            return this.$store.getters["schema/getToaData"];
        }
    },
    mounted() {
        this.testImg.src = logo;
        this.caretUpImg.src = caretUp;
        this.caretDownImg.src = caretDown;
        this.progressImg.src = progress;

        this.initialYValue = this.position.y;
        try {
            store.dispatch("schema/addPositionsOfChildNodes", {
                [this.titleText]: { x: this.position.x, y: this.position.y }
            });
        } catch (e) {
            console.error(e);
        }
        if(!this.isItAFloating){
        if (this.type == "dimension") {
            let repeatingKeysForOneFactTable = removeDuplicatesByKey(
                this.data,
                "foreignAttributeID"
            );
            repeatingKeysForOneFactTable = removeDuplicatesByKey(
                repeatingKeysForOneFactTable,
                "primaryAttributeName"
            );

            this.nonDuplicateItems = repeatingKeysForOneFactTable;
        } else {
            this.nonDuplicateItems = removeDuplicatesByKey(this.data, "foreignAttributeName");
        }
        let toaItemsForThisTable;
        if (this.type === "dimension") {
            toaItemsForThisTable = this.getToaData.filter(
                (i) => i.targetObjectId === this.nonDuplicateItems[0].primaryObjectID
            );
        } else if (this.type === "fact") {
            toaItemsForThisTable = this.getToaData.filter(
                (i) => i.targetObjectId === this.nonDuplicateItems[0].foreignObjectID
            );
        }
        const nonToaItems = removeDuplicatesByKey(
            toaItemsForThisTable,
            "targetObjectAttributeName"
        );
        nonToaItems.map((item) => {
            if (this.type === "dimension") {
                if (
                    this.nonDuplicateItems.filter(
                        (i) => i.primaryAttributeName === item.targetObjectAttributeName
                    )?.length <= 0
                ) {
                    this.nonDuplicateItems = [...this.nonDuplicateItems, item];
                }
            } else {
                if (
                    this.nonDuplicateItems.filter(
                        (i) => i.foreignAttributeName === item.targetObjectAttributeName
                    )?.length <= 0
                ) {
                    this.nonDuplicateItems = [...this.nonDuplicateItems, item];
                }
            }
        });

        this.nonDuplicateItems?.map((generalItem, index) => {
            const title =
                this.type === "dimension"
                    ? generalItem?.primaryAttributeName + generalItem?.primaryObjectName
                    : generalItem.foreignAttributeName +
                      generalItem?.foreignObjectName +
                      generalItem?.foreignAttributeID;

            if (!this.getNodes[title]) {
                const yUpdated =
                    this.type === "dimension"
                        ? this.initialYValue + 30
                        : this.position.y + index * 30;
                store.dispatch("schema/addPositionsOfChildNodes", {
                    [title]: { x: this.position.x, y: yUpdated }
                });
            }
            this.initialYValue += 30;
        });
    }
    },
    methods: {
        callParent() {
            this.$emit('show-fact-relations',this.data)
        },
        isFirst() {
            if(this.isItFirstToAppear){
                return true;
            }else{
                this.isItFirstToAppear=true;
                return false;
            }

        },
        putPositionsOfNonMetaDataItems(targetObjectId) {
            let length = this.nonDuplicateItems.length>=1?this.nonDuplicateItems.length - 1:0;
            let yUpdated = this.positionsOfNodes[length].y ?? 30;
            const xPosition = this.positionsOfNodes[length].x;
            const items = this.getToaData[targetObjectId];
            
                    items?.map((item) => {

                     if(this.nonDuplicateItems.filter(i=>i.targetObjectAttributeName===item.targetObjectAttributeName)?.length<=0){

                  
                        if (this.type === "dimension") {
                            if (
                                this.nonDuplicateItems.filter(
                                    (i) => i.primaryAttributeName === item.targetObjectAttributeName
                                )?.length <= 0
                            ) {
                                // if(this.nonDuplicateItems.filter(i=>i.targetObjectAttributeName===item.targetObjectAttributeName)?.length<=0){

                                    this.nonDuplicateItems = [...this.nonDuplicateItems, item];
                                
                            }
                        } else {
                            if (
                                this.nonDuplicateItems.filter(
                                    (i) => i.foreignAttributeName === item.targetObjectAttributeName
                                )?.length <= 0
                            ) {
                                this.nonDuplicateItems = [...this.nonDuplicateItems, item];
                            }
                        }
                        const title = item?.targetObjectAttributeName;
                        //const yUpdated =this.positionOfNodes[length]?.y
                        this.positionsOfNodes[length + 1] = {
                            title,
                            x: xPosition,
                            y: yUpdated + 30,
                            width: this.titleText.length > 50 ? 400 : 300,
                            height: 30
                        };
                     
                        length++;
                        yUpdated += 30; 
                    }
                    });
                    this.currentKey++;
               },
  
        async setShowFull() {
            this.toaLoading = true;
            let length = this.nonDuplicateItems.length>=1?this.nonDuplicateItems.length - 1:0;
            let yUpdated = this.positionsOfNodes[length].y ?? 30;
            const xPosition = this.positionsOfNodes[length].x;
            let targetObjectId=0;
            //check if the toa nodes exist already
            if(this.isItAFloating){
                targetObjectId=this.data.targetObjectId;
            }else{
               
        
                targetObjectId =
                this.type === "dimension"
                    ? this.data[0].primaryObjectID
                    : this.data[0].foreignObjectID;    }
            if (!this.getToaData[targetObjectId]) {
         
                store.dispatch("schema/setStarSchemaAttributesForTargetObject", targetObjectId).then(() => {
                    this.putPositionsOfNonMetaDataItems(targetObjectId)
                });
            }else{
                this.putPositionsOfNonMetaDataItems(targetObjectId)
            }
            this.toaLoading = true;
            this.showFull = !this.showFull;
           
        },
        getAllColorBlocks(item, type = "dimension") {
            let items;
            if (type !== "dimension") {
                items = this.getMetadata.filter(
                    (i) =>
                        i.foreignAttributeName === item.foreignAttributeName &&
                        i.foreignObjectName === item.foreignObjectName
                );
            } else {
                items = this.getMetadata.filter(
                    (i) =>
                        i.primaryAttributeName === item.primaryAttributeName &&
                        i.primaryObjectName === item.primaryObjectName
                );
            }

            return removeDuplicatesByKey(items, "colorCode");
        },

        getConfig(generalItem, index) {
            return this.getConfigFunction(generalItem, index);
        }
    }
};
</script>
