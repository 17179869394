import { Component, Watch, Vue } from "vue-property-decorator";
import {
    downloadURI,
    getParsedData,
    ZoomInZoomOut,
    removeDuplicatesByKey,
    attachWindowResizer,
    getHeighAndWidthOfWindow,
    getData,
    getUpdatedDateHelper,
    GetPointsForArrows
} from "@/helpers/DMVHelpers";
import { ERDBase } from "./ERDBase";

/*
This class is mostly used for all the calculations for the ERDiagram Tool
*/

export class ERDBaseMain extends ERDBase {
    public myStyles: any;
    getDimensionsWithConfig() {
        const { width, height } = getHeighAndWidthOfWindow();
        this.setDimensionsForWindow();
        this.configKonva.width = width;
        this.configKonva.height = height;
    }

    setDimensionsForWindow() {
        const { width, height } = getHeighAndWidthOfWindow();

        this.windowHeight = `${height - 180}px`;
        this.windowWidth = `${width}px`;
        return this.windowHeight;
   
    }
    async getFloatingObjects() {
        await Vue.prototype.$axios
            .get(`/api/subjectareas/${this.$route.params.subjectAreaId}/object-no-relation`)
            .then((response: any) => {
                this.$store.commit("schema/setFloatingData", response.data);
                this.floatingTablesDataLength = response.data.length;
                this.showTables = true;
                this.showTables = true;
            });
    }
    @Watch("$store.state.schema.childNodePositions")
    setArrowsAsTrue() {
        const item = this.$store.state.schema.childNodePositions;
        if (Object.keys(item).length >= this.$store.getters["schema/getMetaData"].length) {
            this.data.map((item) => this.getConfigForArrows(item));
            this.showArrows = true;
        }
    }

    getFactItemsForHighlight({ highlightItems, type }: { highlightItems: any; type: string }) {
        let data;

        if (highlightItems.primaryObjectName === highlightItems.foreignObjectName) {
            if (type === "fact") {
                data = this.factTables[highlightItems.foreignObjectName]?.filter(
                    (i: any) => i.primaryObjectName === highlightItems.primaryObjectName
                );
            } else {
                data = this.dimensionTables[highlightItems.primaryObjectName];
            }
        } else {
            if (type === "fact") {
                if (this.factTables[highlightItems.foreignObjectName]) {
                    data = this.factTables[highlightItems.foreignObjectName]?.filter(
                        (i: any) => i.primaryObjectName === highlightItems.primaryObjectName
                    );
                } else {
                    data = this.dimensionTables[highlightItems.foreignObjectName].filter(
                        (i: any) => i.primaryObjectName === highlightItems.primaryObjectName
                    );
                }
            } else {
                data = this.dimensionTables[highlightItems.primaryObjectName]?.filter(
                    (i: any) => i.foreignObjectName === highlightItems.foreignObjectName
                );
            }
        }
        this.factItemsForHighlight[
            highlightItems.primaryObjectName +
                highlightItems.foreignObjectName +
                highlightItems.foreignObjectID +
                highlightItems.primaryObjectID
        ] = data;
        return data;
    }
    getPointsForHighlight(highlightItem: any) {
        if (highlightItem.primaryObjectName && highlightItem.foreignObjectName) {
            const points: any[] = [];


            Object.keys(this.factHashesForFloating).map((item: any, index: number) => {
                points.push(this.factHashesForFloating[item]);
            });
            let finalPoints;
            if (points) {
                const name = Object.keys(this.factHashesForFloating)[0];
                const { primaryObjectName, foreignObjectName } = highlightItem;
                if (primaryObjectName === foreignObjectName) {
                    const points = this.factHashesForFloating[name];
                    finalPoints = [
                        points.x,
                        points.y,
                        points.x - 20,
                        points.y,
                        points.x - 20,
                        points.y + 30,
                        points.x,
                        points.y + 30
                    ];
                } else {
                    finalPoints = [
                        points[0]?.x,
                        points[0]?.y,
                        points[0]?.x - 20,
                        points[0]?.y,
                        points[0]?.x - 20,
                        points[1]?.y,
                        points[1]?.x,
                        points[1]?.y
                    ];
                }
            }

            return finalPoints;
        }
        return;
    }
    getPosition({
        type,
        associatedfactTable,
        item,
        y = 40,
        isItFloating = false,
        isItAHighlight = false,
        key
    }: {
        type: string;
        associatedfactTable?: string;
        item: string;
        y: number;
        isItFloating: boolean;
        isItAHighlight: boolean;
        key: string;
    }) {
        let x: number, hash;

       if(this.positions[item]){
        return this.positions[item]
       }
      
      

        if (isItFloating || isItAHighlight) {
            if (isItFloating) {
                x = 20;
                y = 50;
            } else {
                x = 80;
                y = 100;
            }
            hash = this.factHashesForFloating;
            while (hash[x + y]) {
                if (isItAHighlight) {
                    y += 150;
                } else {
                    y += 50;
                }
            }
        } else {
            hash = this.factHashes;
            x = this.factPositions.x;
            y = this.factPositions.y;

            if (!isItFloating && Object.keys(this.positions).length > 5) {
                this.configKonva.height += 175;
            }
            //get node positions and check if there already is a item on that y position
            if (this.lastDetails) {
                const finalPosition = this.checkIfSelfReference(x);
                if (finalPosition) {
                    y = finalPosition;
                    // Object.keys(this.positions).map(item=>{
                    //     if(this.positions[item].y===y){
                    //         y+=100;
                    //     }
                    // })
                    // Object.keys(this.positions).map(item=>{
                    //     if(this.positions[item].y===y){
                    //         y+=100;
                    //     }
                    // })
                }
            }
        }
        // get all fact and dimension tables length
        if (!isItFloating && !isItAHighlight) {
            const totalTables =
                Object.keys(this.factTables).length + Object.keys(this.dimensionTables).length;
            //get the nearest sqrt
            const value = Number(Math.sqrt(totalTables).toFixed(0));
           

            if (this.numberOfTablesPerColumn > value) {
                this.numberOfTablesPerColumn = 1;
                y = 50;
                this.factPositions.y = 50;
                x = x + 500;
                this.factPositions.x = this.factPositions.x + 500;
                this.configKonva.width += 400;
                this.factPositions.index += 1;
            } else {
                this.numberOfTablesPerColumn++;
            }
        }

        if (isItAHighlight || isItFloating) {
            hash[x + y] = { x: x, y: y };
        } else {
            hash[x + y + this.factPositions.index] = { x: x, y: y };
        }
        const data = { x: x, y: y };
     
        this.positions[item]=data;

       
        this.lastDetails = {
            type: type,
            x: x,
            y: y,
            item: item
        };

        return data;
    }

    checkIfSelfReference(x: any) {
        return this.checkIfPositionExists(x);
    }
    checkIfPositionExists(currentX: number) {
        if (this.lastDetails !== undefined) {
            const { x, type, item } = this.lastDetails;
            let { y } = this.lastDetails;
            if (type === "fact") {
                //get items associated with this
                let items = this.factTables[item];
                if (items) {
                    const isThereASelfReferece = items.filter(
                        (i: any) => i.foreignObjectName === i.primaryObjectName
                    );
                    if (type == "dimension" && isThereASelfReferece.length <= 0) {
                        items = removeDuplicatesByKey(items, "foreignAttributeName");
                    }
                    items.map((item: any, index: any) => {
                        if (index == 0) {
                            y += 90;
                        } else {
                            y += 35;
                        }
                    });
                }
            } else if (type === "dimension") {
                let items = this.dimensionTables[item];
                const isThereASelfReferece = items.filter(
                    (i: any) => i.foreignObjectName === i.primaryObjectName
                );
                if (type === "dimension" && isThereASelfReferece.length <= 0) {
                    items = removeDuplicatesByKey(items, "primaryAttributeName");
                }
                items.map((item: any, index: any) => {
                    if (index == 0) {
                        y += 90;
                    } else {
                        y += 35;
                        y += 35;
                    }
                });
            }     

            return y;
        }
    }

    getDate() {
        this.lastDate = getUpdatedDateHelper(this.data, "orCreationDate");
        return getUpdatedDateHelper(this.data, "orCreationDate");
    }

    getUniqueCircle(key: string) {
        return this.uniqueHashMapForCircles[key];
    }
    getPositionsOfNodes(item: any) {
        const data = this.$store.getters["schema/getPositionsOfChildNodes"];
        return data[item];
    }

    getCurrentArrow(item: any) {
        const fact =
            item?.foreignAttributeName + item?.foreignObjectName + item?.foreignAttributeId;

        let targetItemPosition;
        if (item.primaryObjectName === item.foreignObjectName) {
            targetItemPosition = this.getPositionsOfNodes(
                item.primaryAttributeName + item.primaryObjectName + item.primaryAttributeId
            );
        } else {
            targetItemPosition = this.getPositionsOfNodes(
                item?.primaryAttributeName + item?.primaryObjectName
            );
        }

        const factItemPosition = this.getPositionsOfNodes(
            item?.foreignAttributeName + item?.foreignObjectName + item?.foreignAttributeId
        );
        let points;

        if (factItemPosition && targetItemPosition) {
            const title = item.foreignObjectName;
            if (factItemPosition?.x > targetItemPosition?.x) {
 
                if (factItemPosition.y > targetItemPosition?.y + 100) {

                    //p1
                    points = GetPointsForArrows(factItemPosition, targetItemPosition, "p1", item);
                } else {
               
                    if (factItemPosition.x && factItemPosition.y) {
                        //p2
                        points = GetPointsForArrows(
                            factItemPosition,
                            targetItemPosition,
                            "p2",
                            item
                        );
                    }
                }
            } else if (factItemPosition?.x < targetItemPosition?.x) {
     
                if (factItemPosition?.y > targetItemPosition?.y + 100) {
       
                    //p3
                    points = GetPointsForArrows(factItemPosition, targetItemPosition, "p3", item);
                } else {
          
                    if (factItemPosition?.x && factItemPosition?.y) {
                        //p4
                        points = GetPointsForArrows(
                            factItemPosition,
                            targetItemPosition,
                            "p4",
                            item
                        );
                    }
                }
            } else if (factItemPosition?.x === targetItemPosition?.x) {

                if (factItemPosition?.y > targetItemPosition?.y) {
                    //p5
                    points = GetPointsForArrows(factItemPosition, targetItemPosition, "p5", item);
                } else if (factItemPosition?.y === targetItemPosition?.y) {
                    //get the child elements position that have the relationship in between
                    //get the object
                    const targetedObject =
                        this.dimensionToFactAssociatedHash[item.primaryObjectName];

                    //get the keys that have the relationship in them
                    if (this.factTables && targetedObject && this.factTables[targetedObject[0]]) {
                        const items = this.factTables[targetedObject[0]];

                        const childElementPosition = this.getPositionsOfNodes(
                            items[0]?.primaryAttributeName +
                                items[0]?.primaryObjectName +
                                items[0]?.foreignAttributeId
                        );
                        const parentElementPosition = this.getPositionsOfNodes(
                            items[0]?.foreignAttributeName +
                                items[0]?.foreignObjectName +
                                items[0]?.foreignAttributeId
                        );

                        if (parentElementPosition?.y > childElementPosition?.y) {
                            points = [
                                parentElementPosition.x,
                                parentElementPosition.y,
                                childElementPosition.x,
                                childElementPosition.y
                            ];
                        } else {
                            if (
                                childElementPosition?.x &&
                                childElementPosition?.y &&
                                parentElementPosition?.x &&
                                parentElementPosition?.y
                            ) {
                                points = [
                                    childElementPosition.x - 6,
                                    childElementPosition.y,
                                    childElementPosition.x - 50,
                                    childElementPosition.y,
                                    childElementPosition.x - 50,
                                    parentElementPosition.y,
                                    parentElementPosition.x,
                                    parentElementPosition.y
                                ];
                            }
                        }
                    }
                }} else {
                    //p6
                    points = GetPointsForArrows(factItemPosition, targetItemPosition, "p6", item);
                }
            } else {
                //p7
                points = GetPointsForArrows(factItemPosition, targetItemPosition, "p7", item);
            }  

        return {
            points: points,
            fill: item.colorCode,
            stroke: item.colorCode,
            strokeWidth: 4,
            pointerWidth: 12,
            pointerLength: 12,
            dash: [8, 8],
            dashEnabled: item.relationCertifiedFlag===false,
        };
    }
    removeDuplicatesForTables(data: any, type: string) {
        let nonDuplicateItems;
        if (type == "dimension") {
            let repeatingKeysForOneFactTable = removeDuplicatesByKey(data, "foreignAttributeId");
            repeatingKeysForOneFactTable = removeDuplicatesByKey(
                repeatingKeysForOneFactTable,
                "primaryAttributeName"
            );

            nonDuplicateItems = repeatingKeysForOneFactTable;
        } else {
            nonDuplicateItems = removeDuplicatesByKey(data, "foreignAttributeName");
        }
        return nonDuplicateItems;
    }
    getConfigForArrows(item: any) {
        const dt = getParsedData(this.data);
        const dataNew = getData(dt.factTableGroups, dt.dimensionTableGroups);

        if (
            dataNew["dimensionTableGroups"][item.primaryObjectName] &&
            dataNew["factTableGroups"][item.foreignObjectName]
        ) {
            const dimensionTableData = dataNew["dimensionTableGroups"][
                item.primaryObjectName
            ].filter((i: any) => i.primaryObjectId === item.primaryObjectId);
            const factTableData = dataNew["factTableGroups"][item.foreignObjectName].filter(
                (i: any) => i.foreignObjectId === item.foreignObjectId
            );

            const removedDuplicatedFact = this.removeDuplicatesForTables(factTableData, "fact");
            const removedDuplicatedDimension = this.removeDuplicatesForTables(
                dimensionTableData,
                "dimension"
            );
            if (removedDuplicatedFact?.length === removedDuplicatedDimension.length) {
                const value =
                    item.primaryObjectName +
                    item.foreignObjectName +
                    item.foreignObjectId +
                    item.primaryObjectId;

                const testValue =
                    item.primaryObjectName + item.foreignObjectName + item.foreignObjectId;

                if (!(testValue in this.uniqueHashMapForArrows)) {
                    const arrowConfig = this.getCurrentArrow(item);
                    this.uniqueHashMapForArrows[value] = arrowConfig;
                    const valueForCircle =
                        item.primaryObjectName +
                        item.foreignObjectName +
                        item.foreignObjectId +
                        item.primaryObjectId;
                    if (this.uniqueHashMapForArrows[value]?.points) {
                        this.uniqueHashMapForCircles[valueForCircle] = {
                            x: this.uniqueHashMapForArrows[value]?.points[0],
                            y: this.uniqueHashMapForArrows[value]?.points[1],
                            radius: 6.5,
                            stroke: "#fff",
                            fill: this.getCurrentArrow(item)?.fill,
                            zIndex: 81
                        };
                    }
                } else {
                    this.uniqueHashMapForArrows[value];
                }
            } else {
                const value =
                    item.primaryObjectName +
                    item.foreignObjectName +
                    item.foreignObjectId +
                    item.primaryObjectId;

                this.uniqueHashMapForArrows[value] = this.getCurrentArrow(item);
                if (this.uniqueHashMapForArrows[value]?.points) {
                    this.uniqueHashMapForCircles[value] = {
                        x: this.uniqueHashMapForArrows[value]?.points[0],
                        y: this.uniqueHashMapForArrows[value]?.points[1],
                        radius: 6.5,
                        stroke: "#fff",
                        fill: this.getCurrentArrow(item)?.fill,
                        zIndex: 81
                    };
                }
            }
        } else {
            const value =
                item.primaryObjectName +
                item.foreignObjectName +
                item.foreignObjectId +
                item.primaryObjectId;

            this.uniqueHashMapForArrows[value] = this.getCurrentArrow(item);
            if (this.uniqueHashMapForArrows[value] && this.uniqueHashMapForArrows[value]?.points) {
                this.uniqueHashMapForCircles[value] = {
                    x: this.uniqueHashMapForArrows[value]?.points[0],
                    y: this.uniqueHashMapForArrows[value]?.points[1],
                    radius: 6.5,
                    stroke: "#fff",
                    fill: this.getCurrentArrow(item)?.fill,
                    zIndex: 81
                };
            }
        }
    }

    getCorrectTypeForFilteredItems(itemForFilteredItems: any, type: string) {
        switch (type) {
            case "PrimaryElementsForFilteredItems":
                return (
                    itemForFilteredItems.primaryAttributeName +
                    itemForFilteredItems.primaryObjectName +
                    itemForFilteredItems.primaryAttributeId
                );
            case "DestinationElementsForFilteredItems":
                return (
                    itemForFilteredItems.primaryAttributeName +
                    itemForFilteredItems.primaryObjectName
                );
            case "ForeignElementsForFilteredItems":
                return (
                    itemForFilteredItems.foreignAttributeName +
                    itemForFilteredItems.foreignObjectName +
                    itemForFilteredItems.foreignAttributeId
                );
            case "default":
                return;
        }
    }

    showHighlightedLeave(event: any, item: any, fillColor: string, strokeColor: string) {
        event.target.fill(fillColor);
        event.target.stroke(strokeColor);
        event.target.strokeWidth(4);
        this.showHighlight = false;
        this.highlightItems = {};
        this.showHighlightSourceConfig = {};
        this.showHighlightTargetConfig = {};
        this.isHighlightVisible = false;
        this.positionOfHighlightElements = [];
        this.highlightArrowsItems = [];
    }

    //used to show highlight entering
    showHighlightedEnter(
        event: any,
        item: any,
        fillColor: string,
        strokeColor: string,
        leaveColor: string
    ) {
        // let dataNew = getParsedData(this.data);
        const isTheItemSameAsPrevious =
            item.foreignObjectName +
                item.foreignAttributeName +
                item.primaryObjectName +
                item.primaryAttributeName ===
            this.prevItem?.foreignObjectName +
                this.prevItem?.foreignAttributeName +
                this.prevItem?.primaryObjectName +
                this.prevItem?.primaryAttributeName;
        if (isTheItemSameAsPrevious) {
            this.showHighlightedLeave(
                this.prevEvent,
                this.prevItem,
                this.prevColor,
                this.prevColor
            );
            this.prevItem = null;

            return;
        }
        if (this.prevEvent !== null && this.prevItem !== null && this.prevColor !== null) {
            this.showHighlightedLeave(
                this.prevEvent,
                this.prevItem,
                this.prevColor,
                this.prevColor
            );
        }
        const primaryAttribute = item.primaryObjectName;
        const foreignAttribute = item.foreignObjectName;
        const filteredForeignElements = this.data.filter(
            (i) =>
                i.primaryObjectName === primaryAttribute && i.foreignObjectName === foreignAttribute
        );

        //changed
        this.highlightItems = {
            primaryObjectName: primaryAttribute,
            primaryObjectID: item.primaryObjectId,
            foreignObjectID: item.foreignObjectId,
            foreignObjectName: foreignAttribute
        };
        let targetItemPosition;
        let factItemPosition;
        filteredForeignElements.map((itemForFilteredItems, _) => {
            const PrimaryElementsForFilteredItems = this.getCorrectTypeForFilteredItems(
                itemForFilteredItems,
                "PrimaryElementsForFilteredItems"
            );
            const DestinationElementsForFilteredItems = this.getCorrectTypeForFilteredItems(
                itemForFilteredItems,
                "DestinationElementsForFilteredItems"
            );
            const ForeignElementsForFilteredItems = this.getCorrectTypeForFilteredItems(
                itemForFilteredItems,
                "ForeignElementsForFilteredItems"
            );

            let destinationPosition;
            if (itemForFilteredItems.primaryObjectName === itemForFilteredItems.foreignObjectName) {
                destinationPosition = this.getPositionsOfNodes(PrimaryElementsForFilteredItems);
                factItemPosition = this.getPositionsOfNodes(ForeignElementsForFilteredItems);
            } else {
                destinationPosition = this.getPositionsOfNodes(DestinationElementsForFilteredItems);
                factItemPosition = this.getPositionsOfNodes(DestinationElementsForFilteredItems);
            }
            targetItemPosition = this.getPositionsOfNodes(ForeignElementsForFilteredItems);

            this.positionOfHighlightElements = [
                ...this.positionOfHighlightElements,
                {
                    xPositionOfSource: targetItemPosition.x,
                    yPositionOfSource: targetItemPosition.y,
                    xPositionOfDestination:
                        targetItemPosition?.x > factItemPosition?.x &&
                        targetItemPosition?.y < factItemPosition?.y
                            ? destinationPosition.x + 300
                            : destinationPosition.x,
                    yPositionOfDestination:
                        targetItemPosition?.y < factItemPosition?.y
                            ? targetItemPosition?.x > factItemPosition?.x &&
                              targetItemPosition.y < factItemPosition.y
                                ? destinationPosition.y + 20
                                : targetItemPosition.x < factItemPosition.x
                                ? destinationPosition.y + 20
                                : destinationPosition.y
                            : destinationPosition.y,
                    width: this.getWidthForTable(item)
                },
                {
                    xPositionOfSource: factItemPosition.x,
                    yPositionOfSource:
                        itemForFilteredItems.primaryObjectName ===
                        itemForFilteredItems.foreignObjectName
                            ? destinationPosition.y
                            : factItemPosition.y,
                    width: this.getWidthForTable(item)
                }
            ];
        });

        this.factHashesForFloating = [];
        this.isHighlightVisible = true;
        this.showHighlight = true;
        event.target.fill(fillColor);
        event.target.stroke(strokeColor);
        event.target.strokeWidth(5);
        event.target.moveToTop();
        this.prevEvent = event;
        this.prevItem = item;
        this.prevColor = leaveColor;
    }

    getWidthForTable(item: any) {
        return item.primaryObjectName.length > 47 ? item.primaryObjectName.length * 7 : 300;
    }
}
