import { IDataObjectListItem } from '@/interfaces/IDataObjectListItem'

export class ProductMeasureGroupListItem implements IDataObjectListItem {
    id: number;
    productId: number;
    //productName: string;
    measureGroupId: number;
    measureGroupName: string;
    loadDate: string;
    loadDateText: string;
    targetObjectList: string;
}
