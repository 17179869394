
    import { Component, Vue, Prop, Watch, InjectReactive } from 'vue-property-decorator'
    import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId'
    import { ObjectRelationAttribute } from '@/models/ObjectRelationAttribute';

    @Component
    export default class ObjectRelationAttributeRow extends Vue {
        @Prop()
        public item: ObjectRelationAttribute;
        @Prop()
        public isConstantYn: boolean;

        @InjectReactive()
        public primaryAttributeLookup: Array<DropdownOptionIntegerId>;

        @InjectReactive()
        public foreignAttributeLookup: Array<DropdownOptionIntegerId>;

        @InjectReactive()
        public objectRelationAttributes: Array<ObjectRelationAttribute>;

        private foreignIdsToRemove: Array<number> = [];


        @Watch("objectRelationAttributes", { deep: true })
        private setForeignIds() {
            this.foreignIdsToRemove = this.objectRelationAttributes.map(i => { return i.foreignTargetObjectAttributeId; }).filter(y => y != this.item.foreignTargetObjectAttributeId);
            return this.item.isConstantYn;
        }

        get filteredForeignAttributeLookup() {
            return this.foreignAttributeLookup.filter(x => !this.foreignIdsToRemove.includes(x.id));
        }

        created() {
            // initially set the filtering incase watched array has not changed in size and does not run when opening edit screen
            this.setForeignIds();
        }
    };
