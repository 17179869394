<template :key="currentKey">
    <div  class="mainArea" v-if="data!==undefined">
      <v-rect
            @click="callParent"
            :style="{ zIndex: 10 }"
            :config="{
                x: positionsOfNodes[0].x,
                y: positionsOfNodes[0].y - 40,
                width: titleText.length <40 ?  300 : titleText.length*8,
                height: 40,
                stroke: '#fff',
                fill: getType()
            }"
        />

        <v-text
        v-if="!isItAFloating"
            @click="callParent"
            :style="{ zIndex: 10 }"
            :config="{
               
                text: titleText + `${generalType==='dimension' ? ` [${data[0].primaryObjectId}] ` : ` [${data[0].foreignObjectId}] `}`,
                x: positionsOfNodes[0].x,
                y: positionsOfNodes[0].y - 40,
                padding: 10,
                fill: '#fff'
            }"
        />
        <v-text v-else
        :style="{ zIndex: 10 }"
            :config="{
                text: titleText,
                x: positionsOfNodes[0].x,
                y: positionsOfNodes[0].y - 40,
                padding: 10,
                fill: '#fff',
       
            }"
        />
        <v-text
        :style="{ zIndex: 10 }"
            :config="{
                text: subtitleText,
                x: positionsOfNodes[0].x,
                y: positionsOfNodes[0].y-25,
                padding: 10,
                fill: '#fff',
                fontSize:10

            }"
        />

        <v-image
            v-if="!isItAHighlight"
            @click="setShowFull"
            :config="{
                x: positionsOfNodes[0].x + (this.titleText.length < 40 ? 280 : this.titleText.length*8 -20),
                y: positionsOfNodes[0].y - 20,
                image: this.showFull ? this.caretUpImg : this.caretDownImg,
                height: 10,
                width: 10
            }"
        />


        <div v-for="(generalItem, index) in nonDuplicateItems" :key="index * 2">
            <div v-if="!generalItem.targetObjectAttributeName" >
                <v-rect
                    :style="{ zIndex: 12 }"
                    :config="{
                          x: positionsOfNodes[index].x,
                        y: getHeight(generalItem)>=45?index===0?positionsOfNodes[index].y:positionsOfNodes[index].y:positionsOfNodes[index].y,
                        width: positionsOfNodes[index].width,
                       height:getHeight(generalItem),
                        stroke: '#fff',
                        fill: '#F0F0F0',
                        zIndex:99
                    }"
                />
             

                <v-text
                    :style="{ zIndex: 12 }"
                    :config="{
                            zIndex:99,
                            x: positionsOfNodes[index].x + 30,
                            y:getHeight(generalItem)>=45?index===0?getYForIndex(generalItem,index):getYForIndex(generalItem,index)+(index*2):getYForIndex(generalItem,index),
                            //y: generalType==='dimension'?generalItem.primaryAttributeName.length>46?positionsOfNodes[index].y-7:positionsOfNodes[index].y:generalItem.foreignAttributeName.length>46?positionsOfNodes[index].y-7:positionsOfNodes[index].y,
                            width:positionsOfNodes[index].width-30,
                            height:getHeight(generalItem),
                            wrap:'word',
                            // ellipsis:true,
                            text:getText(generalItem),

                            fill: '#000',
                            padding: getHeight(generalItem)>=40?8:10
                    }"
                    class="odd"
                />
                <v-image
                v-if="isThisAPrimaryKey(generalItem) && !isItAHighlight"
            :config="{
                x: positionsOfNodes[index].x +(titleText.length<40?270:((titleText.length*8)-40)),
                y: positionsOfNodes[index].y+10,
                image: primaryKeyImg,
                height: 10,
                width: 10
            }"
        />
            </div>

            <div v-if="showFull && generalItem.targetObjectAttributeName">
                <v-rect
                    v-if="showFull"
                    :style="{ zIndex: 12 }"
                    :config="{
                             x: positionsOfNodes[index].x,
                        y: positionsOfNodes[index].y,
                        width:  index===0?300:positionsOfNodes[index].width,
                       height:30,
                        stroke: '#fff',
                        padding:20,
                        fill: '#F0F0F0'
                    }"
                />

                <v-text
                    :style="{ zIndex: 12 }"
                   
                    :config="{
                        x: positionsOfNodes[index].x - 5,
                        y: generalItem.targetObjectAttributeName.length>46?positionsOfNodes[index].y-10:positionsOfNodes[index].y,
                        width:  positionsOfNodes[index].width ,
                        height:generalItem.targetObjectAttributeName.length>46?45:30,
                        wrap:'word',
                        // ellipsis:true,
                        text: generalItem.targetObjectAttributeName,

                        fill: '#000',
                        padding: 10
                    }"
                    class="odd"
                />
            </div>
            
            <!-- color blocks -->
            <div v-if="!isItAHighlight">
       
            <div
                v-for="(item, indexOfItem) in getAllColorBlocks(generalItem, generalType)"
                :key="indexOfItem * 3 + 'color'">
           <div   v-if="indexOfItem < 3">

               <v-rect
                   :style="{ zIndex: 12 }"
                   :config="{
                       x: positionsOfNodes[index].x + (indexOfItem===0?10:indexOfItem*10+10),
                       y: positionsOfNodes[index].y + 10,
                       width: 8,
                       height: 8,
                       // stroke: '#fff',
                       fill: item?.colorCode,
                       padding: 5
                   }"
               />
           </div>
            </div> 
        </div>
           
           
        </div>
        <div>
     </div>

    </div>
</template>

<script>
import store from "@/store";
import { removeDuplicatesByKey } from "@/helpers/DMVHelpers";
import logo from "../assets/MultiColorIcon.svg";
import caretUp from "../assets/arrow-up.svg";
import caretDown from "../assets/arrow-down.svg";
import progress from "../assets/Progress.svg";
import primaryKeyIcon from '../assets/primary-key.svg';
import DMVColorType from '@/helpers/DMVColorCodeHelper.ts'
export default {
    props: {
        data: [],
        titleText: String,
        position: { x: Number, y: Number },
        type: String,
        isItAFloating:Boolean,
        generalType:String,
        isItAHighlight: Boolean,
        subtitleText:String
    },
    data() {
        return {
            isItFirstToAppear:false,
            toaLoading: false,
            currentKey: 0,
            showFull: false,
            initialYValue: 0,
            testImg: new Image(10, 10),
            caretUpImg: new Image(10, 10),
            primaryKeyImg:new Image(10,10),
            caretDownImg: new Image(10, 10),
            progressImg: new Image(10, 10),
            loadingToa: false,
            prevLength:0,
            configKonva: {
                width: 400,
                height: 500,
            },
            noOfItemsThatGoBeyond:0,
            types: {
                Fact: "fact",
                Dimension: "dimension"
            },
            nonDuplicateItems: [],
            currentConfig: undefined,
            hasBeenMounted: false,
            positionsOfNodes: {},
            getSameItemsCount(item) {
                return this.data.filter(
                    (i) => i.primaryAttributeName === item.primaryAttributeName
                );
            }
        };
    },
    watch: {
        data: function (newValue, oldValue) {
            if(newValue !== oldValue) {
                    if(this.isItAHighlight){
                        this.nonDuplicateItems=[]
                        this.data = newValue;  
                        this.plotNodes();
                    }
                }
            
        
        },
        "$store.state.mainStage.toaData": function (value) {
            if (value.length > 1) {
                this.hasBeenMounted = true;
            }
        },
    "$store.state.mainStage.childNodePositionsForMainstage": function () {
        const item = this.$store.state.mainStage.childNodePositionsForMainstage;
        if (Object.keys(item).length > 1) {
           Object.keys(item).map((childPosition,_)=>{
            if(item[childPosition].y - this.position.y <=100){
                this.position.y +=100
                
                if(item[childPosition].x === this.position.x){
                    this.position.y+=500;
                    
                }
            }
           })
        }
    }
      
  
    },

    created() {
        this.testImg.src = logo;
        this.caretUpImg.src = caretUp;
        this.caretDownImg.src = caretDown;
        this.primaryKeyImg.src = primaryKeyIcon;
        this.plotNodes()      
    },

    computed: {
        getHeightOfItem() {
            return(generalItem)=>{
            const text= this.generalType === 'dimension'
                     ? generalItem.primaryAttributeName ||
                       generalItem.targetObjectAttributeName
                     : generalItem?.foreignAttributeName ||
                       generalItem.targetObjectAttributeName;                     
            return text.length>50?70:40;
}
},
        expandCollapseImageConfig() {
            return {
                image: this.loadingToa ? this.progressImg : this.caretDownImg,
                height: 10,
                width: 10
            };
        },
        imageConfig() {
            return {
                image: this.testImg,
                height: 10,
                width: 10
            };
        },

    getNodes() {
            return this.$store.getters["schema/getPositionsOfChildNodes"];
        },
        getMainStageNodes(){
            return this.$store.getters['schema/getPositionsOfChildNodes'];
        },
        getMetadata() {
            return this.$store.getters["schema/getMetaData"];
        },
        getToaData() {
            return this.$store.getters["schema/getToaData"];
        }
    },
    mounted() {
        this.testImg.src = logo;
        this.caretUpImg.src = caretUp;
        this.caretDownImg.src = caretDown;
        this.progressImg.src = progress;
        this.initialYValue = this.position.y;
       
        try {
            // check if position exists
            let positions = this.getMainStageNodes[this.titleText]
 
            if(positions===undefined){
                store.dispatch("schema/addPositionsOfChildNodes", {
                    [this.titleText]: { x: this.position.x, y: this.position.y }
                });
            }
          
        } catch (e) {
            console.error(e);
        }
        if(!this.isItAFloating){
     
        const key = this.data[0].primaryAttributeName??this.data[0].foreignAttributeName
        const isThereASelfReferece = this.data.filter(i=>i.foreignObjectName === i.primaryObjectName);
        if (this.generalType == "dimension" &&  isThereASelfReferece.length<=0) {
            this.nonDuplicateItems = removeDuplicatesByKey(this.data,"primaryAttributeName");
        }else if(this.generalType==='fact' && isThereASelfReferece.length<=0){

           this.nonDuplicateItems = removeDuplicatesByKey(this.data, "foreignAttributeName");
        }
      
        let toaItemsForThisTable;
        if (this.type === "dimension") {
            toaItemsForThisTable = this.getToaData.filter(
                (i) => i.targetObjectId === this.nonDuplicateItems[0].primaryObjectId
            );
        } else if (this.type === "fact") {
            toaItemsForThisTable = this.getToaData.filter(
                (i) => i.targetObjectId === this.nonDuplicateItems[0].foreignObjectId
            );
        }
       
       
      
        this.nonDuplicateItems?.map((generalItem, index) => {
            let title;
            if(generalItem?.foreignObjectName === generalItem.primaryObjectName){
                title = generalItem?.foreignAttributeName + generalItem?.foreignObjectName + generalItem?.foreignAttributeId;
                if(this.getMainStageNodes[title]){
                    title = generalItem?.primaryAttributeName + generalItem?.primaryObjectName;
                }
            }else{
           
                title =this.getTitle(generalItem)
                       
            } 
            if (!this.getMainStageNodes[title]) {
                let yUpdated;
                if(generalItem?.primaryObjectName === generalItem?.foreignObjectName){
                    yUpdated = this.generalType==='dimension'?generalItem.primaryAttributeName.length>46?this.positionsOfNodes[index].y-7:this.positionsOfNodes[index].y:generalItem.foreignAttributeName.length>46?this.positionsOfNodes[index].y-7:this.positionsOfNodes[index].y
           
                }else{

                     yUpdated =
                        this.type === "dimension"
                            ? this.initialYValue + 30
                            : this.position.y + index * 30;
                }

                store.dispatch("schema/addPositionsOfChildNodes", {
                    [title]: { x: this.position.x, y: yUpdated }
                });
            }
            this.initialYValue += 30;
        });
    }
    },
    methods: {
isThisAPrimaryKey(item){
    let data = this.getMetadata
    let isAPrimary;
    if(item?.addedType){
        isAPrimary= item?.addedType !== 'fact'
                                    ? true
                                    : false
            }else{

                isAPrimary=  this.generalType === 'dimension'
                                    ? true
                                    : false
            }
   return isAPrimary

},
        plotNodes(){
            if(this.position){

        if(!this.isItAFloating) {
        this.initialYValue = this.position.y;
        let newData=[];


        this.data.map((item)=>{
            if(item.primaryObjectName === item.foreignObjectName && item.foreignAttributeName !== item.primaryAttributeName)  {
               
                const copy = JSON.parse(JSON.stringify(item));
          
                if(this.generalType==='fact'){
                    copy['foreignAttributeName'] = item.primaryAttributeName;
                    copy['foreignAttributeId'] = item.primaryAttributeId;
                    copy['addedType']='fact'
                }else{
                 copy['primaryAttributeName'] =item.foreignAttributeName;
                 copy['primaryAttributeId'] =item.foreignAttributeId;
                 copy['addedType']='dimension'
                }
                newData = [...newData,item,copy];
            }else{
                newData = [...newData,item]
            }
        
        })
    this.nonDuplicateItems=[...newData];

this.nonDuplicateItems.map((i, index) => {
    const title =
        this.type === "dimension"
            ? i?.primaryObjectName + i?.primaryAttributeName || i?.targetObjectAttributeName
            : i?.foreignObjectName + i?.foreignAttributeName ||
              i?.targetObjectAttributeName;
    const yUpdated =
        this.type === "dimension" ? this.initialYValue + 30 : this.position.y + index * 30;

    this.positionsOfNodes[index] = {
        title,
        x: this.position.x,
        y: yUpdated,
        width: this.titleText.length < 40 ? 300 : this.titleText.length*8,
        height: 30
    };
    this.initialYValue +=30;
});
}else{
this.positionsOfNodes[0]={
    x:this.position.x,
    y:this.position.y
}
}
}
        },
        getText(generalItem){
            if(generalItem?.addedType){
               return generalItem?.addedType !== 'fact'
                                    ? generalItem.primaryAttributeName  + ' ['+generalItem.primaryAttributeId+'] '
                                    : generalItem.foreignAttributeName + ' [' +generalItem.foreignAttributeId+'] '
            }else{

              return  this.generalType === 'dimension'
                                    ? generalItem.primaryAttributeName  + ' ['+generalItem.primaryAttributeId+'] '
                                    : generalItem.foreignAttributeName + ' [' +generalItem.foreignAttributeId+'] '
            }
        },
        getHeight(generalItem){
          const length=  this.generalType==='dimension'?(generalItem.primaryAttributeName +generalItem.primaryAttributeId).length>35?40:35: (generalItem.foreignAttributeName+generalItem.foreignAttributeId).length>35?40:35
            this.prevLength=length;
            return length;
        },
        getTitle(generalItem){
      
           const type = generalItem?.addedType!==undefined ? generalItem['addedType'] : this.generalType 
            return type === "dimension" 
                            ? generalItem?.primaryAttributeName + generalItem?.primaryObjectName
                            : generalItem?.foreignAttributeName +
                              generalItem?.foreignObjectName +
                              generalItem?.foreignAttributeId;
        },
        getYForIndex(generalItem,index){
            const yUpdated= this.generalType==='dimension'?generalItem.primaryAttributeName.length>46?this.positionsOfNodes[index].y-7:this.positionsOfNodes[index].y:generalItem.foreignAttributeName.length>46?this.positionsOfNodes[index].y-7:this.positionsOfNodes[index].y;
             let title = generalItem.foreignAttributeName + generalItem.foreignObjectName + generalItem.foreignAttributeId;

                if(generalItem.foreignObjectName === generalItem.primaryObjectName){
                title = generalItem.primaryAttributeName + generalItem.primaryObjectName + generalItem.primaryAttributeId;
              
             }

            
         
            return yUpdated;
        },  
        getType(){return DMVColorType[this.type]},
        callParent() {
            this.$emit('show-fact-relations',this.data)
        },
        isFirst() {
            if(this.isItFirstToAppear){
                return true;
            }else{
                this.isItFirstToAppear=true;
                return false;
            }

        },
        putPositionsOfNonMetaDataItems(targetObjectId) {
            
            if(this.isItAFloating){
                const data = {data:this.getToaData[targetObjectId],isOpen:this.showFull};
                    this.$emit("get-toa-data",data)
            }
            let length = this.nonDuplicateItems.length>=1?this.nonDuplicateItems.length - 1:0;
            let yUpdated = this.positionsOfNodes[length].y ?? 30;
            const xPosition = this.positionsOfNodes[length].x;
            const items = this.getToaData[targetObjectId];
            
                    items?.map((item) => {
                     if(this.nonDuplicateItems.filter(i=>i.primaryAttributeId===item.targetObjectAttributeId || i.foreignAttributeId===item.targetObjectAttributeId).length<=0){
                        this.nonDuplicateItems=[...this.nonDuplicateItems,item];
                        const title = item?.targetObjectAttributeName;
                        this.positionsOfNodes[length + 1] = {
                            title,
                            x: xPosition,
                            y: yUpdated + 30,
                            width: this.titleText.length > 50 ? 400 : 300,
                            height: 30
                        };
                     
                        length++;
                        yUpdated += 30; 
                    }
                    });
                    this.currentKey++;
               },
  
        async setShowFull() {
            this.toaLoading = true;
            let length = this.nonDuplicateItems.length>=1?this.nonDuplicateItems.length - 1:0;
            let yUpdated = this.positionsOfNodes[length].y ?? 30;
            const xPosition = this.positionsOfNodes[length].x;
            let targetObjectId=0;
          
            if(this.isItAFloating){
                targetObjectId=this.data.targetObjectId;
            }else{
                targetObjectId =
                this.generalType === "dimension"
                    ? this.data[0].primaryObjectId
                    : this.data[0].foreignObjectId;}
            //check if the toa nodes exist already
            if (!this.getToaData[targetObjectId]) {   
                store.dispatch("schema/setStarSchemaAttributesForTargetObjectErd", targetObjectId).then(() => {
                    this.putPositionsOfNonMetaDataItems(targetObjectId)
               
                });
                
            }
            this.toaLoading = true;
            this.showFull = !this.showFull;
           
        },

        getAllColorBlocks(item, type = "dimension") {
            let items;
            if(item.primaryObjectName === item.foreignObjectName) return [{colorCode:item.colorCode}];
            let data = this.getMetadata;
            if (type !== "dimension") {
                items = data.filter(
                    (i) =>
                        i.foreignAttributeName === item.foreignAttributeName &&
                        i.foreignObjectName === item.foreignObjectName
                );
            } else {
                items = data.filter(
                    (i) =>
                        i.primaryAttributeName === item.primaryAttributeName &&
                        i.primaryObjectName === item.primaryObjectName
                );
            }

            return removeDuplicatesByKey(items, "colorCode");
        },

        getConfig(generalItem, index) {
            return this.getConfigFunction(generalItem, index);
        }
    }
};
</script>
<style scoped>
div.mainArea{
 z-index: 8;
 height:'300px';
 overflow:'scroll';
}

div.mainArea::-webkit-scrollbar{
    display:none;
}
body::-webkit-scrollbar{
  display:none;
}

</style>