
    import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
    import { ref } from 'vue';
    import { ObjectDataListBase } from '@/models/ObjectDataListBase';
    import { SubjectAreaListItem } from '@/models/SubjectAreaListItem';
    import { SubjectAreaFilterData } from '@/models/SubjectAreaFilterData';
    import { SubjectAreaFilterOptions } from '@/models/SubjectAreaFilterOptions';
    import { DataObjectList } from '@/models/DataObjectList';

    @Component
    export default class SubjectAreasIndex extends ObjectDataListBase<SubjectAreaListItem, DataObjectList<SubjectAreaListItem>, SubjectAreaFilterData, SubjectAreaFilterOptions> {

        private headers: Array<any>;
        private deleteDialog = false;
        private deleteId: number;
        created() {
            this.filteredLookupData = new SubjectAreaFilterData();
            this.filterOptions = new SubjectAreaFilterOptions();
            this.apiUrlBase = "/api/subjectareas/";
            this.options.sortBy = ["loadDateText", "subjectAreaName"];
            this.options.sortDesc = [true, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Subject Area", value: "subjectAreaName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Load Date"   , value: "loadDateText"   , class: this.headerClass, cellClass: this.cellClass },
                { text: "Action"     , value: "actions"        , class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        // router link isn't working from within the data table, it was not recognising the use of 'item'
        subjectAreaLink(id: number) {
            this.$router.push({ path: '/subjectareas/' + id });
        }

        visualiseStarSchemaOfSubjectArea(item: any) {
            this.$router.push({ path: '/subjectareas/' + item.id +'/ERdiagram' });
        }
        
        private closeDeleteDialog() {
            this.deleteDialog = false;
        }


        private deleteItem(id: number) {
            this.deleteDialog = true;
            this.deleteId = id;
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            this.$axios.delete(this.apiUrlBase + this.deleteId)
                .then(response => {
                    this.showResponseFeedback(response);
                    this.getItemsFiltered();
                })
                .catch(err => {
                    this.showErrorMessage(err);
                }).finally(() => {
                    this.hideOverlay();
                });
        }
        //public async getDiagramAvailability(){
        //   return await this.$axios.get(this.apiUrlBase+'star-schema-hasDMVFlag/')
        //        .then((response) => {
        //            this.hasDmvFlagItems = response.data;
        //        })
        //        .catch((err) => {
        //            return Promise.reject(err);
        //        });

        //}

        constructor() {
            super();
        }

    }
