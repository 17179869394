
import { Getter, Action } from 'vuex-class'
import { Component, Prop, Provide, Vue } from 'vue-property-decorator'
import { ComponentBase } from '@/models/ComponentBase'

@Component
export default class TopNavigation extends ComponentBase {
  @Getter('userName', { namespace: 'auth' })
  private userName!: string

  @Provide()
  private drawer = false

  @Action('logout', { namespace: 'auth' })
  private logOut!: () => void

  constructor() {
      super()
  }
}
