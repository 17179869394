
    import { Component, Prop, Ref } from 'vue-property-decorator';
    import PlatformsForm from '@/components/PlatformsForm';
    import { ComponentBase } from '@/models/ComponentBase';
    import DashboardCard from '@/components/DashboardCard';
    import { FormStateType } from '@/models/FormStateType';
    import { IGetItem } from '@/interfaces/IGetItem';

    @Component({
        components: {
            DashboardCard,
            PlatformsForm
        }
    })
    export default class PlatformsEdit extends ComponentBase {

        @Prop()
        public platformId: number; // this prop comes via route parameter
        public platformNameTitle = "Edit Platform";

        private formState: FormStateType = FormStateType.Edit;

        private breadcrumbItems = [
            {
                text: 'Back to platforms',
                disabled: false,
                href: '/admin/platforms',
            },
            {
                text: 'Edit',
                disabled: true
            }
        ];

        @Ref() readonly platformFormRef!: IGetItem;

        private async setPlatformName(value: string) {
            this.platformNameTitle = value;
        }

        private refresh() {
            this.platformFormRef!.getItem(this.platformId);
        }

        constructor() {
            super();
        }



    }
