import { IDataObjectListItem } from '@/interfaces/IDataObjectListItem'

export class SubjectAreaTargetObjectListItem implements IDataObjectListItem {

    id: number;
    subjectAreaId: number;
    targetObjectId: number;
    subjectAreaName: string;
    targetObjectName: string;
    platformId: number;
    platformName: string;
    loadDate: string;
    loadDateText: string;
}
