
    import { Component, Provide, Vue, Watch} from 'vue-property-decorator'
    import { ComponentBase } from '@/models/ComponentBase'
    import { UserList } from '../models/UserList';
    import { UserListItem } from '../models/UserListItem';
    import { User } from '../models/User';
    import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId';
    import { DataOptions } from 'vuetify/types'

    @Component
    export default class Users extends ComponentBase {

        private headerClass = "text-uppercase text-subtitle-1 primary--text font-weight-bold";
        private cellClass = "text-subtitle-1 primary--text";

        public users: Array<UserListItem> = []; 
        public totalItems = 0;
        public userRoles = Array<DropdownOptionIntegerId>();
        public search = "";
        private timerId: number;

        private options: DataOptions = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["userName"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [false],
            multiSort: true,
            mustSort: false
        };
        private loading = true;

        public emailRules: Array<(data: string) => void> = [
            v => !!v || "E-mail is required",
            v => /^[^\s@]+@[^\s@]+$/.test(v) || "E-mail must be valid"
        ];
        public userRoleRules: Array<(data: string) => void> = [
            v => !!v || "Select a role",
        ];

        private emailAlreadyExistsErrorMessage = '';

        public dialog = false;
        public dialogDelete = false;
        public editedIndex = -1;

        private editedItem: User = {
            userId: 0,
            userName: '',
            emailAddress: '',
            userRoleIds: [],
            //userRoleDescription: '',
            role: '',
            active: true,
            systemActive: true,
            isValid: false,
            receiveEmailYN: true,
            businessFunction:''
        }
        private defaultItem: User = {
            userId: 0,
            userName: '',
            emailAddress: '',
            userRoleIds: [],
            //userRoleDescription: '',
            role: '',
            active: true,
            systemActive: true,
            isValid: false,
            receiveEmailYN: true,
            businessFunction: ''
        }

        private headers: Array<any> = [
            { text: "Email", value: "emailAddress", class: this.headerClass, cellClass: this.cellClass },
            { text: "Username", value: "userName", class: this.headerClass, cellClass: this.cellClass },         
            { text: "Active", value: "active", class: this.headerClass, cellClass: this.cellClass },
            { text: "Actions", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
        ];

        private async getItems() {
            try {
                this.loading = true;

                await this.$axios.get<UserList>("/api/users", { params: Object.assign(this.options, { search: this.search }) })
                    .then(response => {
                        this.users = response.data.items;
                        this.userRoles = response.data.userRoles;
                        this.totalItems = response.data.totalItems;
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get list");
                    });
            } finally {
                this.loading = false;
            }
        }

        private async getItem(id: number) {
            await this.$axios.get<User>("/api/users/" + id)
                .then(response => {
                    this.editedItem = Object.assign({}, response.data);
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get item");
                });
        }

        private editItem(item: User) {
            this.editedIndex = this.users.indexOf(item);
            this.getItem(item.userId).then(x => {
                this.dialog = true;
            });
            
        }

        private deleteItem(item: User) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        }

        private async deleteItemConfirm() {
            await this.$axios.delete("/api/users/" + this.editedItem.userId)
                .then(response => {
                    this.showResponseFeedback(response);
                })
                .catch(err => {
                    this.showErrorMessage(err);
                })
            this.closeDelete();
        }

        private close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            })
        }

        private closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            })
        }

        @Watch('dialog')
        private dialogChange(val: boolean) {
            val || this.close();
        }

        @Watch('dialogDelete')
        private dialogDeleteChange(val: boolean) {
            val || this.closeDelete();
        }

        private async save() {
            if (this.editedItem.isValid) {
                if (this.editedIndex > -1) {
                    await this.$axios.put("/api/users/" + this.editedItem.userId, this.editedItem)
                        .then(response => {
                            this.showResponseFeedback(response);
                        })
                        .catch(err => {
                            this.showErrorMessage(err);
                        })
                } else {
                    await this.$axios.post("/api/users", this.editedItem)
                        .then(response => {
                            this.showResponseFeedback(response);
                        })
                        .catch(err => {
                            this.showErrorMessage(err);
                        })
                }
            }

            this.close();
        }

        public async emailChange() {
            this.emailAlreadyExistsErrorMessage = '';
            this.editedItem.userName = this.editedItem.emailAddress.trim().replace('@unilever.com', '');
            if (this.editedItem && this.editedItem.emailAddress && this.editedItem.emailAddress.trim() !== "") {
                await this.$axios.get<boolean>("/api/users/email-exists/" + this.editedItem.emailAddress).then(response => {
                    if (response.data) {
                        this.emailAlreadyExistsErrorMessage = "A user with that email already exists";
                    }
                })
                .catch(err => {
                    this.showErrorMessage(err);
                })
            }
        }

        @Watch("options", { deep: true })
        @Watch("search")
        public getItemsFiltered(val: string | DataOptions) {
            // cancel the delay
            clearTimeout(this.timerId);

            this.timerId = setTimeout(() => {
                this.getItems();
            }, 500);
        }

        get formTitle() {
            return this.editedIndex === -1 ? 'Create User' : 'Edit User'
        }

        constructor() {
            super();
        }
    }
