
    import { Component, Provide, Vue } from 'vue-property-decorator'
    import { ComponentBase } from '@/models/ComponentBase'
    import SubjectAreaForm from '@/components/SubjectAreaForm';
    import { FormStateType } from '@/models/FormStateType';

    @Component({
        components: {
            SubjectAreaForm
        }
    })
    export default class SubjectAreasCreate extends ComponentBase {

        private breadcrumbItems = [
            {
                text: 'Back to subjectAreas',
                disabled: false,
                href: '/subjectareas',
            },
            {
                text: 'Create',
                disabled: true
            }
        ];

        private formState: FormStateType = FormStateType.Create;

        constructor() {
            super();
        }

    }
