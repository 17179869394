import { Configuration, LogLevel } from '@azure/msal-browser'
import { AUTH_CONFIG } from './AuthConfig'
/**
 * Configuration class for @azure/msal-browser:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: AUTH_CONFIG.credentials.clientId,
        authority: `https://login.microsoftonline.com/${AUTH_CONFIG.credentials.tenantId}/`, // we need this to be multi-tenant, organization is better but msal-browser doesn't seem to support it. common for work/school/personal accounts, organization for work/school only, for single tenant restriction use 'https://login.microsoftonline.com/{your_tenant_id}'
        postLogoutRedirectUri: AUTH_CONFIG.configuration.postLogoutRedirectUri
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false // Set to "true" if having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    //case LogLevel.Info:
                    //    console.info(message);
                    //    return;
                    //case LogLevel.Verbose:
                    //    console.debug(message);
                    //    return;
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                }
            }
        }
    }
}
