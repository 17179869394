import { ObjectRelationAttribute } from '@/models/ObjectRelationAttribute'

export class ObjectRelation {
    objectRelationId : number;
    primaryTargetObjectId: number;
    primaryObjectType: string;
    foreignTargetObjectId: number;
    foreignObjectType: string;
    primaryTargetObjectAttributeId: number;
    foreignTargetObjectAttributeId: number;
    relationTypeId: number;
    hasDqri: boolean;
    useAlternateKey: boolean;
    objectRelationAttributes: Array<ObjectRelationAttribute>;
    relationCertifiedFlag:boolean;
}
