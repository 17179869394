export class TargetCalculation {
    targetCalculationId: number;
    targetObjectAttributeId: number;
    targetObjectAttributeName: string;
    targetObjectId: number;
    calculationTypeId: number;
    calculationTypeName: string;
    calculation: string;
    calculationComplexity: string;
    calculationSummary: string;
    smenotesTcalc: string;
    loadDate: string;
    refreshDate: string;
    isEditable: boolean;
}
