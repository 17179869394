
export class GraphEdgeRelation  {
    graphEdgeRelationId: number;
    graphEdgeObjectId: number;
    graphEdgeObjectType: string;
    relationTypeId: number;
    targetVertexObjectId: number;
    targetVertexObjectType: string;
    sourceVertexObjectId: number;
    sourceVertexObjectType: string;
    edgeDirection: number;
}
