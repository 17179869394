
    import { Vue, Component, Provide, Prop, Watch } from 'vue-property-decorator'
    import store from '@/store'
    import { ProductMeasureGroup } from '@/models/ProductMeasureGroup';
    import { DataObjectList } from '@/models/DataObjectList';
    import { ProductMeasureGroupListItem } from '@/models/ProductMeasureGroupListItem';
    import { ProductMeasureGroupLookupData } from '@/models/ProductMeasureGroupLookupData';
    import { ProductMeasureGroupFilterData } from '@/models/ProductMeasureGroupFilterData';
    import { ObjectDataListBase } from '@/models/ObjectDataListBase';
    import { ProductMeasureGroupFilterOptions } from '@/models/ProductMeasureGroupFilterOptions';

    @Component
    export default class ProductMeasureGroups extends ObjectDataListBase<ProductMeasureGroupListItem, DataObjectList<ProductMeasureGroupListItem>, ProductMeasureGroupFilterData, ProductMeasureGroupFilterOptions>  {

        @Prop()
        public productId: number;
        protected editedItem: ProductMeasureGroup = new ProductMeasureGroup();
        protected lookupData: ProductMeasureGroupLookupData = new ProductMeasureGroupLookupData();
        protected editDialog = false;
        protected editItemIsValid = true;

        private headers: Array<any>;
        private deleteDialog = false;
        private deletedItem: ProductMeasureGroupListItem = new ProductMeasureGroupListItem();
        private isMeasureGroupExisting = false;
        private isEdit = false;

        // override of the existing lookup function to hard set the productId
        // calling base class function was not working due to the promise being called by subsequent functions
        protected async getFilteredLookupData() {
            this.filterOptions.productId = this.productId;

            await this.$axios.get<ProductMeasureGroupFilterData>(this.apiUrlBase + "filter-data", {
                params: this.filterOptions
            }).then(response => {
                this.filteredLookupData = response.data;
            })
            .catch(err => {
                this.showErrorMessage(err, "Could not get lookup data");
            });
        }

        protected async getItem(id: number) {
            await this.$axios.get<ProductMeasureGroup>(this.apiUrlBase + id)
                .then(response => {
                    this.editedItem = Object.assign({}, response.data);
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get item");
                    return Promise.reject(err);
                });
        }

        protected async getLookupData() {
            await this.$axios.get<ProductMeasureGroupLookupData>(this.apiUrlBase + "lookup-data")
                .then(response => {
                    this.lookupData = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

        protected add() {
            this.showOverlay();
            this.isEdit = false;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.getLookupData()
                        .then(x => {
                            this.editedItem = Object.assign({}, new ProductMeasureGroup());
                            this.editedItem.productId = this.productId;
                            this.editDialog = true;
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            })
        }

        protected editItem(item: ProductMeasureGroupListItem) {
            this.showOverlay();
            this.isEdit = true;
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    Promise.all([this.getLookupData(), this.getItem(item.id)])
                        .then(x => {
                            this.editDialog = true;
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            })
        }

        private async create() {
            this.closeEditDialog();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.$axios.post(this.apiUrlBase, this.editedItem)
                            .then(response => {
                                this.showResponseFeedback(response);
                                this.getItemsFiltered();
                            })
                            .catch(err => {
                                this.showErrorMessage(err);
                            }).finally(() => {
                                this.hideOverlay();
                            });
                    }
                }
            });
        }

        private async update() {
            this.showOverlay();
            this.closeEditDialog();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.$axios.put(this.apiUrlBase + this.editedItem.productMeasureGroupId, this.editedItem)
                            .then(response => {
                                this.showResponseFeedback(response);
                            })
                            .catch(err => {
                                this.showErrorMessage(err);
                            }).finally(() => {
                                this.hideOverlay();
                            });
                    }
                }
            });
        }

        protected closeEditDialog() {
            this.editDialog = false;
            this.isEdit = false;
        }

        private closeDeleteDialog() {
            this.deleteDialog = false;
        }

        private deleteItem(item: ProductMeasureGroupListItem) {
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (!this.isBatchLocked) {
                    this.deleteDialog = true;
                    this.deletedItem = Object.assign({}, item);
                }
            });
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.$axios.delete(this.apiUrlBase + this.deletedItem.id)
                        .then(response => {
                            this.showResponseFeedback(response);
                            this.getItemsFiltered();
                        })
                        .catch(err => {
                            this.showErrorMessage(err);
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            });
        }

        private getExists() {
            if (this.editedItem && this.editedItem.productId && this.editedItem.measureGroupId) {
                this.$axios.get<boolean>(this.apiUrlBase + "exists", {
                    params: { productId: this.editedItem.productId, measureGroupId: this.editedItem.measureGroupId }
                })
                    .then(response => {
                        this.isMeasureGroupExisting = response.data;
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get existing measure group status");
                    });
            } else {
                this.isMeasureGroupExisting = false;
            }
        }

        created() {
            this.filteredLookupData = new ProductMeasureGroupFilterData();
            this.lookupData = new ProductMeasureGroupLookupData();
            this.filterOptions = new ProductMeasureGroupFilterOptions();

            this.apiUrlBase = "/api/productmeasuregroups/";
            this.options.sortBy = ["loadDateText", "measureGroupName"];
            this.options.sortDesc = [true, false];

            // needs to be set in created, because properties aren't being initialised in constructors
            this.headers = [
                { text: "Measure Group", value: "measureGroupName", class: this.headerClass, cellClass: this.cellClass },
                { text: "Related Target Objects", value: "targetObjectList", class: this.headerClass, cellClass: this.cellClass },
                { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
                { text: "", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
            ];
        }

        constructor() {
            super();
        }
    };
