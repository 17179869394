export class Platform {
    platformId: number;
    platformName: string;
    cataloguePlatformName: string;
    cataloguePlatformDescription: string;
    cataloguePlatformRootPath: string;
    catalogueIntegrationType: string;
    globalOrRegional: string;
    dataCoverage: string;
    dataOwnerId: number;
    internalExternal: string;
    platformDescription: string;
    platformGroup: string;
    platformLayer: string;
    platformLevel: number;
    platformType: string;
    platformRegion: string;
    sourceSpoc: string;
    sourceEscalationContact: string;

    loadDate: string;
    //refreshDate: string;
}
