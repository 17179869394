
    import { Component, Provide, Vue } from 'vue-property-decorator'
    import { ComponentBase } from '@/models/ComponentBase'

    @Component
    export default class Admin extends ComponentBase {
        constructor() {
            super();
        }

    }
