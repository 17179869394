export class ProductMeasureGroup {
    productMeasureGroupId: number;
    description: string;
    productId: number;
    measureGroupId: number;

    // additional properties
    loadDate: string;
    refreshDate: string;
    productName: string;
    measureGroupName: string;
}
